<template>
    <div class="rental">
        <div class="custom_keyboard" v-if="showCustomKeyBoard">
            <div class="keyboard_el_wrapper">
                <ul class="controls">
                    <li><a class="close_button" @click="hideKeyBoard">Close</a></li>
                </ul>
                <ul class="select_wrapper">
                    <li v-for="(i, index) in selectOptions" @click.prevent="setSelectedOption(i, index)" :class="{selected: i.value == selectedOptionValue}">{{ i.text }}</li>
                </ul>
                <ul class="controls">
                    <li><a class="next_button" @click="nextKeyBoardField">Next</a></li>
                </ul>
            </div>
        </div>
        <section id="noke-confirmation" v-if="isActive('noke_confirm')">
            <div class="noke-confirmation-wrapper"></div>
            <div class="ic_confirmation" :class="{error: processingError}">
                <span class="icon-bullet_icon" :class="{error: processingError}"></span>
            </div>
            <div class="heading">
                <h3>CONGRATULATIONS</h3>
                <p class="highlight">Your rental is confirmed!</p>
                <p class="colored">This facility is operated with an electronic smart access system.</p>
            </div>
            <div class="text-section" :class="{ 'noke-failed': !nokeStatusCheck }">
                <p v-if="nokeStatusCheck">You will be able to open your space from your smart phone. If you would like to access the facility with a key FOB please contact the facility manager.</p>
                <p v-else>Please show your government issued ID to the Manager to help you set up access control to your space.</p>

            </div>
            <img src="../../assets/graphics/noke-mobile.png">
            <KFooter :done-title="dynamicTitle()" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>
        <section id="setup-smart-access" v-if="isActive('setup_noke')">
            <div class="head-wrapper">
                <div class="text-section">
                    <h2>SETUP YOUR SMART ACCESS SYSTEM</h2>
                    <p>We have emailed and texted you the links to download the App AND your Access Control PIN.</p>
                    <p>If you did not receive a text message, please resend it below</p>
                    <form id="resend-text">
                        <p v-if="gateCodeSMSSuccess" class="success">{{ gateCodeSMSSuccess }}</p>
                        <p v-if="gateCodeSMSError" class="error">{{ gateCodeSMSError }}. Please try again.</p>
                        <div>
                            <label class="show_in_keyboard">Phone Number</label>
                            <input type="text" autocomplete="none" name="phone" v-mask="'(###) ###-####'" v-model="model.tenant_phone" @focus="showKeyBoard"  data-layout="phone" placeholder="Phone Number">
                        </div>

                        <button @click.prevent="sendGateCode" class="resend-button ripple">RESEND TEXT</button>
                    </form>
                </div>
            </div>
            <div class="detail-wrapper">
                <p>To setup a key FOB, please contact the manager on duty.</p>
            </div>
            <img src="../../assets/graphics/noke-pin-mobile.png">
            <KFooter :done-title="dynamicTitle()" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="find_reservation" v-if="isActive('find_reservation')">
            <div class="wrapper">
                <div class="heading">Great! Let's look up your Reservation.</div>
                <div class="form_row">
                    <span class="error" v-if="fieldErrors">{{ fieldErrors }}</span>
                    <span class="error" v-else="errors.has('reservation_code')">{{ errors.first('reservation_code') }}</span>
                    <div class="reservation-code" :style="getBigScreenStyle">
                        <label class="show_in_keyboard">Reservation Code</label>
                        <input @change="fieldErrors=''" v-bind:class="{ input_error: errors.has('reservation_code') }" v-validate="{ required: true}" v-model="model.reservation_code" name="reservation_code" type="text" autocomplete="none" placeholder="Reservation Code" data-vv-as="reservation code" required @focus="showKeyBoard" class="email" data-layout="submit"/>
                        <a id="reservation_submit_button" class="ripple submit_button" @click.prevent="callMethods('reservation_code')" v-loading :class="{loading: loadingReservationDetails}" ><span>FIND MY RESERVATION</span></a>
                    </div>
                </div>
            </div>
        </section>

        <section id="detail_page" :class="{ 'sticky': isSticky }" v-if="isActive('detail_page')">
            <Slider :facilityMedia="facilityMedia" :video="video"></Slider>
            <div class="availability" @click="unsetSticky">
                <p v-if="!facility.hide_storage_size_count"><span>{{ sizeCount }}</span> sizes for rent</p>
            </div>
            <div class="container" @click="setSticky">
                <div class="left panel">
                    <div v-if="spaceSearchActive && spaceSearchResult.length" class="unit_search">
                        <div class="header">
                            <span class="unit-name">{{ space_search_keyword }}</span>
                            <span><span v-if="spaceSearchResult.length">{{spaceSearchResult.length}}</span><span v-else>no</span> {{spaceSearchResult   | pluralize('space')}} found</span>
                            <span @click="clearSpaceSearch" @click.prevent="setActiveSection('detail_page')" class="icon-close_button_icon"></span>
                        </div>
                        <ul>
                            <SpaceDetails
                                v-for="(space, key) in spaceSearchResult"
                                :space="space"
                                :index="key+1"
                                :key="key+1"
                                :isExpand="isExpand"
                                :total="spaceSearchResult.length"
                                :buttonStatus="showSearchPayBillButton"
                                @clearSpaceSearch="clearSpaceSearch"
                                @setSelectedSpace="setSelectedSpace"
                                @allSpaceOccupied="allSpaceOccupied"
                                :facilityMedia="facilityMedia"
                            ></SpaceDetails>
                        </ul>
                    </div>
                    <div v-else class="accordion-section">
                        <UnitCategory
                            v-if="facilityUnits.wine_storage"
                            unit-size="Wine Storage"
                            :units-list="facilityUnits.wine_storage"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.extra_small"
                            unit-size="X-Small"
                            :units-list="facilityUnits.extra_small"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.small"
                            unit-size="Small"
                            :units-list="facilityUnits.small"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.medium"
                            unit-size="Medium"
                            :units-list="facilityUnits.medium"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.large"
                            unit-size="Large"
                            :units-list="facilityUnits.large"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.extra_large"
                            unit-size="X-Large"
                            :units-list="facilityUnits.extra_large"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.parking"
                            unit-size="Parking"
                            :units-list="facilityUnits.parking"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.mobile_cube"
                            unit-size="Mobile Cube"
                            :units-list="facilityUnits.mobile_cube"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                        <UnitCategory
                            v-if="facilityUnits.other"
                            unit-size="Other Units"
                            :units-list="facilityUnits.other"
                            @updateSizeCount="updateSizeCount"
                            @setVideo="setVideo"
                            :is-instore="facility.show_instore_only_in_kiosk"
                            :show-count="facility.disable_show_unit_count"
                            :hide-category="facility.disable_unit_category"
                        ></UnitCategory>
                    </div>
                </div>
                <div class="right panel">
                    <template v-if="isSticky">
                        <div v-for="(sizeVideo, index) in sizeGuideVideos" :key="index"  v-show="video == sizeVideo.video_id">
                            <transition mode="out-in">
                                <div :id="videoClass(sizeVideo.video_id)" :class="videoClass(sizeVideo.video_id)" class="wistia_embed" style="width:594px;height:335px;margin-bottom:20px"></div>
                            </transition>
                        </div>
                    </template>
                    <SpaceSearch
                        v-if="facilitySpaces.length"
                        :spaceSearchResult="spaceSearchResult"
                        :spaceSearchActive="spaceSearchActive"
                        @spaceNumberSearch="spaceNumberSearch"
                    ></SpaceSearch>
                    <div class="facility-details">
                        <div class="address">
                            <p class="tittle">{{ facility.name }}</p>
                            <p class="street">{{ facility.address1 }}, {{ facilityAddress2 }}{{ facility.city }}, {{ facility.state }} {{ facility.postal }}</p>
                        </div>
                        <div class="access-hours-wrapper" v-if="facility.timings">
                            <ul class="access-time" v-for="(facTiming, key) in facility.timings.kiosk_time" :key="key" v-show="showFacTiming(key)">
                                <li>
                                    <span>{{ key|unslugify }}</span>
                                    <span class="status">{{ facTiming.status }}</span>
                                </li>
                                <li v-for="accessHour in facTiming.time" :class="{ active: accessHour.is_today, daily: isDaily(facTiming.time.length) }">
                                    <span>{{ accessHour.label }}</span>
                                    <span>{{ accessHour.time|formateTime }}</span>
                                </li>
                            </ul>
                            <ul v-if="facility.facility_notes" v-html="facilityNotes" class="access-time facility-notes">
                            </ul>
                        </div>
                        <div class="access-hours-wrapper" v-else>
                            <ul v-if="facility.facility_notes" v-html="facilityNotes" class="access-time facility-notes">
                            </ul>
                        </div>
                    </div>
                    <div class="review-section" v-if="(facility.reviews.google && facility.reviews.google.user_reviews.length) || (facility.reviews.yelp && facility.reviews.yelp.user_reviews.length)">
                        <div class="review-tab head-box" @click.prevent="reviewToggle" :class="{ 'active': isReviewActive }">
                            <span class="icon-arrow_top">REVIEWS</span>
                        </div>
                        <section>
                            <ReviewBox
                                v-for="(reviews, reviewType, index) in facility.reviews"
                                v-if="reviewType && reviews.user_reviews.length"
                                :review-type="reviewType"
                                :reviews="reviews"
                                :key="index"
                            ></ReviewBox>
                        </section>
                    </div>
                    <div class="amenities-section" v-if="facility.features.length">
                        <div class="amenities head-box" @click.prevent="amenityToggle" :class="{ 'active': isAmenityActive }">
                            <span class="icon-arrow_top">AMENITIES</span>
                        </div>
                        <section>
                            <ul>
                                <li v-for="amenity in facility.features" :style="getAmenityImage(amenity)">
                                    <span v-text="getAmenityName(amenity)"></span>
                                </li>
                                <div class="clear"></div>
                            </ul>
                        </section>
                    </div>
                    <div class="payment-notice"><strong>Credit Card Payments ONLY.</strong> See Manager for alternative Payments.</div>
                </div>
                <div class="clear"></div>
            </div>
        </section>

        <section class="load_section" v-if="isActive('process_rental')">
            <div class="container">
                <div class="loader_wrapper">
                    <div class="loader_content">
                        <h3>We are processing your {{ userAction }}!</h3>
                        <div class="bar_loader">
                            <span></span>
                        </div>
                    </div>
                </div>
                <trivia/>
            </div>
        </section>

        <section id="hold_unit" v-if="isActive('hold_unit')">
            <div class="container">
                <div class="loader_wrapper">
                    <div class="loader_content">
                        <h3>We are putting your space on hold.</h3>
                        <div class="bar_loader">
                            <span></span>
                        </div>
                    </div>
                </div>
                <trivia/>
            </div>
        </section>

        <login v-if="isActive('login')" isRental="true"></login>

        <section id="rent_this_space" v-if="isActive('rent_this_space')">
            <KHeader title="rent this space" sub_title="Get started by verifying your identity"></KHeader>
            <div class="container">
                <div class="img-wrapper" :class="{active: dlScanError}">
                    <!-- <div class="error_div">
                        <span class="error center_align" v-if="dlScanError">There has been an error scanning your driving license.</span>
                    </div>
                    <img v-if="!dlScanError" @click.prevent="setActiveSection('info')" src="../../assets/graphics/rental/credit_card_new.png"> -->

                    <!-- <div class="arrow" v-if="!dlScanError">
                        <svg width="188px" height="159px" viewBox="0 0 188 159" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Sketch.</desc>
                            <defs></defs>
                            <g id="WhitelabelKioskArrow" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="4" transform="translate(-863.000000, -618.000000)">
                                    <polygon id="Path-24" points="863 673.330078 956.308594 618 1050.11719 673.330078 992.089844 673.330078 1029.1543 776.117188 883.233768 776.117188 921.070312 673.330078"></polygon>
                                </g>
                            </g>
                        </svg>
                        <svg width="188px" height="159px" viewBox="0 0 188 159" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                            <desc>Created with Sketch.</desc>
                            <defs>
                                <linearGradient x1="50%" y1="0%" x2="43.8770824%" y2="334.552102%" id="linearGradient-1">
                                    <stop stop-color="#000000" stop-opacity="0" offset="0%"></stop>
                                    <stop stop-color="#000000" offset="100%"></stop>
                                </linearGradient>
                            </defs>
                            <g id="Whitelabel-Kiosk-trans" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <g id="4" transform="translate(-1071.000000, -608.000000)" fill="url(#linearGradient-1)">
                                    <polygon id="Path-24" points="1071 663.330078 1164.30859 608 1258.11719 663.330078 1200.08984 663.330078 1237.1543 766.117188 1091.23377 766.117188 1129.07031 663.330078"></polygon>
                                </g>
                            </g>
                        </svg>
                    </div> -->
                </div>
                <div class="gif-image">
                  <img
                       src="https://d38j3vvmfsoios.cloudfront.net/owners/own0d990176d2dd4b43bb83179167530c29/others/file/other/testgif-20220927-064641?Expires=2147472000&Signature=QEm~CRliQWz6N7CWZ8xTW1xfHygpLBwwKPYcj4lFkYPMOGxc0hCxWRehE4ff0zw8gW4PchuJK7~HEb1nA2kiuvkMlmEyVl2x9MIHvw8FwHDMEjPIbmCQJTOgWeSBBhNCTBItxsml3eJh2bFELVP~c85kC3Q0g4Ebrrw~RQYgCeL4jqj1ugEN1LD5~Mp~q8aYNja6ZsjKWuwgIkwSOIoDTXETENO37fQ25N08D9gvxnQo26m7iUzsNBdrm6sDkg1Sy8TyrVg8iSk671pZeb6B~~d~eHq6ociLN6GgbFUEv9jpcn6bNu9aqJcliqxF9MwMKrq~TktS4pD3iobE5iUl0g__&Key-Pair-Id=APKAJL3PZG6ISLR33ZHQ"
                       alt="Scan driver’s license using mobile phone for ID verification"/>
                </div>
                <div class="btn-wrapper" :class="{active: dlScanError}">
                    <div class="btn-container">
                        <!-- <p class="warn_btn_text">Enter your driver license with the magnetic strip on the bottom right and <span class="pulsate_effect">press the button below.</span></p> -->
                        <div class="warn_btn_text">Enter your phone number below to verify your identity on your mobile device.</div>
                        <div class="form-container">
                          <div class="form-fields">
                            <div class="form_row">
                                <div class="form_field_align">
                                    <label class="show_in_keyboard">Mobile Number</label>
                                    <span class="error" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span>
                                    <input ref="inputPhone" type="text" autocomplete="none" v-mask="'(###) ###-####'" name="phone_sendText"  v-model="model.tenant_phone" placeholder="Mobile Number*" :class="{ input_error: validation.hasError('model.tenant_phone'), highlight: highlight.phone }" @blur="formatPhoneNumber(model.tenant_phone, 'normal')" :disabled="loading" @focus="showKeyBoard($event, 'error')" data-layout="submit_phone"/>
                                </div>
                            </div>
                          </div>
                            <button type="submit" v-loading :class="{loading: loading}" class="verify-button ripple" @click.prevent="callMethods('phone_sendText')">
                              <span>Verify ID</span>
                            </button>
                            <!-- <button v-else class="btn-disabled">Verify ID</button> -->
                        </div>
                        <!-- <button v-if="!dlScanError" class="scan_button" :class="{loading: loadingDlData}" v-loading @click.prevent="getDlData" ref="scan_button">
                            <span id="scan_license_button">Scan your driver license</span>
                        </button> -->
                        <!-- <button v-else class="rescan_button" :class="{loading: loadingDlData}" v-loading @click.prevent="getDlData">
                            <span>Rescan your driver license</span>
                        </button> -->
                        <!-- <p @click.prevent="setActiveSection('login')" v-if="!dlScanError">I'm already a customer</p> -->
                        <div class="discription">
                          <div class="existing-customer" @click.prevent="loginUser" v-if="!dlScanError">I'm already a customer</div>
                          <div v-if="!facility.driver_license_required" class="manual-verificatoin" @click.prevent="manualVerification">Manually Verify ID</div>
                        </div>
                        <!-- <p @click.prevent="loginUser" v-if="!dlScanError">I'm already a customer</p> -->
                        <!-- <p v-else="dlScanError" @click.prevent="setActiveSection('info')">Enter your information manually</p> -->
                    </div>
                </div>
                <div v-show="isPopupEnabled" id="popup1" :class="{'absolute-position': positionAbsolute}" class="overlay">
                  <div class="popup-wrapper" :class="{popupbackground: !isGuestLoginEnabled, top15: !isGuestLoginEnabled}">
                        <div class="popup-container">
                          <div v-if="!isGuestLoginEnabled" class="popup-header">
                              <h2>Login to Continue</h2>
                              <a class="close" @click="updatePopup('main-close')">&times;</a>
                          </div>
                          <div class="popup-body">
                            <div v-if="isGuestLoginEnabled">
                              <h3 class="error" v-if="failedAttempt">Oops! Identity Verification failed. Please try again.</h3>
                              <h2 class="pb-h1">Session continues on your phone.</h2>
                              <p class="just-in-case">
                                Just in case you did&nbsp;<strong>NOT</strong>&nbsp;get the message
                              </p>
                            </div>
                            <div v-if="!isGuestLoginEnabled && !isContinueWithMobileEnabled && !isOtpSend">
                              <button key="continueWithMobile" class="cwm-button" @click="updatePopup('continueWithMobile')"><div class="cwm-wrapper"><span class="sms-icon"><img src="../../assets/graphics/sms-processing.png"></span>&nbsp;Continue with Mobile</div></button>
                            </div>
                            <div v-if="isGuestLoginEnabled">
                              <div class="form-container">
                                <div class="form-fields">
                                  <div class="form_row">
                                    <div class="form_field_align">
                                      <label class="show_in_keyboard">Mobile Number</label>
                                      <span class="error" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span>
                                      <input type="text" class="pb-input1" autocomplete="none" v-mask="'(###) ###-####'" name="phone_1_sendText" v-model="model.tenant_phone" placeholder="Mobile Number*" :class="{ input_error: validation.hasError('model.tenant_phone'), highlight: highlight.phone }" @blur="formatPhoneNumber(model.tenant_phone, 'normal')" :disabled="loading" @focus="showKeyBoard" data-layout="submit_phone" />
                                    </div>
                                  </div>
                                </div>
                                <button type="submit" key="resendText" v-loading :class="{loading: loading}" v-if="isGuestLoginEnabled && !validation.hasError('model.tenant_phone')" class="verify-button ripple" @click.prevent="callMethods('phone_1_sendText')">
                                  <span>Resend Text</span>
                                </button>
                                <button v-else class="btn-disabled">Resend Text</button>
                              </div>
                            </div>
                            <div class="continue-mobile" v-if="isContinueWithMobileEnabled">
                              <p class="error" v-if="!validation.hasError('model.tenant_phone') && errorMsg">{{errorMsg}}</p>
                              <p class="cm-p1">Fields marked with * are mandatory.</p>
                              <p class="cm-p2">Enter the mobile number associated with your storage account.</p>
                              <div class="form-fields">
                                <div class="form_row">
                                  <div class="form_field_align">
                                      <label class="show_in_keyboard">Mobile Number</label>
                                      <span class="error" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span>
                                      <!-- <span class="error" v-if="!validation.hasError('model.tenant_phone') && errorMsg">{{errorMsg}}</span> -->
                                      <input ref="inputPhone" type="text" autocomplete="none" v-mask="'(###) ###-####'" name="phone_sentOtp" v-model="model.tenant_phone" placeholder="Mobile Number*" :class="{ input_error: validation.hasError('model.tenant_phone'), highlight: validation.hasError('model.tenant_phone') }" @blur="formatPhoneNumber(model.tenant_phone, 'otp')" :disabled="loading" @focus="showKeyBoard" data-layout="submit_phone" />
                                  </div>
                                </div>
                              </div>
                              <p class="info">We'll text you to confirm your number. If the number is not found, you will need to create a new account. Standard message and data rates apply.</p>
                              <button type="submit" key="continue" v-loading :class="{loading: otpLoder}" class="btn-full-width ripple" @click.prevent="callMethods('phone_sentOtp')"><span>Continue</span></button>
                            </div>
                            <div class="break-line" :class="{breakLinePadding: !isGuestLoginEnabled}" v-if="!isOtpSend">
                              <hr class="hr" />
                              <span class="hr-msg">or</span>
                              <hr class="hr" />
                            </div>
                            <button key="returnToThisDevice" v-if="isGuestLoginEnabled" class="btn-full-width" @click="updatePopup('close')">Return to this device</button>
                            <button key="continueWithEmail" v-if="isContinueWithMobileEnabled" class="btn-full-width cwe-button" @click="updatePopup('continueWithEmail')"><span class="icon-message"></span>&nbsp;Continue with Email</button>
                            <div class="body-footer" v-if="!isGuestLoginEnabled && !isContinueWithMobileEnabled">
                              <!-- <span class="error" v-if="fieldErrors">{{ fieldErrors}}</span> -->
                              <p class="bf-p1">Fields marked with * are mandatory.</p>
                              <div v-if="!isOtpSend">
                                <div class="form-fields">
                                  <div class="form_row">
                                    <div class="form_field_align">
                                        <label class="show_in_keyboard">Email</label>
                                        <span class="error" v-if="fieldErrors.__all__">{{ fieldErrors.__all__[0] }}</span>
                                        <span class="error" v-if="validation.hasError('model.tenant_email')">{{ validation.firstError('model.tenant_email') }}</span>
                                        <input type="text" autocomplete="none" class="email" name="username"  v-model="model.tenant_email" placeholder="Email*" :class="{ input_error: validation.hasError('model.tenant_email'), highlight: highlight.email }" @focus="showKeyBoard" data-layout="email">
                                    </div>
                                  </div>
                                  <div class="form_row">
                                      <div class="form_field_align">
                                        <label class="show_in_keyboard">Password</label>
                                        <!-- <span v-show="errors.has('password')" :class="{ error: errors.has('password') }">{{ errors.first('password') }}</span> -->
                                        <span class="error" v-if="validation.hasError('model.password')">{{ validation.firstError('model.password') }}</span>
                                        <input ref="showPassword" type="password" autocomplete="none" v-model="model.password" name="password_onSubmit" v-bind:class="{ input_error: validation.hasError('model.password') }" v-validate="{ required: true, confirmed:model.password}" placeholder="Password*" @focus="showKeyBoard"  data-layout="submit" data-vv-as="password">
                                      </div>
                                  </div>
                                </div>
                                <checkbox class="popup-checkbox" type="checkbox" id="show_password" :true-value="true" :false-value="false" v-model="model.show_password"  @input="showPassword"  name="show_password"><span class="checkbox-label">Show Password</span></checkbox>
                              </div>
                              <div v-else class="otp-section">
                                <div class="otp-section-wrapper">
                                  <p>Enter the login code we just sent over SMS to:</p>
                                  <h5 class="otp-section">+1 {{model.tenant_phone}}</h5>
                                  <div class="form-fields">
                                    <div class="form_row">
                                      <div class="form_field_align">
                                          <label class="show_in_keyboard">Enter Code</label>
                                          <input type="text" class="mobile_input_field" autocomplete="none" name="otp_onSubmit" v-model="otpCode" placeholder="Enter Code*"  :class="{ input_error: validation.hasError('otpCode'), 'relative-position': positionRelative }" v-validate="{ required: true, min: 1 }" @focus="showKeyBoard" data-layout="submit_phone" />
                                          <!-- <span class="error error_alignment" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span> -->
                                      </div>
                                    </div>
                                  </div>
                                  <p class="count-down" v-if="showTimer">Resend login code in {{ countDownValue }} sec</p>
                                  <p v-if="!hideResendSMS" class="resent-sms">Didn't get a code? <a href="#" @click.prevent="sentOtp()">Resend SMS</a></p>
                                  <p class="error" v-if="responseError">{{ responseError }}</p>
                                  <div class="otp-notification">
                                    <p><img src="../../assets/graphics/notification.svg" alt="notification-icon"/><span> Check text message for your login code.</span></p>
                                    <p class="otp-warning">(This is not your Access Code)</p>
                                  </div>
                                </div>
                              </div>
                              <button type="submit" key="login" v-loading :class="{loading: submitLogin}" class="btn-full-width ripple" @click.prevent="callMethods('password_onSubmit')"><span>Login</span></button>
                              <!-- <button v-loading type="submit" :class="{loading: submitLogin}" class="ripple" @click.prevent="onSubmit"><span>Login</span></button> -->
                            </div>
                          </div>
                        </div>
                  </div>
                </div>
            </div>
        </section>

        <section id="side_bar" v-show="isSideBarActive()" v-if="selectedUnit">
            <div class="head">
                <span>Your Storage {{ unitDescriptions }}</span>
                <span class="button ripple" @click.prevent="setActiveSection('detail_page')">CHANGE</span>
            </div>
            <div class="move-in-cost">
                <p class="unit-name">{{ selectedUnit.actual_width }} ' X {{ selectedUnit.actual_length }} ' {{ unitDescriptions }}</p>
                <!-- <p class="unit-type">{{ selectedUnit.unit_type }} <span v-if="model.unit_door_number">| {{  model.unit_door_number }}</span></p> -->
                <p class="unit-type">{{ getUnitDetails() }}</p>
                <p class="unit-description">{{ selectedUnit.description }}</p>
                <ul>
                    <li class="move-in"><span>Move in date</span> <span>{{ moveInDate|formatMoveInDate }}</span></li>
                    <li class="in-store" v-if="!facility.show_instore_only_in_kiosk"><span>Monthly Rent</span> <span class="price">{{ selectedSpace.rental_rate?selectedSpace.rental_rate:selectedUnit.rental_rate|formatAmount }}</span></li>
                    <template v-for="(cost, index) in getFilteredMoveInCost">
                    <li v-if="displayAmount(cost)" :class="{'first': index === 0 && cost.name == 'rent', 'last' : isLast(index, getFilteredMoveInCost)}" class="cost-list">
                        <span>{{ cost|formatDescription(index) }}</span>
                        <span class="price" v-if="isLast(index, getFilteredMoveInCost)">{{ cost.total|formatAmount }}</span>
                        <span class="price" v-else>{{ cost|formatAmount }}</span>
                    </li>
                    <li v-if="cost.name.toLowerCase() == 'rent'" class="cost-list">
                    <span>{{cost.period}}</span>
                    </li>
                    </template>
                    <!-- for no moving cost-->
                    <div v-if="!moveInCost.length" class="not_movecost bar_loader">
                            <span></span>
                    </div>
                    <li class="cost-list last" v-if="!moveInCost.length"><span>Calculating Move-In Cost ...</span></li>
                    <!-- ends -->
                </ul>
                <div class="card-images">
                    <img src="../../assets/graphics/rental/godady.png">
                    <img  class="all-cards" src="../../assets/graphics/rental/cards_image.png">
                </div>
                <div class="notice">Credit Card Payments ONLY</div>
                <img class="zigzag-img" src="../../assets/graphics/rental/bottom.png">
            </div>
        </section>

        <section id="info" v-if="isActive('info')" class="wrapper_container" :class="{loading: sectionLoading}">
            <div class="loader_wrapper" :style="getHeaderStyle">
                <div class="loader"></div>
            </div>
            <KHeader title="Your information" sub_title="Please confirm and complete your information."></KHeader>
            <div class="container_info">
                <div class="form-fields">
                  <div v-if="!$store.state.skipAndVerify" class="id-container">
                        <div class="container-scanned-document">
                            <div class="scanned-document">
                              <div :class="['verified-top-banner', {'margin-0': showManualVerificationOnScannedDocumentSection}]">
                                <div>
                                  <span v-if="checkConfidence || (!PreviouslyIdVerifiedDetails && !verificationCompleted)" class="iconBorder" :class="{'danger': !checkConfidence}">
                                    <i v-if="checkConfidence" class="ss-icon icon-tick_icon"></i>
                                    <i v-if="!PreviouslyIdVerifiedDetails && !verificationCompleted" class="ss-icon icon-x-out"></i>
                                  </span>
                                </div>
                                <h2 v-if="checkConfidence" class="verified-title">Identity Verified</h2>
                                <h2 v-if="!PreviouslyIdVerifiedDetails && !verificationCompleted" class="verified-title">Verify Identity</h2>
                                <p v-if="!checkConfidence && (PreviouslyIdVerifiedDetails || verificationCompleted)">Thank you for providing your documents.</p>
                              </div>
                              <div v-if="!PreviouslyIdVerifiedDetails && !verificationCompleted" class="form-container">
                                <div class="form-fields">
                                  <div class="form_row">
                                      <div class="form_field_align">
                                          <label class="show_in_keyboard">Mobile Number</label>
                                          <span class="error" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span>
                                          <input ref="inputPhone" type="text" autocomplete="none" v-mask="'(###) ###-####'" name="phone_sendText_info"  v-model="model.tenant_phone" placeholder="Mobile Number*" :class="{ input_error: validation.hasError('model.tenant_phone'), highlight: validation.hasError('model.tenant_phone') }" @blur="formatPhoneNumber(model.tenant_phone, 'normal')" @focus="showKeyBoard" :disabled="loading" data-layout="submit_phone"/>
                                      </div>
                                  </div>
                                </div>
                                  <button type="submit" v-loading :class="{loading: loading}" class="verify-button ripple" @click.prevent="callMethods('phone_sendText_info')">
                                    <span>Verify ID</span>
                                  </button>
                                  <a v-if="showManualVerificationOnScannedDocumentSection" class="skip-and-verify" @click.prevent="manualVerification">Manually Verify ID</a>
                              </div>
                              <div class="image-wrapper">
                                  <div class="front-img">
                                    <img
                                    v-if="extractedImages.front"
                                    :src="extractedImages.front"
                                      alt="license front"
                                    />
                                  </div>
                                  <!-- <div class="selfie-img">
                                    <img
                                      v-if="extractedImages.selfie"
                                      :src="extractedImages.selfie"
                                      alt="selfie image"
                                    />
                                  </div> -->
                              </div>
                              <p :class="['rental-process', {'margin-0': showManualVerificationOnScannedDocumentSection}]">Please continue the rental process.</p>
                            </div>
                      </div>
                      <div class="form-field-left-wrapper">
                          <div class="form_field_align">
                                  <label class="show_in_keyboard">Email</label>
                                  <span class="error" v-if="validation.hasError('model.tenant_email')">{{ validation.firstError('model.tenant_email') }}</span>
                                  <input type="text" autocomplete="none" class="email" name="username"  v-model="model.tenant_email" placeholder="Email*" :class="{ input_error: validation.hasError('model.tenant_email'), highlight: validation.hasError('model.tenant_email') }" @focus="showKeyBoard" data-layout="email">
                          </div>
                          <div class="form_field_align">
                                  <label class="show_in_keyboard">Cell Phone</label>
                                  <span class="error" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span>
                                  <input type="text" autocomplete="none" v-mask="'(###) ###-####'" name="phone3"  v-model="model.tenant_phone" placeholder="Cell Phone*" :class="{ input_error: validation.hasError('model.tenant_phone'), highlight: validation.hasError('model.tenant_phone') }" @focus="showKeyBoard" data-layout="phone"/>
                          </div>
                          <div class="form_field_align">
                              <label class="show_in_keyboard">First Name</label>
                              <span class="error" v-if="validation.hasError('model.tenant_first_name')">{{ validation.firstError('model.tenant_first_name') }}</span>
                              <input type="text" autocomplete="none" name="first-name"  v-model="model.tenant_first_name" placeholder="First Name*" :class="{ input_error: validation.hasError('model.tenant_first_name') }" @focus="showKeyBoard" data-layout="normal" :maxlength="45">
                          </div>
                          <div class="form_field_align">
                              <label class="show_in_keyboard">Last Name</label>
                              <span class="error" v-if="validation.hasError('model.tenant_last_name')">{{ validation.firstError('model.tenant_last_name') }}</span>
                              <input type="text" autocomplete="none" name="last-name"  v-model="model.tenant_last_name" placeholder="Last Name*" :class="{ input_error: validation.hasError('model.tenant_last_name') }" @focus="showKeyBoard" data-layout="normal" :maxlength="45">
                          </div>
                      </div>
                    </div>
                    <div class="info_wrapper">
                        <div v-if="$store.state.skipAndVerify" class="form_row">
                            <div class="form_field_align">
                              <label class="show_in_keyboard">Email</label>
                              <span class="error" v-if="validation.hasError('model.tenant_email')">{{ validation.firstError('model.tenant_email') }}</span>
                              <input type="text" autocomplete="none" class="email" name="username"  v-model="model.tenant_email" placeholder="Email*" :class="{ input_error: validation.hasError('model.tenant_email'), highlight: validation.hasError('model.tenant_email') }" @focus="showKeyBoard" data-layout="email">
                            </div>
                            <div class="form_field_align">
                              <label class="show_in_keyboard">Cell Phone</label>
                              <span class="error" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span>
                              <input type="text" autocomplete="none" v-mask="'(###) ###-####'" name="phone3"  v-model="model.tenant_phone" placeholder="Cell Phone*" :class="{ input_error: validation.hasError('model.tenant_phone'), highlight: validation.hasError('model.tenant_phone') }" @focus="showKeyBoard" data-layout="phone"/>
                            </div>
                            <div class="form_field_align">
                              <label class="show_in_keyboard">First Name</label>
                              <span class="error" v-if="validation.hasError('model.tenant_first_name')">{{ validation.firstError('model.tenant_first_name') }}</span>
                              <input type="text" autocomplete="none" name="first-name"  v-model="model.tenant_first_name" placeholder="First Name*" :class="{ input_error: validation.hasError('model.tenant_first_name') }" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="form_field_align">
                              <label class="show_in_keyboard">Last Name</label>
                              <span class="error" v-if="validation.hasError('model.tenant_last_name')">{{ validation.firstError('model.tenant_last_name') }}</span>
                              <input type="text" autocomplete="none" name="last-name"  v-model="model.tenant_last_name" placeholder="Last Name*" :class="{ input_error: validation.hasError('model.tenant_last_name') }" @focus="showKeyBoard" data-layout="normal">
                            </div>
                        </div>
                        <h3>Mailing Address</h3>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Address 1</label>
                                <span class="error" v-if="validation.hasError('model.mailing_address1')">{{ validation.firstError('model.mailing_address1') }}</span>
                                <input type="text" autocomplete="none" name="address1"  v-model="model.mailing_address1" placeholder="Address 1*" :class="{ input_error: validation.hasError('model.mailing_address1') }" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Address 2</label>
                                <span class="error" v-if="validation.hasError('address2')">{{ validation.firstError('address2') }}</span>
                                <input type="text" autocomplete="none" name="address2" v-model="model.mailing_address2" placeholder="Address 2" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">City</label>
                                <span class="error" v-if="validation.hasError('model.mailing_city')">{{ validation.firstError('model.mailing_city') }}</span>
                                <input type="text" autocomplete="none" name="city"  v-model="model.mailing_city" placeholder="City*" :class="{ input_error: validation.hasError('model.mailing_city') }" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="half-column">
                                <div class="form_field_align">
                                    <label class="show_in_keyboard">Zip</label>
                                    <span class="error" v-if="validation.hasError('model.mailing_zip')">Invalid zip code</span>
                                    <input type="text" autocomplete="none" name="zip"  v-model="model.mailing_zip" placeholder="Zip*" :class="{ input_error: validation.hasError('model.mailing_zip') }"  @focus="showKeyBoard" data-layout="numeric">
                                </div>
                                <div class="form_field_align form_select">
                                    <label class="show_in_keyboard">State</label>
                                    <span class="error" v-if="validation.hasError('model.mailing_state')">{{ validation.firstError('model.mailing_state') }}</span>
                                    <span v-else-if="model.mailing_zip && !model.mailing_state" class="error">This field is required</span>
                                    <select name="mailing_state"  v-model="model.mailing_state"  class="state_select" :class="{ input_error: validation.hasError('model.mailing_state') || (model.mailing_zip && !model.mailing_state)}" @click="showSelectKeyBoard">
                                        <option v-for="option in options.states" :value="option.value">{{ option.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <h3>Driver License</h3>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">License Number</label>
                                <span class="error" v-if="validation.hasError('model.driving_lis_no')">{{ validation.firstError('model.driving_lis_no') }}</span>
                                <input type="text" autocomplete="none" name="license-number"  v-model="model.driving_lis_no" placeholder="Driver License No.*" :class="{ input_error: validation.hasError('model.driving_lis_no') }" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="half-column">
                                <div class="form_field_align form_select">
                                    <label class="show_in_keyboard">License State</label>
                                    <span class="error" v-if="validation.hasError('model.driving_lis_state')">{{ validation.firstError('model.driving_lis_state') }}</span>
                                    <span v-else-if="model.driving_lis_no && !model.driving_lis_state" class="error">This field is required</span>
                                    <select name="driving_lis_state" @click="showSelectKeyBoard" v-model="model.driving_lis_state"  class="state_select" :class="{ input_error: validation.hasError('model.driving_lis_state') || (model.driving_lis_no && !model.driving_lis_state)}">
                                     <option v-for="option in options.states" :value="option.value">{{ option.text }}</option>
                                    </select>
                                </div>
                                <div class="form_field_align">
                                    <span class="error" v-if="validation.hasError('driving_lis_expiry')">{{ validation.firstError('driving_lis_expiry') }}</span>
                                    <datepicker :input-class="{ input_error: validation.hasError('driving_lis_expiry'), datepicker: true }" format="MM/dd/yyyy" v-model="driving_lis_expiry"  :initialView="'year'" name="license-expiry" placeholder="Expiry*"  :disabled="getLicenseDisabledDates"></datepicker>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_info_btm" v-if="isReservationEnabled">
                <div class="reservation_wrapper" v-if="!findReservationSection">
                    <checkbox :value="isReservation" v-model="isReservation"><span><strong>I need a bit more time.</strong> To reserve, your move-in should begin within {{ facility.advanced_dates }} days</span></checkbox>
                    <datepicker v-if="isReservation" v-model="reservation_date" calendar-class="reservation_calendar" format="MMM dsu, yyyy" :disabled="getReservationDisabledDates" wrapper-class="icon-calendar_icon"></datepicker>
                </div>
                <!-- <div class="form-fields">
                    <div class="form_row">
                        <div class="form_field_align">
                            <label class="show_in_keyboard">Email</label>
                            <span class="error" v-if="validation.hasError('username')">{{ validation.firstError('username') }}</span>
                            <input type="text" autocomplete="none" name="username" v-validate="'required|email'" v-model="model.tenant_email" placeholder="Email*" :class="{ input_error: validation.hasError('username') }" @focus="showKeyBoard" data-layout="email">
                        </div>
                        <div class="form_field_align">
                            <label class="show_in_keyboard">Phone</label>
                            <span class="error" v-if="validation.hasError('phone')">{{ validation.firstError('phone') }}</span>
                            <input type="text" autocomplete="none" v-mask="'(###) ###-####'" name="phone" v-validate="'required'" v-model="model.tenant_phone" placeholder="Phone*" :class="{ input_error: validation.hasError('phone') }" @focus="showKeyBoard" data-layout="numeric"/>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div> -->
            </div>
            <div v-show="isPopupEnabledInfoPage" id="popup2" :class="{'absolute-position': positionAbsolute}" class="overlay">
                  <div class="popup-wrapper">
                        <div class="popup-container">
                          <div class="popup-body">
                            <div v-if="isGuestLoginEnabled">
                              <h2>Session continues on your phone.</h2>
                              <p class="just-in-case">
                                Just in case you did&nbsp;<strong>NOT</strong>&nbsp;get the message
                              </p>
                            </div>
                            <div v-if="isGuestLoginEnabled">
                              <div class="form-container">
                                <div class="form-fields">
                                  <div class="form_row">
                                    <div class="form_field_align">
                                      <label class="show_in_keyboard">Mobile Number</label>
                                      <span class="error" v-if="validation.hasError('model.tenant_phone')">{{ validation.firstError('model.tenant_phone') }}</span>
                                      <input class="pb-input1" type="text" autocomplete="none" v-mask="'(###) ###-####'" name="phone_1_sendText" v-model="model.tenant_phone" placeholder="Mobile Number*" :class="{ input_error: validation.hasError('model.tenant_phone'), highlight: highlight.phone }" @blur="formatPhoneNumber(model.tenant_phone, 'normal')" :disabled="loading" @focus="showKeyBoard" data-layout="submit_phone" />
                                    </div>
                                  </div>
                                </div>
                                <button type="submit" key="resendText" v-loading :class="{loading: loading}" v-if="isGuestLoginEnabled && !validation.hasError('model.tenant_phone')" class="verify-button ripple" @click.prevent="callMethods('phone_1_sendText')">
                                  <span>Resend Text</span>
                                </button>
                                <button v-else class="btn-disabled">Resend Text</button>
                              </div>
                            </div>
                            <div class="break-line">
                              <hr class="hr" />
                              <span class="hr-msg">or</span>
                              <hr class="hr" />
                            </div>
                            <button key="returnToThisDevice-2" v-if="isGuestLoginEnabled" class="btn-full-width" @click="updatePopup('close')">Return to this device</button>
                          </div>
                        </div>
                  </div>
              </div>
            <!--
                commented this code for enable the back button into the license swipe
                <KFooter :cancel-title="dynamicTitle(cancelTitle=true)" :done-title="dynamicTitle()" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter> -->
            <KFooter cancel-title="Back" :done-title="dynamicTitle()" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="business_info" v-if="isActive('business_info')">
            <KHeader title="Business Information" sub_title="Please add the information about your military unit"></KHeader>
            <div class="container_info">
                <div class="form-fields">
                    <h3>Business Rental?</h3>
                    <ul class="radio-wrapper">
                        <li>
                            <radio type="radio" id="business_contact_needed_no" :value="false" v-model="model.business_contact_needed">No</radio>
                        </li>
                        <li>
                            <radio type="radio" id="business_contact_needed_yes" :value="true" v-model="model.business_contact_needed">Yes</radio>
                        </li>
                        <div class="clear"></div>
                    </ul>
                    <div v-if="model.business_contact_needed" class="business-info">
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Company Name</label>
                                <span class="error" v-if="errors.has('business-contact-name')">{{ errors.first('business-contact-name') }}</span>
                                <input :class="{ input_error: errors.has('business-contact-name') }" type="text" autocomplete="none" name="business-contact-name" placeholder="Company Name*" v-validate="'required'" v-model="model.business_contact_name"
                                @focus="showKeyBoard">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Company Name</label>
                                <span class="error" v-if="errors.has('business-contact-email')">{{ errors.first('business-contact-email') }}</span>
                                <input :class="{ input_error: errors.has('business-contact-email') }" type="text" autocomplete="none" name="business-contact-email" placeholder="Company Name*" v-validate="'required|email'" v-model="model.business_contact_email"
                                @focus="showKeyBoard">
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <KFooter cancel-title="Back" done-title="NEXT" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="secondary_contact" v-if="isActive('secondary_contact')">
            <KHeader :title="secondaryContactTitle" :sub_title="secondaryContactSubTitle"></KHeader>
            <div class="container_info">
                <div class="form-fields">
                    <span class="error" v-if="validation.hasError('model.no_secondary_contact')">{{ validation.firstError('model.no_secondary_contact') }}</span>
                    <ul class="radio-wrapper" v-show="!frontendConf.secondary_contact_required">
                        <li>
                            <radio type="radio" id="secondary_contact_yes" :value="false" v-model="model.no_secondary_contact" checked >I would like to provide a secondary contact to receive lien notices.</radio>
                        </li>
                        <li>
                            <radio type="radio" id="secondary_contact_no" :value="true" v-model="model.no_secondary_contact">I waive my rights to a secondary contact.</radio>
                        </li>
                        <div class="clear"></div>
                    </ul>
                    <span class="error secondary_verify" v-if="!isValidSecondaryContactInfo && !model.no_secondary_contact">Mailing Address and Secondary Address can't be same</span>
                    <div v-if="!model.no_secondary_contact" class="secondary-contact">
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">First Name</label>
                                <span class="error" v-if="validation.hasError('model.secondary_contact_first_name')">{{ validation.firstError('model.secondary_contact_first_name') }}</span>
                                <input type="text" autocomplete="none" name="secondary-first-name"  v-model="model.secondary_contact_first_name" placeholder="First Name*" :class="{ input_error: validation.hasError('model.secondary_contact_first_name') }" @focus="showKeyBoard" data-layout="normal" :maxlength="45">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Last Name</label>
                                <span class="error" v-if="validation.hasError('model.secondary_contact_last_name')">{{ validation.firstError('model.secondary_contact_last_name') }}</span>
                                <input type="text" autocomplete="none" name="secondary-last-name"  v-model="model.secondary_contact_last_name" placeholder="Last Name*" :class="{ input_error: validation.hasError('model.secondary_contact_last_name') }" @focus="showKeyBoard" data-layout="normal" :maxlength="45">
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Email</label>
                                <span class="error" v-if="validation.hasError('model.secondary_contact_email')">{{ validation.firstError('model.secondary_contact_email') }}</span>
                                <input type="text" autocomplete="none" class="email" name="secondary-email" v-model="model.secondary_contact_email" :placeholder="secondaryEmailPlaceholder" :class="{ input_error: validation.hasError('model.secondary_contact_email') }" @focus="showKeyBoard" data-layout="email">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Cell Phone</label>
                                <span class="error" v-if="validation.hasError('model.secondary_contact_phone')">{{ validation.firstError('model.secondary_contact_phone') }}</span>
                                <input type="text" autocomplete="none" v-mask="'(###) ###-####'" name="secondary-phone" v-model="model.secondary_contact_phone" placeholder="Cell Phone*" :class="{ input_error: validation.hasError('model.secondary_contact_phone') }" @focus="showKeyBoard" data-layout="phone"/>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Address 1</label>
                                <span class="error" v-if="validation.hasError('model.secondary_contact_address1')">{{ validation.firstError('model.secondary_contact_address1') }}</span>
                                <input type="text" autocomplete="none" name="secondary-address1" v-model="model.secondary_contact_address1" placeholder="Address 1*" :class="{ input_error: validation.hasError('model.secondary_contact_address1') }" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Address 2</label>
                                <span class="error" v-if="validation.hasError('secondary-address2')">{{ validation.firstError('secondary-address2') }}</span>
                                <input type="text" autocomplete="none" name="secondary-address2" v-model="model.secondary_contact_address2" placeholder="Address 2" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">City</label>
                                <span class="error" v-if="validation.hasError('model.secondary_contact_city')">{{ validation.firstError('model.secondary_contact_city') }}</span>
                                <input type="text" autocomplete="none" name="secondary-city" v-model="model.secondary_contact_city" placeholder="City*" :class="{ input_error: validation.hasError('model.secondary_contact_city') }" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="half-column">
                                <div class="form_field_align">
                                    <label class="show_in_keyboard">Zip</label>
                                    <span class="error" v-if="validation.hasError('model.secondary_contact_zipcode')">Invalid zip code</span>
                                    <input type="text" autocomplete="none" name="secondary-zip" v-model="model.secondary_contact_zipcode" placeholder="Zip*" :class="{ input_error: validation.hasError('model.secondary_contact_zipcode') }" @focus="showKeyBoard" data-layout="numeric">
                                </div>
                                <div class="form_field_align form_select">
                                    <label class="show_in_keyboard">State</label>
                                    <span class="error" v-if="validation.hasError('model.secondary_contact_state')">{{ validation.firstError('model.secondary_contact_state') }}</span>
                                    <span v-else-if="model.secondary_contact_zipcode && !model.secondary_contact_state" class="error">This field is required</span>
                                    <select class="state_select" @click="showSelectKeyBoard" :class="{ input_error: validation.hasError('model.secondary_contact_state') || (model.secondary_contact_zipcode && !model.secondary_contact_state)}" name="secondary_contact_state" v-model="model.secondary_contact_state">
                                        <option v-for="option in options.states" :value="option.value">{{ option.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container_info_btm">
                <div class="form-fields">
                    <h2>Are you active military ?*</h2>
                    <span class="error" v-if="validation.hasError('model.active_military')">{{ validation.firstError('model.active_military') }}</span>
                    <ul class="radio-wrapper">
                        <li>
                            <radio type="radio" id="military_no" :value="false" v-model="model.active_military" name="active-military">No</radio>
                        </li>
                        <li>
                            <radio type="radio" id="military_yes" :value="true" v-model="model.active_military" name="active-military">Yes</radio>
                        </li>
                    </ul>
                    <img src="../../assets/graphics/rental/military_man.png">
                </div>
            </div>
            <KFooter cancel-title="Back" done-title="NEXT" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="military_info" v-if="isActive('military_info')">
            <KHeader title="Military Information" sub_title="Please add the information about your military unit"></KHeader>
            <div class="container_info">
                <div class="form-fields">
                    <h3>Are you active military?*</h3>
                    <ul class="radio-wrapper">
                        <li>
                            <radio type="radio" id="military_info_no" :value="false" v-model="model.active_military">No</radio>
                        </li>
                        <li>
                            <radio type="radio" id="military_info_yes" :value="true" v-model="model.active_military">Yes</radio>
                        </li>
                        <div class="clear"></div>
                    </ul>
                    <div v-if="model.active_military" class="military-info">
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">SSN</label>
                                <span class="error" v-if="validation.hasError('model.military_ssn')">{{ validation.firstError('model.military_ssn') }}</span>
                                <input :class="{ input_error: validation.hasError('model.military_ssn') }" type="text" autocomplete="none" v-mask="'###-##-####'" name="military-ssn" placeholder="SSN*" v-model="model.military_ssn"
                                @focus="showKeyBoard" data-layout="numeric">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">ETS</label>
                                <span class="error" v-if="validation.hasError('military_ets_date')">{{ validation.firstError('military_ets_date') }}</span>
                                <datepicker :input-class="{ input_error: validation.hasError('military_ets_date') }" format="MM/dd/yyyy" v-model="military_ets_date"  :initialView="'year'" name="military-ets" placeholder="ETS*" ></datepicker>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Military Email</label>
                                <span class="error" v-if="validation.hasError('model.military_email')">{{ validation.firstError('model.military_email') }}</span>
                                <input type="text" autocomplete="none" class="email" :class="{ input_error: validation.hasError('model.military_email') }" name="military-email" placeholder="Military Email*" v-model="model.military_email" @focus="showKeyBoard" data-layout="email">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Military Contact Name</label>
                                <span class="error" v-if="validation.hasError('model.military_contact_name')">{{ validation.firstError('model.military_contact_name') }}</span>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.military_contact_name') }" name="military-contact-name" placeholder="Military Contact Name*" v-model="model.military_contact_name" @focus="showKeyBoard" data-layout="normal">
                            </div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Commanding Officer</label>
                                <span class="error" v-if="validation.hasError('model.military_commanding_officer')">{{ validation.firstError('model.military_commanding_officer') }}</span>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.military_commanding_officer') }" name="military-co" placeholder="Commanding Officer*" v-model="model.military_commanding_officer" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Military Branch</label>
                                <span class="error" v-if="validation.hasError('model.military_branch')">{{ validation.firstError('model.military_branch') }}</span>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.military_branch') }" name="military-branch" placeholder="Military Branch*" v-model="model.military_branch" @focus="showKeyBoard" data-layout="normal">
                            </div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Military Unit Name</label>
                                <span class="error" v-if="validation.hasError('model.military_name')">{{ validation.firstError('model.military_name') }}</span>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.military_name') }" name="military-unit-name" placeholder="Military Unit Name*" v-model="model.military_name" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="form_field_align">
                                <span class="error" v-if="validation.hasError('model.military_unit_phone')">{{ validation.firstError('model.military_unit_phone') }}</span>
                                <label class="show_in_keyboard">Unit Phone</label>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.military_unit_phone') }" v-mask="'(###) ###-####'" name="military-unit-phone" placeholder="Unit Phone #*" v-model="model.military_unit_phone"
                                @focus="showKeyBoard" data-layout="phone"/>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Address 1</label>
                                <span class="error" v-if="validation.hasError('model.military_address1')">{{ validation.firstError('model.military_address1') }}</span>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.military_address1') }" name="military-address1" placeholder="Address 1*" v-model="model.military_address1" @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Address 2</label>
                                <input type="text" autocomplete="none" name="military-address2" placeholder="Address 2" v-model="model.military_address2"
                                @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="form_row">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">City</label>
                                <span class="error" v-if="validation.hasError('model.military_city')">{{ validation.firstError('model.military_city') }}</span>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.military_city') }" name="military-city" placeholder="City*" v-model="model.military_city"
                                @focus="showKeyBoard" data-layout="normal">
                            </div>
                            <div class="half-column">
                                <div class="form_field_align">
                                    <label class="show_in_keyboard">Zip</label>
                                    <span class="error" v-if="validation.hasError('model.military_zip')">Invalid zip code</span>
                                    <input :class="{ input_error: validation.hasError('model.military_zip') }" type="text" autocomplete="none" name="military-zip" placeholder="ZIP*" v-model="model.military_zip"
                                    @focus="showKeyBoard" data-layout="numeric">
                                </div>
                                <div class="form_field_align form_select">
                                    <label class="show_in_keyboard">State</label>
                                    <span class="error" v-if="validation.hasError('model.military_state')">{{ validation.firstError('model.military_state') }}</span>
                                    <span v-else-if="model.military_zip && !model.military_state" class="error">This field is required</span>
                                    <select class="state_select" @click="showSelectKeyBoard" :class="{ input_error: validation.hasError('model.military_state') || (model.military_zip && !model.military_state)}" name="military_state" v-model="model.military_state">
                                        <option v-for="option in options.states" :value="option.value">{{ option.text }}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="clear"></div>
                        </div>
                    </div>
                </div>
                <img src="../../assets/graphics/rental/military_man.png">
            </div>
            <KFooter cancel-title="Back" done-title="NEXT" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="vehicle_storage" v-if="isActive('vehicle_storage')">
            <KHeader title="Vehicle Storage"></KHeader>
            <div class="container_info">
                <div class="form-fields">
                    <h3 v-if="showStoringVehicleToggle">Are you storing vehicle?*</h3>
                    <span class="error" v-if="validation.hasError('model.store_vehicle')">{{ validation.firstError('model.store_vehicle') }}</span>
                    <ul v-if="showStoringVehicleToggle" class="radio-wrapper">
                        <li>
                            <radio type="radio" id="storing_vehicle_no" :value="false" v-model="model.store_vehicle" name="store_vehicle">No</radio>
                        </li>
                        <li>
                            <radio type="radio" id="storing_vehicle_yes" :value="true" v-model="model.store_vehicle" name="store_vehicle">Yes</radio>
                        </li>
                    </ul>
                    <!--<div v-if="model.store_vehicle && facility.enable_parking_type_fields" class="vehicle-info">-->
                    <div v-if="showParkingTypeFields" class="vehicle-info">
                        <h3>Please choose vehicle type*</h3>
                        <span class="error" v-if="validation.hasError('model.storing_vehicle_type')">{{ validation.firstError('model.storing_vehicle_type') }}</span>
                        <ul class="radio-wrapper">
                            <li v-for="vehicle_type in facility.vehicle_type_kiosk">
                                <radio type="radio" :value="vehicle_type[0]" v-model="model.storing_vehicle_type" name="storing_vehicle_type" >{{ vehicle_type[1] }}</radio>
                            </li>
                            <li v-if="model.storing_vehicle_type=='other'">
                                <label class="show_in_keyboard">Other</label>
                                <input type="text" autocomplete="none" :class="{ input_error: validation.hasError('model.other_vehicle_describe') }" name="other-value" placeholder="Other*" v-model="model.other_vehicle_describe" @focus="showKeyBoard" data-layout="normal">
                                <span class="error" v-if="validation.hasError('model.other_vehicle_describe')">{{ validation.firstError('model.other_vehicle_describe') }}</span>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
            <KFooter cancel-title="Back" done-title="NEXT" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <!-- Other discounts - START -->
        <section id="discounts" v-if="isActive('discounts')">
            <KHeader title="Discounts" sub_title="We will save you even more!"></KHeader>
                <div class="form-fields">
                    <h3>Do you qualify for a discount?</h3>
                    <ul class="radio-wrapper">
                        <li v-if="hasPromo('senior')">
                            <radio type="radio" value="senior" v-model="discounts">Senior</radio>
                        </li>
                        <li v-if="hasPromo('student')">
                            <radio type="radio" value="student" v-model="discounts">Student</radio>
                        </li>
                        <li>
                            <radio type="radio" value="default" v-model="discounts">None</radio>
                        </li>
                        <div class="clear"></div>
                    </ul>
                    <p><span>VALID ID required</span> for the discount to apply upon checkout.</p>
                    <p> Please show your ID to the Facility Manager.</p>
                    <div v-if="model.student" class="mt25">
                        <div class="form_field_align">
                            <label class="show_in_keyboard">School Name</label>
                            <span class="error" v-if="validation.hasError('model.school_name')">{{ validation.firstError('model.school_name') }}</span>
                            <input type="text" autocomplete="none" name="school_name" placeholder="School Name*" v-model="model.school_name" :class="{ input_error: validation.hasError('model.school_name') }" @focus="showKeyBoard" data-layout="normal">
                        </div>
                        <div class="form_field_align">
                            <label class="show_in_keyboard">Student ID</label>
                            <span class="error" v-if="validation.hasError('model.student_id')">{{ validation.firstError('model.student_id') }}</span>
                            <input type="text" autocomplete="none" name="student_id" placeholder="Student ID*" v-model="model.student_id" :class="{ input_error: validation.hasError('model.student_id') }" @focus="showKeyBoard" data-layout="normal">
                        </div>
                    </div>
                    <div v-if="model.senior" class="form_field_align mt25">
                        <span v-show="!validDob" class="error">This field is required</span>
                        <datepicker  id="senior_dob" placeholder="Select birthday" v-model="birthday" :disabled="getRange" :initialView="'year'" :openDate="openDate" :input-class="{input_error: !validDob}" format="MM/dd/yyyy"></datepicker>
                    </div>
                </div>
            <KFooter cancel-title="Back" done-title="NEXT" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>
        <!-- Other discounts - END -->

        <section id="content_insurance" v-if="isActive('content_insurance')">
            <KHeader :title="insuranceHead()" sub_title="Select a plan to protect your valuables"></KHeader>
            <div class="container">
                <div class="radio_button_wrapper custom_radio_box protection_plan_section">
                    <ul  class="round_check">
                        <li><span class="error" v-if="validation.hasError('model.insurance_id')">{{ validation.firstError('model.insurance_id') }}</span></li>
                        <li class="ex_big" v-for="insurance in insurances" v-bind:class="{ most_popular: is_Popular(insurance) }"  v-if="insurance.insurance_id != '-2' && insurance.enabled">
                            <div class="each_insurance">
                                <label v-bind:for="insurance.insurance_id" v-bind:class="{ checked: isChecked(insurance, model) }">
                                <input type="radio" name="insurance_plan" :value="insurance.insurance_id" v-bind:id="insurance.insurance_id"
                                v-model="model.insurance_id"
                                >
                                    <div class="insurance">
                                        <span class="amount_section"><span class="text">Coverage Amount</span>
                                        <span class="amount">${{ insurance.coverage_amount|currency }}</span>
                                        <div class="clear"></div></span>
                                        <span class="monthly_rate"><span class="text">Monthly Premium</span>
                                        <span class="amount">${{ insurance.monthly_premium }}</span></span>
                                    </div>
                                </label>
                                 <div class="popular"><span class="triangle"><span class="text">Most Popular</span></span></div>
                            </div>
                        </li>
                        <li class="ex_big no_insurance" :class=" {'no_insurance_text' : !facility.own_insurance_text}" v-for="insurance in insurances"  v-if="!facility.insurance_required && insurance.insurance_id == '-2'">
                             <div class="each_insurance">
                                <span class="no_insurance_bg">
                                     <label v-bind:for="insurance.insurance_id" v-bind:class="{ checked: isChecked(insurance, model) }">
                                        <input type="radio" name="insurance_plan" :value="insurance.insurance_id" v-bind:id="insurance.insurance_id" v-model="model.insurance_id">
                                        I have my Own Insurance
                                    </label>
                                    <p class="text" v-if="facility.own_insurance_text" v-html="facility.own_insurance_text"></p>
                                </span>
                             </div>
                        </li>
                    </ul>
                    <div class="policy_wrapper">
                            <span class="icon-view_more_icon"></span>
                            <h3>Our Plan will pay for loss of or damage to your property from many causes including:*</h3>
                            <span class="policy_box">
                                <span class="icons">
                                    <span class="icon-fire"></span>
                                    <span class="icon-fire_lighting"></span>
                                </span>
                                <span class="l1">Fire or Lightning, Explosion</span>
                            </span>
                            <span class="policy_box">
                                <span class="icons">
                                    <span class="icon-smoke"></span>
                                    <span class="icon-water"></span>
                                </span>
                                <span class="l2">Smoke or Water Damage; no Flood</span>
                            </span>
                            <span class="policy_box box_3">
                                <span class="icons">
                                    <span class="icon-malicious"></span>
                                    <span class="icon-mischief"></span>
                                </span>
                                <span class="l3">Vandalism, Malicious Mischief or Burglary</span>
                            </span>
                            <span class="policy_box">
                                <span class="icons">
                                    <span class="icon-windstorm"></span>
                                    <span class="icon-hail"></span>
                                </span>
                                <span class="l1">Windstorm or Hail</span>
                            </span>
                            <span class="policy_box">
                                <span class="icons">
                                    <span class="icon-weight_snow"></span>
                                    <span class="icon-weight_ice"></span>
                                </span>
                                <span class="l3">Weight of ice, snow or sleet; Building Collapse</span>
                            </span>
                            <div class="clear"></div>
                            <!-- <img class="default" src="//d6bpjrnvj37va.cloudfront.net/static/graphics/whitelabel/demo_storage_rent-4febef73f2c3ae5e464bd1f4e30953e188e94f15.png"> -->

                        <p class="policy_enabler">
                            <b>*Note this is only a partial list of our coverages.</b> <span v-show="showDocs.insurance">Click below to learn more about our Terms and Conditions, how to maintain your {{ isInsurance }} Plan and how to file a claim in the event of a loss.</span>
                        </p>
                        <div class="clear plan_exxtra_block">
                            <span class="major_button pplan_button protection_plan_brochure" @click.prevent="showBrochure" v-show="showDocs.insurance">View Brochure</span>
                        </div>
                    </div>
                </div>
            </div>
            <KFooter cancel-title="Back" done-title="Select this plan" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>
        <NonInteractiveAgreements v-if="isShowBrochure" show-close-button="true" doc-type="insurance" @closeAction="closeAction"></NonInteractiveAgreements>

        <section id="about_convenience" v-if="isActive('about_convenience')">
            <KHeader title="All about convenience" sub_title="We'd like to make it as easy on you as possible."></KHeader>
            <div class="container">
                <div class="lock-section" v-if="merchandiseItem">
                    <div class="view-port">
                        <h3>ADD A LOCK</h3>
                        <p class="text">Your facility manager will provide you with the perfect lock type for your storage unit.</p>
                        <checkbox :value="merchandiseItem.item_id" v-model="model.item_id" id="merchandiseItem"><span class="">Add a lock<span class="price">{{ merchandiseItem.amount }}</span></span></checkbox>
                    </div>
                    <div class="img-wrapper">
                        <img v-if="merchandiseItem.image_url" :src="merchandiseItem.image_url">
                        <img v-else src="../../assets/graphics/rental/lock.png">
                    </div>
                </div>
                <div class="auto-pay-section">
                    <div class="view-port">
                        <!-- TODO: Handle autpay required -->
                        <h3>ENROLL FOR AUTO DEBIT</h3>
                        <!-- <p class="text">Permanently waive any late fee when you sign for autopay!</p> -->
                        <p class="text" v-html="facility.auto_debit_offer"></p>
                        <span class="error" v-if="validation.hasError('model.auto_debit')">{{ validation.firstError('model.auto_debit') }}</span>
                        <checkbox type="checkbox" id="auto_pay_enroll" :true-value="true" :false-value="false" v-model="model.auto_debit"  name="enroll_auto_pay"><span>Please auto-charge the monthly rent to my credit card every month</span></checkbox>
                    </div>
                </div>
                <div class="gate-code-section" v-if="facility.is_gatecode_selectable && facility.show_gatecode">
                    <div class="view-port">
                        <h3>CREATE YOUR GATECODE</h3>
                        <p class="text">Please choose a gate code with {{ facility.gatecode_length }} numbers</p>
                        <div class="form-fields">
                            <div class="form_row">
                                <div class="form_field_align gate-code">
                                    <label class="show_in_keyboard">Gatecode</label>
                                    <span v-show="validatingGateCode" class="error">Validating gatecode...</span>
                                    <span v-show="!validatingGateCode && !validGateCode && model.gatecode.length == facility.gatecode_length" class="error">Sorry! This gatecode is unavailable</span>
                                    <span :class="{ error: validation.hasError('model.gatecode') }" v-show="validation.hasError('model.gatecode')">{{ validation.firstError('model.gatecode') }}</span>
                                    <input @focus="showKeyBoard" type="text" autocomplete="none" :placeholder="'_ '.repeat(facility.gatecode_length)" name="gatecode" v-model="model.gatecode" :maxlength=" facility.gatecode_length" data-layout="numeric" :class="{gc_error: !validatingGateCode && !validGateCode && model.gatecode.length == facility.gatecode_length}">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <KFooter cancel-title="Back" done-title="NEXT" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="swipe_scan" v-if="isActive('swipe_scan')">
            <KHeader title="Payment" sub_title="Please swipe your credit card"></KHeader>
            <div class="container">
                <img class="swipe-img" src="../../assets/graphics/rental/swipe_card.png">
                <div class="card_separator">
                    <div class="card-border"></div>
                    <div class="card-text">OR</div>
                    <div class="card-border"></div>
                </div>
                <p class="action-link" @click="doneAction">Enter credit card manually</p>
                <img class="to-do-girl" src="../../assets/graphics/rental/do_action_girl.png">
            </div>
        </section>

        <section id="card_details" v-if="isActive('card_details')">
            <KHeader title="Payment" sub_title="Please enter the cvv code"></KHeader>
            <div class="container_info">
                <div class="form-fields">
                    <div class="mailing_check">
                        <checkbox type="checkbox" name="billing_same_as_mailing" id="billing_same_as_mailing" :true-value="true" :false-value="false" v-model="model.billing_same_as_mailing"><span>My Billing address is the same as my Mailing Address.</span></checkbox>
                    </div>
                    <div class="form_row billing_section" v-if="!model.billing_same_as_mailing">
                        <div class="form_field_align">
                            <label class="show_in_keyboard">Address 1</label>
                            <span :class="{ error: errors.has('billing_address1') }" v-show="errors.has('billing_address1')">This field is required</span>
                            <input type="text" autocomplete="none" name="billing_address1"  v-model="model.billing_address1" placeholder="Address 1*" :class="{ input_error: errors.has('billing_address1') }" @focus="showKeyBoard" data-layout="normal" v-validate="'required'">
                        </div>
                        <div class="form_field_align">
                            <label class="show_in_keyboard">Address 2</label>
                            <input type="text" autocomplete="none" name="billing_address2" v-model="model.billing_address2" placeholder="Address 2" :class="" @focus="showKeyBoard" data-layout="normal">
                        </div>
                        <div class="clear"></div>
                        <div class="form_field_align">
                            <label class="show_in_keyboard">City</label>
                            <span :class="{ error: errors.has('billing_city') }" v-show="errors.has('billing_city')">This field is required</span>
                            <input type="text" autocomplete="none" name="billing_city"  v-model="model.billing_city" placeholder="City*" :class="{ input_error: errors.has('billing_city') }" @focus="showKeyBoard" data-layout="normal" v-validate="'required'">
                        </div>
                        <div class="half-column">
                            <div class="form_field_align">
                                <label class="show_in_keyboard">Zip</label>
                                <span :class="{ error: errors.has('billing_zipcode') }" v-show="errors.has('billing_zipcode')">Invalid zip code</span>
                                <input type="text" autocomplete="none" name="billing_zipcode"  v-model="model.billing_zipcode" placeholder="Zip*" :class="{ input_error: errors.has('billing_zipcode') }"  @focus="showKeyBoard" data-layout="numeric" v-validate="'required|digits:5'">
                            </div>
                            <div class="form_field_align form_select">
                                <label class="show_in_keyboard">State</label>
                                <span class="error" v-show="errors.has('billing_state') || model.billing_zipcode && !model.billing_state">This field is required</span>
                                <select class="state_select" @click="showSelectKeyBoard" name="billing_state" v-model="model.billing_state" :class="{ input_error: errors.has('billing_state') || (model.billing_zipcode && !model.billing_state)}" v-validate="'required'">
                                    <option v-for="option in options.states" :value="option.value">{{ option.text }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="form_row">
                        <div class="form_field_align">
                            <label class="show_in_keyboard">Name On Card</label>
                            <!-- <span class="error" v-if="validation.hasError('model.secondary_contact_last_name')">{{ validation.firstError('model.secondary_contact_last_name') }}</span>
                            <span :class="{ error: errors.has('name_on_card') }" v-show="errors.has('name_on_card')">This field is required</span>
                             -->
                             <!-- {{ validation.hasError('name_on_card') }} -->
                             <span :class="{ error: validation.hasError('model.name_on_card') }" v-if="validation.hasError('model.name_on_card')">
                             {{ validation.firstError('model.name_on_card') }}</span>
                            <input type="text" autocomplete="none" :class="{ input_error: errors.has('name_on_card') }" placeholder="Name On Card*" name="name_on_card" v-validate="'required'" v-model="model.name_on_card"  @focus="showKeyBoard" data-layout="normal" :maxlength="100">
                        </div>
                        <div class="form_field_align">
                            <label class="show_in_keyboard">Card Number</label>
                            <span :class="{ error: errors.has('credit_card') }" v-show="errors.has('credit_card')">Invalid credit card</span>
                            <input :class="{ input_error: errors.has('credit_card') }" type="text" autocomplete="none" v-model="model.cc_number" placeholder="XXXX XXXX XXXX XXXX*" name="credit_card" @focus="showKeyBoard" data-layout="numeric" v-mask="creditCardFormatter" v-validate="'required|credit_card'"/>
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="form_row">
                        <div class="form_field_align card-month form_select">
                            <label class="show_in_keyboard">Credit Card Expiry Month</label>
                            <span :class="{ error: errors.has('cc_exp_month') || (model.cvv && !model.cc_exp_month) }" v-show="errors.has('cc_exp_month') || (model.cvv && !model.cc_exp_month)">Invalid expiry date</span>
                            <span class="error" v-if="date_expired">invalid expiry date</span>
                            <select ref="cc_exp_month"  @click="showSelectKeyBoard" :class="{ input_error: errors.has('cc_exp_month') || date_expired || (model.cvv && !model.cc_exp_month)}" name="cc_exp_month" id="cc_exp_month" v-model="model.cc_exp_month" v-validate="'required'">
                                <option :value="month.value" v-for="month in options.months">{{ month.text|formatCardMonth }}</option>
                            </select>
                        </div>
                        <div class="form_field_align card-year form_select">
                            <label class="show_in_keyboard">Credit Card Expiry Year</label>
                            <span :class="{ error: errors.has('cc_exp_year') || (model.cvv && !model.cc_exp_year) }" v-show="errors.has('cc_exp_year') || (model.cvv && !model.cc_exp_year)">Invalid expiry date</span>
                            <select @click="showSelectKeyBoard" :class="{ input_error: errors.has('cc_exp_year') || date_expired || (model.cvv && !model.cc_exp_year) }" name="cc_exp_year" id="cc_exp_year" v-model="model.cc_exp_year" v-validate="'required'">
                                <option :value="year.value" v-for="year in options.years">{{ year.text }}</option>
                            </select>
                        </div>
                        <div class="form_field_align card-cvv">
                            <label class="show_in_keyboard">CVV</label>
                            <span v-if="errors.has('cc_cvv')" :class="{ error: errors.has('cc_cvv') }">{{ errors.first('cc_cvv') }}</span>
                            <span v-else-if="swipeCard && !model.cvv" class="error">This field is required</span>
                            <input type="password" :maxlength="cvvLength" :class="{ input_error: errors.has('cc_cvv') || (swipeCard && !model.cvv) }" name="cc_cvv" v-validate="{ required: true, validate_cvv: model.cc_number }" v-model="model.cvv" placeholder="cvv*"
                            @focus="showKeyBoard" data-layout="numeric" autocomplete="xxxx" id="cvv">
                        </div>
                        <div class="clear"></div>
                    </div>
                    <div class="auto-pay-section" v-if="showAutoDebit">
                        <!-- TODO: Handle autpay required -->
                        <div class="view-port">
                            <h3>ENROLL FOR AUTO DEBIT</h3>
                            <p class="text" v-html="facility.auto_debit_offer"></p>
                            <span class="error" v-if="errors.has('auto_pay_enroll_1')">{{ errors.first('auto_pay_enroll_1') }}</span>
                            <checkbox type="checkbox" name="auto_pay_enroll_1" id="auto_pay_enroll_1" :true-value="true" :false-value="false" v-validate="'required'" v-model="model.auto_debit"><span>Please auto-charge the monthly rent to my credit card every month</span></checkbox>
                        </div>
                    </div>
                </div>
                <img v-if="showAutoDebit" src="../../assets/graphics/girl.png">
            </div>
            <KFooter cancel-title="Swipe a different card" done-title="Complete Rental" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="panda_fields" v-if="isActive('panda_fields')" :class="{loading: sectionLoading}">
            <div class="loader_wrapper" :style="getHeaderStyle">
                <div class="loader"></div>
            </div>
            <div v-if="retryPandaTokens" id="confirm_rentalpay" class="retryPandaAgreements">
                <div class="container">
                    <div class="loader_wrapper error">
                        <h3 class="error_head">Oops!</h3>
                        <div class="ic_confirmation icon-bullet_icon error"></div>
                    </div>
                    <div>
                        <div class="fail-next-to-do">
                            <h4>We are experiencing some problems with additional information.</h4>
                            <h3>Please try again or ask the Manager for help.</h3>
                        </div>
                    </div>
                </div>
                <KFooter cancel-title="" done-title="Retry" @cancelAction="" @doneAction="showTokens"></KFooter>
            </div>
            <KHeader v-if="!retryPandaTokens" title="ADDITIONAL INFORMATION" sub_title="Please add the additional information as necessary to complete your rental"></KHeader>
            <div class="container_info" v-if="!retryPandaTokens">
                <div class="form-fields" ref="pandaFields">
                    <div class="form_row" v-for="section in pandaTokens" :ref="section.title">
                        <h3 v-if="section.title">{{ section.title }}</h3>
                        <p v-if="section.description" class="section_description">{{ section.description }}</p>
                        <span class="error section_error" style="display:none">This field is required</span>
                        <div class="form_field_align checkbox" v-for="field in section.fields" v-if="field.type=='checkbox'">
                            <span :class="{ error: errors.has(field.name) }" v-show="errors.has(field.name)">This field is required</span>
                            <checkbox type="checkbox" :id="field.name" :name="field.name" :class="{ input_error: errors.has(field.name) }" v-validate="{required: field.required}" v-model="model[field.name]" :placeholder="checkAsterisk(field)" value="X">{{ field.label }}</checkbox>
                        </div>
                        <div class="form_field_align" v-else-if="field.type=='textarea'">
                            <label class="show_in_keyboard">Storing Other</label>
                            <span :class="{ error: errors.has(field.name) }" v-show="errors.has(field.name)">This field is required</span>
                            <textarea v-model="model[field.name]" :name="field.name" v-validate="{required: field.required}" :class="{ input_error: errors.has(field.name) }" :placeholder="checkAsterisk(field)" @focus="showKeyBoard" data-layout="normal"/>
                        </div>
                        <div class="form_field_align radio-wrapper" v-else-if="field.type=='radiobox'">
                            <span :class="{ error: errors.has(field.radiobox_group) }" v-show="errors.has(field.radiobox_group)">This field is required</span>
                            <radio  type="radio" :id="field.name"  v-validate="{ rules: 'required', arg:field.radiobox_group }"  :class="{ input_error: errors.has(field.radiobox_group), radio_box_field: true }" v-model="model[field.radiobox_group]" :name="field.radiobox_group" :placeholder="checkAsterisk(field)" :value="field.name" :required="field.required">{{ field.label }}</radio>
                        </div>
                        <div class="form_field_align" v-else-if="field.type=='phone'">
                            <label class="show_in_keyboard">{{ field.label }}</label>
                            <span :class="{ error: errors.has(field.name) }" v-show="errors.has(field.name)">Invalid Phone Number</span>
                            <input type="text" autocomplete="none" v-mask="'(###) ###-####'" :name="field.name" :class="{ input_error: errors.has(field.name) }" v-validate="{ required: field.required, min: 14 }" v-model="model[field.name]" :placeholder="checkAsterisk(field)" @focus="showKeyBoard" data-layout="phone" />
                        </div>
                        <div class="form_field_align" v-else-if="field.type=='ssn'">
                            <label class="show_in_keyboard">{{ field.label }}</label>
                            <span :class="{ error: errors.has(field.name) }" v-show="errors.has(field.name)">This field is required</span>
                            <input type="text" autocomplete="none" v-mask="'###-##-####'" :name="field.name" :class="{ input_error: errors.has(field.name) }" v-validate="{required: field.required}" v-model="model[field.name]" :placeholder="checkAsterisk(field)" @focus="showKeyBoard" data-layout="numeric" />
                        </div>
                        <div class="form_field_align" v-else-if="field.type=='date'">
                            <label class="show_in_keyboard">{{ field.label }}</label>
                            <span :class="{ error: errors.has(field.name) }" v-show="errors.has(field.name)">This field is required</span>
                            <datepicker format="MM/dd/yyyy" :name="field.name" v-validate="{required: field.required}" :class="{ input_error: errors.has(field.name), 'date_picker': true }" v-model="model[field.name]"  :initialView="'year'" :placeholder="checkAsterisk(field)" ></datepicker>
                        </div>
                        <div class="form_field_align" v-else-if="field.type=='email'">
                            <label class="show_in_keyboard">{{ field.label }}</label>
                            <span :class="{ error: errors.has(field.name) }" v-show="errors.has(field.name)">Invalid email</span>
                            <input class="email" type="text" autocomplete="none" :class="{ input_error: errors.has(field.name) }" :name="field.name" v-validate="{required: field.required, email: true}" v-model="model[field.name]" :placeholder="checkAsterisk(field)" @focus="showKeyBoard"  data-layout="email"/>
                        </div>
                        <div class="form_field_align" v-else>
                            <label class="show_in_keyboard">{{ field.label }}</label>
                            <span :class="{ error: errors.has(field.name) }" v-show="errors.has(field.name)">This field is required</span>
                            <input type="text" autocomplete="none" :class="{ input_error: errors.has(field.name) }" :name="field.name" v-validate="{required: field.required}" v-model="model[field.name]" :placeholder="checkAsterisk(field)" @focus="showKeyBoard"  data-layout="normal"/>
                        </div>
                    </div>
                </div>
            </div>
            <KFooter v-if="!retryPandaTokens" cancel-title="Back" done-title="NEXT" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>

        <section id="sign_docs" v-if="isActive('panda_agreements')" :class="{loading: sectionLoading}">
            <div v-if="retryPandaAgreements" id="confirm_rentalpay" class="retryPandaAgreements">
                <div class="container">
                    <div class="loader_wrapper error">
                        <h3 class="error_head">Oops!</h3>
                        <div class="ic_confirmation icon-bullet_icon error"></div>
                    </div>
                    <div>
                        <div class="fail-next-to-do">
                            <h4>We are experiencing some problems with lease agreement.</h4>
                            <h3>Please try again or ask the Manager for help.</h3>
                        </div>
                    </div>
                </div>
                <KFooter cancel-title="" done-title="Retry" @cancelAction="" @doneAction="retryPandaDocuments"></KFooter>
            </div>
            <div v-else>
                <div class="loader_wrapper" :style="getHeaderStyle">
                    <div class="loader"></div>
                </div>
                <header class="pandadoc_header" :style="getHeaderStyle">
                    <h1>Sign documents</h1>
                    <ul class="agreements_list">
                        <li v-for="(doc, index) in panda_agreements" :class="{ active: index==selected_document, 'completed': doc.completed }">{{ doc.display_name }}</li>
                    </ul>
                </header>
                <div class="container">
                    <div class="document_list">
                        <iframe  v-for="(doc, index) in panda_agreements" v-show="index==selected_document" :src="doc.url"></iframe>
                    </div>
                </div>
            </div>
        </section>
        <!-- confirm payment -->
        <section id="confirm_rental" v-if="isActive('confirm_paybill')">
            <div class="container">
                <div class="loader_wrapper">
                    <h3>Congratulations</h3>
                    <p>Your payment has been made</p>
                    <div class="ic_confirmation">
                        <span class="icon-bullet_icon"></span>
                    </div>
                </div>
                <div class="pay_history">
                    <ul>
                        <li>
                            <div>Monthly Rent(unit C20)<span>10/01/17 to 10/30/2017</span></div>
                            <div>$100.00</div>
                        </li>
                        <li>
                            <div>Protection plan(unit C20)<span>10/01/17 to 10/30/2017</span></div>
                            <div>$100.00</div>
                        </li>
                    </ul>
                </div>
                <div class="total_payment">
                    <span>Total payment made</span>
                    <span>$112.00</span>
                </div>
            </div>
            <KFooter cancel-title="Make Another Payment" done-title="Log out" @cancelAction="cancelAction" @doneAction="doneAction"></KFooter>
        </section>
        <!-- Ends -->

        <!--Confirm rental -->
        <section id="confirm_rentalpay" v-if="isActive('confirm_rental')">
            <div class="container">
                <div class="loader_wrapper" :class="{'error': processingError}">
                    <h3 v-if="processingError" class="error_head">Oops!</h3>
                    <h3 v-else-if="!processingError && !isNoke">Congratulations</h3>
                    <h3 v-else>Almost Done!</h3>
                    <p v-if="!processingError && !isNoke" class="success">Your {{ userAction }} is confirmed!</p>
                    <p v-if="!processingError && isNoke" class="success">Simply choose a password to access your online account.</p>
                    <!-- <div class="ic_confirmation" :class="{error: processingError}">
                        <span class="icon-bullet_icon" :class="{error: processingError}"></span>
                    </div> -->
                    <div class="ic_confirmation icon-bullet_icon" :class="{error: processingError, success:!processingError}"></div>
                </div>
                <div v-if="!processingError">
                    <div :class="[{'pt60': isReservation},'create_accnt']" v-if="newUser && !facility.external_paybill">
                        <div v-if="isReservation" class="reservation_code_section">
                            <p>RESERVATION CODE</p>
                            <p>{{ reservationCode }}</p>
                        </div>
                        <div v-else><h3>Create your online account</h3></div>
                        <div class="form-caption_txt">
                            <p v-if="isUpdatePasswordError" class="error">Update Password Failed. Please try again.</p>
                            <p>Please choose a password to log into your online account.</p>
                            <p v-show="!isReservation">Online account features include:</p>
                        </div>
                        <div class="feature-acclist">
                            <ul v-show="!isReservation">
                                <li>Bill Pay</li>
                                <li>Setup Autopay</li>
                                <li>Update Account Information</li>
                            </ul>
                            <p class="password_note">Your password has to be at least 6 characters long, must contain 1 uppercase and 1 special character.</p>
                            <div class="secondary-contact">
                                <div class="form-fields">
                                    <div class="form_row">
                                        <div class="form_field_align">
                                            <label class="show_in_keyboard">Password</label>
                                            <span v-show="errors.has('newpassword')" :class="{ error: errors.has('newpassword') }">{{ errors.first('newpassword') }}</span>
                                            <span class="error show_strong_msg" v-show="!errors.has('newpassword') && showStrongMsg">{{ strongMsg }}</span>
                                            <input type="password" v-model="model.newpassword" name="newpassword" v-bind:class="{ input_error: errors.has('newpassword') }" placeholder="Password" @focus="showKeyBoard" data-layout="normal" v-validate="{ required: true, min:6 }" data-vv-as="password">
                                        </div>
                                        <div class="form_field_align">
                                            <label class="show_in_keyboard">Confirm Password</label>
                                            <span v-show="errors.has('newpassword1')" :class="{ error: errors.has('newpassword1') }">{{ errors.first('newpassword1') }}</span>
                                            <input type="password" v-model="model.newpassword1" name="newpassword1" v-bind:class="{ input_error: errors.has('newpassword1') }" v-validate="{ required: true, confirmed:model.newpassword}" placeholder="Re-enter password" @focus="showKeyBoard"  data-layout="normal" data-vv-as="password">
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="form_row" v-if="showTextCode() && !isNoke">
                                        <div class="form_field_align">
                                            <label class="show_in_keyboard">Phone</label>
                                            <span v-show="errors.has('phonenumber')" :class="{ error: errors.has('phonenumber') }">{{ errors.first('phonenumber') }}</span>
                                            <input type="text" autocomplete="none" v-mask="'(###) ###-####'" v-model="model.tenant_phone" name="phonenumber" v-bind:class="{ input_error: errors.has('phonenumber') }" v-validate="'required'" placeholder="Mobile Phone" @focus="showKeyBoard"  data-layout="phone">
                                        </div>
                                        <div class="view-port checkbox" :class="{form_field_align: isReservation}">
                                            <checkbox id="id_send_gatecode" v-model="model.text_me_gate_code">
                                                <span v-if="isReservation" class="textSms">Text me my {{ codeType }} Code</span>
                                                <span v-else>Resend my {{ codeType }} Code</span>
                                            </checkbox>
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="form_row">
                                        <div class="form_field_align">
                                            <button type="button" class="ripple" @click.prevent="onCreateacc" v-loading :class="{loading: submitPassword}"><span>Create my Account</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div :class="[{'pt60': isReservation},'create_accnt']" v-else>
                        <div v-if="isReservation" class="reservation_code_section">
                            <p>RESERVATION CODE</p>
                            <p>{{ reservationCode }}</p>
                        </div>
                        <div class="form-caption_txt">
                            <p v-show="!isReservation">You're all done. Please proceed to your unit to move in your stuff.</p>
                            <p v-if="gateCodeSMSSuccess" class="success">{{ gateCodeSMSSuccess }}</p>
                            <p v-if="gateCodeSMSError" class="error">{{ gateCodeSMSError }}. Please try again.</p>
                        </div>
                        <div class="feature-acclist" v-if="showTextCode()">
                            <div class="secondary-contact">
                                <div class="form-fields">
                                    <div class="form_row">
                                        <div :class="[{resCodeSms: isReservation},'form_field_align']">
                                            <label class="show_in_keyboard">Phone</label>
                                            <span v-show="errors.has('phonenumber')" :class="{ error: errors.has('phonenumber') }">{{ errors.first('phonenumber') }}</span>
                                            <input type="text" autocomplete="none" v-mask="'(###) ###-####'" v-model="model.tenant_phone"  name="phonenumber" v-bind:class="{ input_error: errors.has('phonenumber') }" v-validate="'required'" placeholder="Mobile Phone" @focus="showKeyBoard"  data-layout="phone">
                                        </div>
                                        <div class="clear"></div>
                                    </div>
                                    <div class="form_row">
                                        <button v-if="isReservation" type="button" class="ripple" @click.prevent="sendGateCode" v-loading :class="{loading: sendingTextMessage, resCodeSms: isReservation}"><span>Text me my {{ codeType }} Code</span></button>
                                        <button v-else type="button" class="ripple" @click.prevent="sendGateCode" v-loading :class="{loading: sendingTextMessage}"><span>Resend my {{ codeType }} Code</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="fail-next-to-do">
                        <h3 v-if="isReservation">Your {{ userAction }} did NOT go through.</h3>
                        <h3 v-else-if="isCCFailure">Your credit card did NOT go through.</h3>
                        <h3 v-else>Your rental did NOT go through.</h3>
                        <h4>Please ask the Manager for help.</h4>
                    </div>
                </div>
            </div>
            <KFooter :done-title="getErrorPageTitle()" @doneAction="doneAction"></KFooter>
        </section>
        <!-- ends -->
        <vue-touch-keyboard id="keyboard" v-if="keyBoardVisible" :layout="layout" :cancel="hideKeyBoard" :accept="acceptValue" :input="input" :next="nextKeyBoardField"  @submit="callMethods(input)" />
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'
import VeeValidate from 'vee-validate'
import CheckboxRadio from 'vue-checkbox-radio'
import Datepicker from 'vuejs-datepicker'
import { mapActions, mapState } from 'vuex'
import KHeader from '../Header'
import KFooter from '../Footer'
import NonInteractiveAgreements from '../NonInteractiveAgreements'
import { keyboardMixin } from '../../mixins/Keyboard.js'
import { PhoneMixin } from '../../mixins/phone.js'
// import { ScannerMixin } from '../../mixins/dlscanner.js'
import Login from '../Paybill/Login'
import Review from './Review'
import ReviewBox from './ReviewBox'
import Slider from './Slider'
import UnitCategory from './UnitCategory'
import SpaceSearch from './SpaceSearch'
import SpaceDetails from './SpaceDetails'
import MTSCRAWebApi from '../../utils/MTSCRAWebApi.js'
import Trivia from '../Trivia'
import API from '../../utils/api'
import moment from 'moment'
// import DLApi from '../../utils/DLApi'
import { AMENITIES, DL_STATES, STATES, MONTHS, YEARS, RENTAL_MODEL } from '../../utils/constants'
import { slugify, zxcvbn } from '../../utils/base'

import SimpleVueValidation from 'simple-vue-validator'
// const dlApi = new DLApi()
Vue.use(VeeValidate)
Vue.use(CheckboxRadio)
const Validator = SimpleVueValidation.Validator.create({
  templates: {
    required: 'This field is required',
    email: 'Invalid email',
    notIn: 'This field is required'
  }
})

Vue.use(SimpleVueValidation)

var api = new API()
var cardAPi = new MTSCRAWebApi()

const RENTAL_FLOW = [
  'rent_this_space',
  'info',
  'secondary_contact',
  'vehicle_storage',
  'content_insurance',
  'about_convenience',
  'swipe_scan',
  'card_details',
  'panda_fields',
  'panda_agreements',
  'process_rental',
  'confirm_rental'
]

const RESERVATION_FLOW = [
  'rent_this_space',
  'info',
  'process_rental',
  'confirm_rental'
]

const FOOTER_TITLES = {
  rent: {
    cancel: 'RENT NOW',
    done: 'RENT THIS SPACE'
  },
  reserve: {
    cancel: 'MAKE A RESERVATION',
    done: 'RESERVE THIS SPACE'
  }
}
// VeeValidate.Validator.extend('validateGateCode', {
//     getMessage(field, args) {
//         return 'Gatecode already taken'
//     },
//     validate(value, args) {
//         api.validateGateCode(value).then(response => true).catch(response => false)
//     }
// })

VeeValidate.Validator.extend('validate_cvv', {
  getMessage: field => 'Invalid CVV',
  validate: (value, ccnum) => {
    if (ccnum[0].startsWith('37') || ccnum[0].startsWith('34')) {
      return (value.length == 3 || value.length == 4)
    }
    return (value.length == 3)
  }
})

/*
    README
        These Vue Datepicker props have been replaced in its latest version:
        - disabled replaced by disabled-dates, and
        - disabled-picker by disabled
        Continue using disabled and disabled-picker for this project!
    */

/*
    TODO
    - Handle all api failures
        - Show proper error messages
        - Reset rental flow if necessary
    -
    */

export default {
  name: 'rental',
  mixins: [keyboardMixin, PhoneMixin],
  props: ['findReservationSection', 'showDocs'],
  data: function () {
    return {
      verificationURL: '',
      interviewId: '',
      clientId: '',
      fieldErrors: '',
      availableUnits: [],
      isShowBrochure: false,
      selectedSpace: {},
      // selectedInsurance: '',
      holdCounter: 0,
      isSelectedUnitAvailable: false,
      sizeCount: 0,
      isSticky: false,
      isReviewActive: false,
      isAmenityActive: false,
      moveInDate: new Date(),
      moveInCost: [],
      activeSectionIndex: 0,
      insurances: [],
      pandaTokens: [],
      loadingDlData: false,
      merchandiseItem: null,
      dynamic_tokens: [],
      panda_agreements: [],
      retryPandaAgreements: false,
      retryPandaTokens: false,
      selected_document: 0,
      completed_document: '',
      sectionLoading: false,
      military_unit_phone: '',
      secondary_contact_phone: '',
      tenant_phone: '',
      cc_number: '',
      newUser: true,
      rrdId: 0,
      sendingTextMessage: false,
      gateCodeSMSSuccess: '',
      gateCodeSMSError: '',
      isUpdatePasswordError: false,
      submitPassword: false,
      date_expired: false,
      cvvLength: 3,
      sizeGuideVideos: [],
      video: '',
      cardData: '',
      loadingReservationDetails: false,
      loadingSpaceNumberSearch: false,
      military_ets_date: '',
      driving_lis_expiry: '',
      processingError: false,
      swipeCard: false,
      selectedPromo: '',
      showAutoDebit: true,
      discounts: '',
      birthday: '',
      validDob: true,
      validLicenseExpiry: true,
      validGateCode: true,
      validPandaRadio: [],
      validatingGateCode: false,
      isReservation: false,
      reservationCode: '', // TODO update using api response
      reservation_date: this.getReservationDate(),
      selectedFlow: RENTAL_FLOW,
      nokePin: null,
      nokeStatus: '',
      paymentRetry: false,
      isCCFailure: false,
      spaceSearchResult: [],
      spaceSearchActive: false,
      showSearchPayBillButton: true,
      space_search_keyword: '',
      isExpand: false,
      showStrongMsg: false,
      strongMsg: '',
      account_id: '',
      moveInCostresponse: {},
      res_mail_req: {},
      rental_mail_req: {},
      // cc_first_name: '',
      // cc_last_name: '',
      model: {
        // Tenant Info
        tenant_first_name: '',
        tenant_last_name: '',
        tenant_email: '',
        tenant_phone: '',
        mailing_address1: '',
        mailing_address2: '',
        mailing_city: '',
        mailing_state: '',
        mailing_zip: '',
        mailing_zipcode: '',
        driving_lis_no: '',
        driving_lis_state: '',
        driving_lis_expiry: '',
        reservation_code: '',
        reservation_date: this.getReservationDate().toLocaleDateString('en-US'),
        // Secondary Contact Info
        no_secondary_contact: '',
        secondary_contact_first_name: '',
        secondary_contact_last_name: '',
        secondary_contact_email: '',
        secondary_contact_phone: '',
        secondary_contact_address1: '',
        secondary_contact_address2: '',
        secondary_contact_city: '',
        secondary_contact_state: '',
        secondary_contact_zipcode: '',
        // Military Info
        active_military: '',
        military_ssn: '',
        military_ets_date: '',
        military_email: '',
        military_contact_name: '',
        military_commanding_officer: '',
        military_branch: '',
        military_name: '',
        military_unit_phone: '',
        military_address1: '',
        military_address2: '',
        military_city: '',
        military_state: '',
        military_zip: '',

        // Storing Vehicle
        store_vehicle: null,
        storing_vehicle_type: '',
        other_vehicle_describe: '',

        // Buisness Renatl
        business_contact_needed: false,
        business_contact_name: '',
        business_contact_email: '',
        business_contact_phone: '',
        business_contact_address1: '',
        business_contact_address2: '',
        business_contact_city: '',
        business_contact_state: '',
        business_contact_zipcode: '',

        insurance_id: '',
        item_id: '', // merchandise item id
        promo_id: '',
        gatecode: '',
        reservation_id: '',
        name_on_card: '',
        cc_exp_month: '',
        cc_exp_year: '',
        cc_number: '',
        cvv: '',
        unit_door_number: '',
        unit_type_id: '',
        auto_debit: false,
        hold_token: '',

        password: '',
        phonenumber: '',
        newpassword: '',
        newpassword1: '',

        total_amount: 0,
        senior: false,
        birthday: '',
        student: false,
        school_name: '',
        student_id: '',

        billing_same_as_mailing: true,
        billing_address1: '',
        billing_address2: '',
        billing_city: '',
        billing_state: '',
        billing_zipcode: '',

        dl_front: null,
        selfie: null,
        show_password: false
      },
      dossierRental: {
        rental: {
          tenantInfo: {
            contacts: [
              {
                email: null,
                name: {
                  first: null,
                  last: null
                },
                move_in_date: null,
                type: 'primary',
                phones: [
                  {
                    number: null,
                    type: 'cell'
                  }
                ]
              }
            ]
          },
          tracking: null,
          space: null,
          unit_no: null,
          spaceType: null,
          paymentAmount: null,
          insuranceId: null,
          moveinDate: null,
          is_rental_success: false
        },
        created_time: null,
        updated_time: null,
        trace_id: this.$session.id()
      },
      options: {
        dlStates: DL_STATES,
        states: STATES,
        months: MONTHS,
        years: YEARS
      },
      facilityId: this.$route.params.facId,
      facilityOwnerId: this.$store.state.kioskConfig.owner_id,
      verificationCompleted: false,
      verificationInProgress: false,
      loading: false,
      formattedPhoneNumber: '',
      savedDossier: null,
      isPopupEnabled: false,
      isPopupEnabledInfoPage: false,
      polling: '',
      checkConfidence: false,
      extractedImages: {
        front: null,
        back: null,
        selfie: null
      },
      isGuestLoginEnabled: false,
      isContinueWithMobileEnabled: false,
      isOtpSend: false,
      positionAbsolute: true,
      positionRelative: false,
      submitLogin: false,
      PreviouslyIdVerifiedDetails: {},
      isExistingUserPhone: false,
      otpLoder: false,
      otpCode: null,
      withCountryCode: null,
      showTimer: false,
      hideResendSMS: true,
      countDownValue: null,
      responseError: '',
      inputFieldType: '',
      errorMsg: '',
      idvId: '',
      confidenceInterval: 0,
      isAuthenticated: false,
      failedAttempt: false,
      dlExpired: false,
      incodeImages: [],
      incodeFront: '',
      incodePhoto: '',
      incodeBack: '',
      incodeCroppedDLPhoto: '',
      incodeCroppedDLFront: '',
      incodeCroppedDLSignature: '',
      dossierId: '',
      docIds: {
        users_drivers_license_front: '',
        users_photo: '',
        users_drivers_license_back: '',
        croppedDocument: '',
        croppedPortrait: '',
        croppedSignature: ''
      },
      newMitekResponse: {},
      // axiosInstance: {},
      uploadStatus: {
        drivers_license_front: false,
        drivers_license_back: false,
        photo: false
      }
    }
  },
  validators: {
    // Primary Info
    'model.tenant_first_name': function (value) {
      // return Validator.value(value).required()
      return Validator.value(value).required().regex('^[a-zA-Z ]*$', 'Must only contain alphabetic characters.')
    },
    'model.tenant_last_name': function (value) {
      // return Validator.value(value).required()
      return Validator.value(value).required().regex('^[a-zA-Z ]*$', 'Must only contain alphabetic characters.')
    },
    'model.tenant_email': function (value) { return Validator.value(value).required().email() },
    'model.tenant_phone': function (value) {
      return Validator.value(value.replace(/[^0-9]/g, '')).required().minLength(10).custom(function () {
        if (!Validator.isEmpty(value)) {
          if (value.startsWith('(1')) {
            return 'Invalid Cell Phone Number'
          }
          if (value.length === 14) {
            const number = value.match(/\d+/g).join([])
            return api.validateNumber(number)
              .then((response) => response.data.data.is_valid ? '' : 'Invalid Cell Phone Number')
              // eslint-disable-next-line handle-callback-err
              .catch((error) => 'Invalid Cell Phone Number')
          }
        }
      })
    },
    'model.mailing_address1': function (value) { return Validator.value(value).required() },
    'model.mailing_city': function (value) { return Validator.value(value).required() },
    'model.mailing_state': function (value) { return Validator.value(value).required() },
    'model.mailing_zip': function (value) {
      return Validator.value(value).required().integer().length(5)
    },
    'model.driving_lis_no': function (value) { return Validator.value(value).required() },
    'model.driving_lis_state': function (value) { return Validator.value(value).required() },
    driving_lis_expiry: function (value) {
      return Validator.value(value).required()
      // return Validator.value(value).required().custom(function () {
      //   if (!Validator.isEmpty(value)) {
      //     var today = new Date()
      //     // console.log(value, today)
      //     value = new Date(value.toDateString())
      //     today = new Date(today.toDateString())
      //     // if (value < today) {
      //     //   return 'Invalid expiry date'
      //     // }
      //   }
      // })
    },
    // Secondary Contact
    'model.no_secondary_contact': function (value) { return Validator.value(value).required() },
    'model.secondary_contact_first_name': function (value) { return Validator.value(value).required() },
    'model.secondary_contact_last_name': function (value) { return Validator.value(value).required() },
    'model.secondary_contact_email': function (value) {
      if (this.facility.secondary_email_required) { return Validator.value(value).required().email() }
      return Validator.value(value).email()
    },
    'model.secondary_contact_phone': function (value) {
      return Validator.value(value.replace(/[^0-9]/g, '')).required().minLength(10).custom(function () {
        if (!Validator.isEmpty(value)) {
          if (value.startsWith('(1')) {
            return 'Invalid Cell Phone Number'
          }
          if (value.length === 14) {
            const number = value.match(/\d+/g).join([])
            return api.validateNumber(number)
              .then((response) => response.data.data.is_valid ? '' : 'Invalid Cell Phone Number')
              // eslint-disable-next-line handle-callback-err
              .catch((error) => 'Invalid Cell Phone Number')
          }
        }
      })
    },
    'model.secondary_contact_address1': function (value) { return Validator.value(value).required() },
    'model.secondary_contact_city': function (value) { return Validator.value(value).required() },
    'model.secondary_contact_state': function (value) { return Validator.value(value).required() },
    'model.secondary_contact_zipcode': function (value) {
      return Validator.value(value).required().integer().length(5)
    },
    // Military
    'model.active_military': function (value) { return Validator.value(value).required() },
    'model.military_ssn': function (value) { return Validator.value(value).required() },
    military_ets_date: function (value) { return Validator.value(value).required() },
    'model.military_email': function (value) { return Validator.value(value).required().email() },
    'model.military_contact_name': function (value) { return Validator.value(value).required() },
    'model.military_commanding_officer': function (value) { return Validator.value(value).required() },
    'model.military_branch': function (value) { return Validator.value(value).required() },
    'model.military_name': function (value) { return Validator.value(value).required() },
    'model.military_unit_phone': function (value) {
      return Validator.value(value.replace(/[^0-9]/g, '')).required().minLength(10).custom(function () {
        if (!Validator.isEmpty(value)) {
          if (value.startsWith('(1')) {
            return 'Invalid Cell Phone Number'
          }
          if (value.length === 14) {
            const number = value.match(/\d+/g).join([])
            return api.validateNumber(number)
              .then((response) => response.data.data.is_valid ? '' : 'Invalid Cell Phone Number')
              // eslint-disable-next-line handle-callback-err
              .catch((error) => 'Invalid Cell Phone Number')
          }
        }
      })
    },
    'model.military_address1': function (value) { return Validator.value(value).required() },
    'model.military_city': function (value) { return Validator.value(value).required() },
    'model.military_state': function (value) { return Validator.value(value).required() },
    'model.military_zip': function (value) {
      return Validator.value(value).required().integer().length(5)
    },
    // Storing Vehicle
    'model.store_vehicle': function (value) { return Validator.value(value).required() },
    'model.storing_vehicle_type': function (value) { return Validator.value(value).required() },
    'model.other_vehicle_describe': function (value) { return Validator.value(value).required() },
    // Insurance, Gatecode, Auto Debit
    'model.insurance_id': function (value) {
      if (!this.insurances.length == 0) {
        return Validator.value(value).required()
      }
    },
    'model.gatecode': function (value) {
      return Validator.value(value).required().length(this.facility.gatecode_length)
    },
    'model.auto_debit': function (value) {
      if (this.facility.autopay_always_required) { return Validator.value(value).notIn([false]) }
    },
    // Card Section
    'model.name_on_card': function (value) {
      // return Validator.value(value).required()
      return Validator.value(value).required().regex('^[a-zA-Z ]*$', 'Must only contain alphabetic characters.')
    },
    'model.cc_exp_month': function (value) { return Validator.value(value).required() },
    'model.cc_exp_year': function (value) { return Validator.value(value).required() },
    'model.cvv': function (value) { return Validator.value(value).required() },
    'model.cc_number': function (value) {
      // TODO: Add credit card validation
      return Validator.value(value).required()
    },
    'model.cvv': function (value) { return Validator.value(value).required() },
    // Senior Promo
    // TODO: requied only if senior is selected
    birthday: function (value) { return Validator.value(value).required() },
    // Student Promo
    'model.school_name': function (value) { return Validator.value(value).required() },
    'model.student_id': function (value) { return Validator.value(value).required() },
    // Find my reservation
    'model.reservation_code': function (value) { return Validator.value(value).required() },
    'model.password': function (value) { return Validator.value(value).required().minLength(6) },
    'model.newpassword': function (value) { return Validator.value(value).required().minLength(6) },
    'model.newpassword1': function (value) { return Validator.value(value).required().minLength(6) },
    otpCode: function (value) { return Validator.value(value).required().minLength(1) }
    // Billing address same as mailing address we setting ve validate for this
    // 'model.billing_address1': function (value) {return Validator.value(value).required()},
    // 'model.billing_city': function (value) {return Validator.value(value).required()},
    // 'model.billing_state': function (value) {return Validator.value(value).required()},
    // 'model.billing_zipcode': function (value) {
    //     return Validator.value(value).required().integer().length(5)
    // },
  },
  components: {
    Trivia,
    KHeader,
    KFooter,
    Review,
    Slider,
    ReviewBox,
    UnitCategory,
    Login,
    Datepicker,
    NonInteractiveAgreements,
    SpaceDetails,
    SpaceSearch
  },
  mounted: function () {
    // this.axiosInstance = axios.create({
    //   timeout: 60 * 1000
    // })
    var self = this
    window.validate = this.$validate
    this.releaseSpace()
    this.getFacilityUnits()
    var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
    var messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'
    window[eventMethod](messageEvent, function (e) {
      if (e && e.data) {
        try {
          var message = JSON.parse(e.data)
          if (message && message.event) {
            if (message.event == 'session_view.document.completed') {
              self.completed_document = message.data.uuid
            }
          }
        } catch (e) {
        }
      }
    }, false)

    api.getSizeGuide(true).then((response) => {
      this.sizeGuideVideos = response.data.result
    })
    if (this.openedUnitGroup) {
      this.isSticky = true
    }
    if (this.activeSection == 'hold_unit') {
      // console.log('process Hold calling', this.$session)
      this.processHold()
    }
  },
  beforeDestroy: function () {
    if (this.video) {
      var video = Wistia.api(this.video)
      video.remove()
      video = null
    }
    this.clearOpenedUnitGroup()
  },
  filters: {
    unslugify: function (val) {
      return val.replace('_', ' ')
    },
    formatMoveInDate: function (moveInDate) {
      const date = moveInDate.toDateString().split(' ')
      return date[1] + ' ' + date[2] + ', ' + date[3]
    },
    formatDescription: function (cost, index) {
      let formattedDescription = cost.description
      if (cost.name == 'TOTALS') formattedDescription = 'Total Cost to Move In'
      if (cost.name.toLowerCase() == 'rent' && index == 0) formattedDescription = 'Prorated Rent'
      return formattedDescription
    },
    formateTime: function (val) {
      if (val.split(' - ').length > 1) {
        const splitTime = val.split(' - ').map(x => x.charAt(0) == '0' ? x.slice(1).replace(':00', '') : x.replace(':00', ''))
        val = splitTime.join(' - ')
      }
      return val
    }
  },
  methods: Object.assign(
    {},
    mapActions({
      setMilitary: 'rental/setMilitary',
      setActiveSection: 'rental/setActiveSection',
      setOtherActiveSection: 'setActiveSection',
      generateTokens: 'rental/getPandaTokens',
      generateAgreements: 'rental/getPandaAgreements',
      setHoldCookie: 'rental/setHoldCookie',
      releaseSpace: 'rental/releaseSpace',
      processRental: 'rental/processRental',
      processAutoPay: 'rental/processAutoPay',
      processMerchandise: 'rental/processMerchandise',
      textGateCode: 'rental/textGateCode',
      validateGateCode: 'rental/validateGateCode',
      updatePassword: 'rental/updatePassword',
      getUserDetails: 'getUserDetails',
      findReservation: 'findReservation',
      setSelectedUnit: 'rental/setSelectedUnit',
      processReservation: 'rental/processReservation',
      getFacilityUnits: 'getFacilityUnits',
      clearOpenedUnitGroup: 'rental/clearOpenedUnitGroup',
      logout: 'logout',
      login: 'login',
      setLogin: 'setLogin'
    }),
    {
      showFacTiming: function (facTiming) {
        if (facTiming == 'office_hours') { return this.facility.enable_office_hours }
        if (facTiming == 'gate_hours') { return this.facility.enable_access_hours }
      },
      clearCCDetails: function () {
        this.swipeCard = false
        this.date_expired = false
        this.model.name_on_card = ''
        this.model.cc_number = ''
        this.model.cc_exp_month = ''
        this.model.cc_exp_year = ''
        this.model.cvv = ''
      },
      convertReservation: function () {
        this.$validator.validateAll().then((result) => {
          if (result && !this.date_expired) {
            this.loadingReservationDetails = true
            this.findReservation(this.model).then(async response => {
              if (response.status == 'error') {
                this.loadingReservationDetails = false
                this.fieldErrors = response.errors
                window.formErrors = this.$validator.errors
              } else {
                this.model.reservation_id = response.result.reservation[0].reservation_id
                var unit_details = response.result.unit_details

                var user = response.result.user_details
                var m = this.model
                m.tenant_first_name = user.first_name
                m.tenant_last_name = user.last_name
                m.tenant_email = user.email
                m.tenant_phone = this.formatPhone(user.phone)
                this.tenant_phone = this.formatPhone(user.phone)
                m.mailing_address1 = user.billing_address1
                m.mailing_address2 = user.billing_address2
                m.mailing_city = user.billing_city
                m.mailing_zip = user.billing_zipcode
                m.mailing_zipcode = user.billing_zipcode
                m.mailing_state = user.billing_state
                m.driving_lis_no = user.driving_lis_no
                m.driving_lis_state = user.driving_lis_state
                var driving_lis_expiry = user.driving_lis_expiry ? new Date(user.driving_lis_expiry) : null
                m.driving_lis_expiry = driving_lis_expiry
                this.driving_lis_expiry = driving_lis_expiry
                m.secondary_contact_first_name = user.secondary_contact_first_name
                m.secondary_contact_last_name = user.secondary_contact_last_name
                m.secondary_contact_email = user.secondary_contact_email
                m.secondary_contact_phone = this.secondary_contact_phone = user.secondary_contact_phone
                m.secondary_contact_address1 = user.secondary_contact_address1
                m.secondary_contact_address2 = user.secondary_contact_address2
                m.secondary_contact_city = user.secondary_contact_city
                m.secondary_contact_state = user.secondary_contact_state || ''
                m.secondary_contact_zipcode = user.secondary_contact_zipcode

                // console.log('selected unit : ', this.selectedUnit)
                this.setSelectedUnit(unit_details)
                this.setSelectedPromo()
                this.selectedSpace.available_unit_id = response.result.reservation[0].space_id
                this.selectedSpace.door_number = response.result.reservation[0].unit_number
                this.selectedSpace.space_type_id = response.result.reservation[0].space_type_id
                this.selectedSpace.available = true

                this.model.unit_door_number = this.selectedSpace.door_number
                this.model.unit_type_id = this.selectedUnit.unit_id

                this.getMoveInCost()
                api.getFacilityInsurance(this.selectedUnit.unit_id).then(response => {
                  this.insurances = response.data.result
                  this.setDefaultInsurance()
                })
                api.getFacilityLockDetail(this.selectedUnit.unit_id).then(response => {
                  this.merchandiseItem = response.data.result[0]
                })
                this.loadingReservationDetails = false
                // this.setActiveSection('info')
                // Create and update dossier to send ID verification link
                await this.getDossierId()
                this.updateDossier(this.selectedSpace)
                this.setActiveSection('rent_this_space')
              }
            })
          }
        })
      },
      spaceNumberSearch: function (keyword) {
        var self = this
        this.video = ''
        this.releaseSpace()
        this.space_search_keyword = keyword
        this.loadingSpaceNumberSearch = true
        this.spaceSearchResult = []
        var exactResult = ''
        const result = this.facilitySpaces.filter(function (el) {
          if (el.door_name.toLowerCase() == keyword.toLowerCase()) {
            exactResult = el
            return false
          }
          return el.door_name.search(new RegExp(keyword, 'i')) >= 0
        })
        var filteredResult = []
        result.forEach(function (el) {
          var unit = self.getUnit(el.unit_id)
          if (unit) {
            el.unit = unit
            el.status = ''
            filteredResult.push(el)
          }
        })
        filteredResult.sort(function (a, b) {
          var sf_diff = a.unit.actual_square_feet - b.unit.actual_square_feet
          if (sf_diff != 0) {
            return sf_diff
          } else {
            var price_diff = a.unit.rental_rate - b.unit.rental_rate
            if (price_diff != 0) {
              return price_diff
            } else {
              if (a.door_name > b.door_name) {
                return 1
              }
              if (a.door_name < b.door_name) {
                return -1
              }
              return 0
            }
          }
        })
        if (exactResult) {
          var unit = self.getUnit(exactResult.unit_id)
          if (unit) {
            exactResult.unit = unit
            exactResult.status = ''
          }
          filteredResult = [exactResult].concat(filteredResult)
        }
        // Log Searched Space Data
        const data = {
          session: this.$session.id(),
          action: 'Search Space',
          searchedSpace: keyword,
          result: JSON.stringify(filteredResult)
        }
        api.logData(data)
        this.spaceSearchResult = filteredResult
        this.loadingSpaceNumberSearch = false
        this.spaceSearchActive = true
        this.showSearchPayBillButton = true
      },
      getUnit: function (unitId) {
        const result = this.allFacilityUnits.find(function (el) {
          return el.unit_id == unitId
        })
        return result
      },
      clearSpaceSearch: function () {
        this.model.space_search_keyword = ''
        this.isExpand = false
        this.spaceSearchResult = []
        this.spaceSearchActive = false
        this.showSearchPayBillButton = true
        this.sizeCount = 0
        this.$validator.reset()
      },
      allSpaceOccupied: function () {
        this.showSearchPayBillButton = false
      },
      nextKeyBoardField () {
        // this.positionAbsolute = false
        const inputs = document.querySelectorAll('input, select')
        let found = false;
        [].forEach.call(inputs, (item, i) => {
          if (!found && item == this.input && i < inputs.length - 1) {
            if (inputs[i + 1].type != 'checkbox' && inputs[i + 1].type != 'radio' && !inputs[i + 1].readOnly && inputs[i + 1].value.length == 0) {
              found = true
              this.$nextTick(async () => {
                await this.hideKeyBoard()// this is a temporary fix
                if (inputs[i + 1].nodeName == 'SELECT') {
                  inputs[i + 1].click()
                } else {
                  inputs[i + 1].focus()
                }
              })
            }
          }
        })
        if (found === true) {
          this.positionAbsolute = false
        }
        this.input.parentNode.classList.remove('in_focus')
        if (!found && this.input) {
          this.input.blur()
          this.hideKeyBoard()
        }
      },
      loginUser: function () {
        this.stopDossierPolling()
        if (this.userToken.length > 0) {
          this.setActiveSection('info')
          this.getLoggedInUserDetails()
        } else {
          // this.setActiveSection('login')
          this.isGuestLoginEnabled = false
          if (this.$store.state.userToken) this.isPopupEnabled = false
          else this.isPopupEnabled = true
        }
      },
      manualVerification: function () {
        this.stopDossierPolling()
        this.setActiveSection('info')
        this.$store.commit('updateSkipAndVerifyStatus', true)
      },
      showBrochure: function () {
        this.isShowBrochure = true
        this.setActiveSection('non_interactive_agreements')
      },
      showTextCode: function () {
        if (!this.isReservation) { return this.newUser ? this.facility.show_gatecode : (!this.gateCodeSMSSuccess && this.facility.show_gatecode) }
        return true
      },
      closeAction: function () {
        this.isShowBrochure = false
        this.setActiveSection('content_insurance')
      },
      updateSizeCount: function (value) {
        this.sizeCount += value
      },
      reviewToggle: function () {
        this.isReviewActive = !this.isReviewActive
      },
      setSticky: function () {
        this.isSticky = true
      },
      unsetSticky: function () {
        this.isSticky = false
        if (this.video) {
          var video = Wistia.api(this.video)
          video.time(0)
          this.video = ''
        }
      },
      isDaily: function (count) {
        return count = !!1
      },
      amenityToggle: function () {
        this.isAmenityActive = !this.isAmenityActive
      },
      getAmenityName: function (val) {
        try {
          return this.amenities[val].name
        } catch (err) {
          return ''
        }
      },
      getAmenityImage: function (amenity) {
        try {
          return { backgroundImage: 'url(' + this.amenities[amenity].image_url + ')' }
        } catch (err) {
          return { backgroundImage: '' }
        }
      },
      isActive: function (section) {
        return section == this.activeSection
      },
      isSideBarActive: function () {
        const disabledSections = ['detail_page', 'panda_agreements', 'non_interactive_agreements', 'find_reservation']
        return !disabledSections.includes(this.activeSection)
        // return this.activeSection != 'detail_page' ? true : false
      },
      displayAmount: function (cost) {
        return cost.amount > 0
      },
      // to remove space and braces
      formatPhoneNumber: async function (number, type) {
        const cleaned = ('' + number).replace(/\D/g, '')
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        // Temporary Fix
        // if (number === '') {
        //   this.model.tenant_phone = ' '
        // }
        if (match) {
          const intlCode = ''
          this.formattedPhoneNumber = [intlCode, match[2], match[3], match[4]].join('')
          if (type === 'otp') { await this.checkUserPhone() }
        }
      },
      async isExpired (date) {
        const expireDate = new Date(date)
        const currentDate = new Date()
        return expireDate < currentDate
      },
      verificationIncodePolling: async function () {
        try {
          const dossierId = this.$store.state?.rental?.dossierId
          const activeSessions = ['rent_this_space', 'info']
          if (!activeSessions.includes(this.$store.state?.rental?.section) || this.$store.state.activeSection === 'home') {
            this.stopDossierPolling()
          }
          const response = await api.getDossierData(dossierId)
          const statusResponse = await api.getIncodeStatus(
            this.facilityId,
            this.interviewId
          )
          const incodeStatus = statusResponse.onboardingStatus
          if (incodeStatus === 'ONBOARDING_FINISHED' && !this.verificationCompleted) {
            const score = await api.getScores(
              this.facilityId,
              this.interviewId
            )
            // this.dlExpired = false
            const overAllRate = Math.round(score.overall.value) * 10
            // if (overAllRate > 500) {
            this.failedAttempt = false
            this.isAuthenticated = true
            clearInterval(this.polling)
            this.verificationCompleted = true
            const resp = await api.getOCRData(
              this.facilityId,
              this.interviewId
            )
            // const isDLExpired = await this.isExpired(Number(resp.expireAt))
            // if (!isDLExpired) {
            const dataImage = await api.getImageData(
              this.facilityId,
              this.interviewId
            )
            const newObj = {
              selfie: {},
              front: {},
              back: {},
              croppedSignature: {},
              croppedPortrait: {},
              croppedDocument: {}
            }

            for (const item in this.captureTypeMap) {
              const captureType = this.captureTypeMap[item]
              newObj[captureType.key].userImg =
                  captureType.key === 'selfie'
                    ? decodeURI(dataImage.croppedFace)
                    : captureType.key === 'front'
                      ? decodeURI(dataImage.croppedFrontID)
                      : decodeURI(dataImage.croppedBackID)

              newObj[captureType.key].document_type = captureType.documentType
              newObj[captureType.key].key = captureType.key
            }

            for (const item in this.captureCroppedTypeMap) {
              const captureType = this.captureCroppedTypeMap[item]
              newObj[captureType.key].userImg =
                  captureType.key === 'croppedPortrait'
                    ? decodeURI(dataImage.croppedIDFace)
                    : captureType.key === 'croppedDocument'
                      ? decodeURI(dataImage.croppedFrontID)
                      : decodeURI(
                          dataImage?.signature ? dataImage?.signature : ''
                      )

              newObj[captureType.key].document_type = captureType.documentType
              newObj[captureType.key].key = captureType.key
            }
            const createUrlResponse = await this.createURL(newObj)
            try {
              for (let i = 0; i < createUrlResponse.length; i++) {
                const photos = await this.getURL(createUrlResponse[i].id)
                if (photos.data && photos.data.file && photos.data.file.attributes) {
                  const key = photos.data.file.file_name
                  let type = ''
                  switch (key) {
                    case 'users_drivers_license_front':
                      this.incodeFront = photos.data.data
                      type = 'front'
                      break
                    case 'users_photo':
                      this.incodePhoto = photos.data.data
                      type = 'photo'
                      break
                    case 'users_drivers_license_back':
                      this.incodeBack = photos.data.data
                      type = 'back'
                      break
                    case 'croppedDocument':
                      this.incodeCroppedDLFront = photos.data.data
                      type = 'croppedDocument'
                      break
                    case 'croppedPortrait':
                      this.incodeCroppedDLPhoto = photos.data.data
                      type = 'croppedPortrait'
                      break
                    case 'croppedSignature':
                      this.incodeCroppedDLSignature = photos?.data?.data || ''
                      type = 'croppedSignature'
                      break
                    default:
                      console.log('Unknown key:', key)
                  }
                  if (type) {
                    const data = {
                      id: createUrlResponse[i].id,
                      type: type
                    }
                    this.docIds[key] = createUrlResponse[i].id
                    this.incodeImages.push(data)
                  }
                }
              }
            } catch (error) {
              console.log('Error fetching URL:', error)
              this.verificationCompleted = false
              this.verificationInProgress = false
              this.failedAttempt = true
            }

            const ocrDatatoIDV = { ...resp }
            delete ocrDatatoIDV.ocrDataConfidence
            delete ocrDatatoIDV.barcodeRawData
            // const phone = this.form.phoneNumber
            //   ? this.form.phoneNumber
            //   : this.prefilledPhoneNumber
            // const email = this.TenantSectionData?.email
            //   ? this.TenantSectionData?.email
            //   : ''
            const reqbody = {
              dossierId: dossierId,
              // email: email,
              // phoneNumber: phone,
              address: {
                address1: resp.addressFields?.street,
                city: resp.addressFields?.city,
                name: resp.name?.fullName,
                postalCode:
                    resp.addressFields?.postalCode.length > 5
                      ? resp.addressFields?.postalCode.substring(0, 5)
                      : resp.addressFields?.postalCode,
                stateCode: resp.addressFields?.state
              },
              authenticated: this.isAuthenticated,
              dob: moment(resp.birthDate).format('YYYY-DD-MM'),
              driversLicense: {
                city: resp.addressFields?.city,
                expiration: moment(Number(resp.expireAt)).format(
                  'YYYY-DD-MM'
                ),
                issued: moment(Number(resp.issuedAt)).format('YYYY-DD-MM'),
                name:
                    resp.name.firstName + ' ' + resp?.name?.paternalLastName,
                number: resp.documentNumber,
                state: resp.addressFields.state
              },
              isBarcodeExtracted: !!resp.barcodeRawData,
              name: {
                first: resp.name?.firstName,
                last: resp.name?.paternalLastName
              },
              probability: overAllRate,
              status:
                  incodeStatus === 'ONBOARDING_FINISHED' ? 'success' : 'failed',
              verification: {
                authenticated: this.isAuthenticated,
                probability: overAllRate
              },
              incodeOCRData: ocrDatatoIDV,
              device: 'desktop',
              sourceApp: 'mariposa',
              documents: [
                {
                  type: 'front',
                  fileId: this.docIds?.users_drivers_license_front
                },
                {
                  type: 'selfie',
                  fileId: this.docIds?.users_photo
                },
                {
                  type: 'back',
                  fileId: this.docIds?.users_drivers_license_back
                },
                {
                  type: 'croppedSignature',
                  fileId: this.docIds?.croppedSignature
                    ? this.docIds?.croppedSignature
                    : ''
                },
                {
                  type: 'croppedPortrait',
                  fileId: this.docIds?.croppedPortrait
                },
                {
                  type: 'croppedDocument',
                  fileId: this.docIds?.croppedDocument
                }
              ]
            }
            this.newMitekResponse = await api.getMitekDetails(
              this.facilityOwnerId,
              this.facilityId,
              this.idvId,
              reqbody
            )
            this.extractedImages.front = decodeURIComponent(this.incodeFront)
            this.extractedImages.selfie = decodeURIComponent(this.incodePhoto)
            this.extractedImages.back = decodeURIComponent(this.incodeBack)
            const extractedData = this.newMitekResponse
            let expDate = resp.expireAt
            let dob = resp.birthDate
            if (expDate) { expDate = moment(Number(expDate)).format('MM/DD/YYYY') }
            if (dob) dob = moment(dob).format('MM/DD/YYYY')
            const updatedRentalDriversLicense = { front: {}, photo: {} }
            updatedRentalDriversLicense.front.url = this.incodeFront
            updatedRentalDriversLicense.photo.url = this.incodePhoto
            extractedData.name = {
              first: resp.name?.firstName,
              last: resp.name?.paternalLastName
            }
            extractedData.address = {
              addressLine1: resp.addressFields?.street,
              addressLine2: resp.checkedAddressBean?.colony,
              postalCode:
                  resp.addressFields?.postalCode.length > 5
                    ? resp.addressFields?.postalCode.substring(0, 5)
                    : resp.addressFields?.postalCode,
              stateProvince: resp.addressFields?.state,
              city: resp.addressFields?.city
            }
            extractedData.documentNumber = resp.documentNumber
            extractedData.dateOfExpiry = expDate
            extractedData.dateOfBirth = dob
            updatedRentalDriversLicense.confidenceInterval = overAllRate
            updatedRentalDriversLicense.expiration = expDate
            updatedRentalDriversLicense.number = resp.documentNumber
            updatedRentalDriversLicense.state = resp.checkedAddressBean?.state
            const dossierData = await api.getDossierData(dossierId)
            const savedDossier = dossierData.body || {}
            extractedData.image = this.newMitekResponse.image
            savedDossier.mitek = extractedData
            savedDossier.mitek.authenticated = this.isAuthenticated
            savedDossier.mitek.isBarcodeExtracted = !!resp?.barcodeRawData
            savedDossier.mitek.probability = overAllRate
            savedDossier.rental.tenantInfo.driversLicense = updatedRentalDriversLicense
            const body = {
              images: {
                front: this.incodeFront,
                selfie: this.incodePhoto
              },
              dlFullName: resp.name.fullName,
              name: { ...response?.body?.name },
              ...savedDossier,
              OTPToken: response?.body?.OTPToken
            }
            await api.updateDossierData(
              dossierId,
              body
            )
            localStorage.removeItem('X-incode-token')
            this.initialLoader = false
            // Checking document authenticated or not
            this.checkConfidence = this.isAuthenticated
            this.verificationCompleted = true
            this.modalShow = false
            this.stopDossierPolling()
            // uses these image id's to call the get file to pull the images back
            // this.updateFields(dataImage)
            await api.getDossierData(dossierId)
              .then(async response => {
                this.updateFields(response.body)
              })
          //     } else {
          //       clearInterval(this.polling)
          //       this.loading = false
          //       this.dlExpired = true
          //       this.verificationCompleted = false
          //       this.verificationInProgress = false
          //       this.failedAttempt = true
          //       this.modalShow = false
          //     }
          //   } else {
          //     clearInterval(this.polling)
          //     this.loading = false
          //     this.verificationCompleted = false
          //     this.verificationInProgress = false
          //     this.failedAttempt = true
          //     this.modalShow = false
          //   }
          }
        } catch (err) {
          console.log(err)
          localStorage.removeItem('X-incode-token')
          this.verificationCompleted = false
          this.verificationInProgress = false
          this.failedAttempt = true
          this.stopDossierPolling()
        }
      },
      verificationPolling () {
        const dossierId = this.$store.state?.rental?.dossierId
        const activeSessions = ['rent_this_space', 'info']
        if (!activeSessions.includes(this.$store.state?.rental?.section) || this.$store.state.activeSection === 'home') {
          this.stopDossierPolling()
        }
        api.getDossierData(dossierId)
          .then(async response => {
            // console.log('getDossierData', response)
            const status = response.body.mitek.state
            if (status === 'received' && !this.verificationCompleted) {
              // this.$store.dispatch('rental/updateIdVerificationStatus', true)
              this.stopDossierPolling()
              this.verificationCompleted = true
              this.verificationInProgress = false
              // uses these image id's to call the get file to pull the images back
              this.updateFields(response.body)
            }
          })
          .catch(err => {
            console.log(err)
            this.verificationCompleted = false
            this.stopDossierPolling()
            this.$store.dispatch('rental/updateDossierPollingStarted', true)
            this.polling = setInterval(() => {
              this.verificationPolling()
            }, 3000)
          })
      },
      focusFieldonClick: async function () {
        const fieldTypes = ['phone_sendText', 'phone_sendText_info', 'phone_sentOtp']
        if (fieldTypes.includes(this.$refs.inputPhone?.name) || fieldTypes.includes(this.inputFieldType)) {
          const type = this.$refs.inputPhone?.name === 'phone_sentOtp' ? 'otp' : 'normal'
          await this.formatPhoneNumber(this.model.tenant_phone, type)
        }
      },
      sendText: async function () {
        await this.focusFieldonClick()
        const validationFields = ['model.tenant_phone']
        this.$validate(validationFields)
        if (!this.validation.hasError('model.tenant_phone') && this.formattedPhoneNumber && !this.loading) {
          try {
            try {
              let response = {}
              const savedToken = JSON.parse(localStorage.getItem('X-incode-token'))
              if (savedToken) {
                const currentTime = new Date().getTime()
                if (currentTime > savedToken.incodeTokenExpireTime) {
                  localStorage.removeItem('X-incode-token')
                }
              }
              // eslint-disable-next-line camelcase
              const incode_token = JSON.parse(localStorage.getItem('X-incode-token'))
              // eslint-disable-next-line camelcase
              if (incode_token) {
                response = await api.getExistingIncodeSessionData(
                  this.facilityId,
                  incode_token.token,
                  this.clientId
                )
                this.verificationURL = response.url
              } else {
                const incodeTokenExpireTime = new Date().getTime() + 15 * 60 * 1000
                response = await api.getIncodeSessionData(this.facilityId)
                this.verificationURL = response.url
                this.interviewId = response.interview_id
                this.clientId = response.clientId
                const token = response.token
                localStorage.setItem(
                  'X-incode-token',
                  JSON.stringify({ token, incodeTokenExpireTime })
                )
              }
            } catch (error) {
              console.error('Error starting Incode:', error)
            }
            this.isGuestLoginEnabled = true
            this.verificationInProgress = true
            this.isPopupEnabled = true
            if (this.inputFieldType === 'phone_sendText_info') { this.isPopupEnabledInfoPage = true }
            const sendNotification = true
            this.loading = true
            const phone = this.formattedPhoneNumber
            const email = this.model?.tenant_email
              ? this.model?.tenant_email
              : ''
            const dossierId = this.$store.state?.rental?.dossierId
            const body = {
              email,
              phone,
              dossierId,
              device: 'desktop',
              incodeVerificationLink: this.verificationURL
            }
            await api.sendSms(
              this.facilityOwnerId,
              this.facilityId,
              body,
              sendNotification
            ).then(async res => {
              const savedDossierData = await api.getDossierData(
                dossierId
              )
              this.logData('Finish Rental')
              // console.log('getDossierData', savedDossierData)
              this.savedDossier = savedDossierData.body || {}
              delete this.savedDossier.mitek
              // const user = {
              //   firstName: this.value.tenant.details.first_name,
              //   lastName: this.value.tenant.details.last_name
              // }
              // this.savedDossier.name = user
              this.savedDossier = savedDossierData.body || {}
              delete this.savedDossier.mitek
              // this.savedDossier.name = user
              this.idvId = res.idvId
              this.savedDossier.idvId = res?.idvId
              this.savedDossier.phone = phone
              // this.savedDossier.mariposaDeviceName = 'desktop'
              // this.savedDossier.rental.ownerId = this.facilityOwnerId
              // this.savedDossier.rental.facilityId = this.facilityId
              await api.updateDossierData(
                dossierId,
                this.savedDossier
              )
              this.loading = false
              this.stopDossierPolling()
              this.$store.dispatch('rental/updateDossierPollingStarted', true)
              this.polling = setInterval(() => {
                this.verificationIncodePolling()
              }, 3000)
            })
          } catch (e) {
            this.loading = false
            console.log('error', e)
          }
        }
      },
      updatePopup: async function (status) {
        if (status === 'close') {
          this.isPopupEnabled = false
          this.isPopupEnabledInfoPage = false
        } else if (status === 'continueWithMobile') {
          this.isContinueWithMobileEnabled = true
          this.model.tenant_phone = ''
        } else if (status === 'continueWithEmail') { this.isContinueWithMobileEnabled = false } else if (status === 'sentOtp') {
          this.otpLoder = true
          await this.sentOtp()
        } else if (status === 'onSubmit') {
          this.onSubmit()
        } else if (status === 'main-close') {
          // For removing validation errors
          if (this.validation?.errors.length) {
            this.validation.errors = [{}]
          }
          this.isPopupEnabled = false
          this.isContinueWithMobileEnabled = false
          this.isOtpSend = false
          this.responseError = ''
        }
      },
      async getDossierId () {
        const createDossierData = {
          created_time: new Date(),
          updated_time: new Date(),
          trace_id: this.dossierRental.trace_id
        }
        this.dossierRental.created_time = new Date()
        this.dossierRental.updated_time = new Date()
        try {
          const dossierDetails = await api.createDossierId(
            this.facilityOwnerId,
            this.facilityId,
            createDossierData
          )
          if (dossierDetails?.dossier_id) this.$store.dispatch('rental/setDossier', dossierDetails?.dossier_id)
          // else console.log('Dossier Null', dossierDetails?.dossier_id)
          // console.log('DossierId', dossierDetails?.dossier_id)
        } catch (e) {
          console.log('e', e)
        }
      },
      updateDossier (selectedSpace) {
        try {
          if (selectedSpace?.available_unit_id) {
            this.dossierRental.rental.space = selectedSpace?.available_unit_id
          }
          this.dossierRental.rental.costs = this.moveInCost
          // this.dossierRental.rental.autopay =
          // this.model.payment.enroll_autopay.enroll_autopay_checkbox ?? false
          const promoId = this.selectedPromo?.promo_id
          this.dossierRental.rental.discountIds = [promoId]
          this.dossierRental.rental.unit_no = selectedSpace?.door_number
          this.dossierRental.rental.moveinDate = this.moveInDate
          this.dossierRental.rental.paymentAmount = this.model.total_amount
          this.dossierRental.updated_time = new Date()
          // // Updating Facility ID , Owner ID & FacilityName
          this.dossierRental.rental.ownerId = this.facilityOwnerId
          this.dossierRental.rental.facilityId = this.facilityId
          // this.dossierRental.rental.facilityName =
          // this.facilityInfo?.rootFacilityInfo?.name?.doing_business_as ||
          // this.facilityInfo?.rootFacilityInfo?.name?.primary_name
          api.updateDossierData(
            this.$store.state?.rental?.dossierId,
            this.dossierRental
          )
        } catch (e) {}
      },
      onSubmit: async function () {
        const validationFields = ['model.password', 'model.tenant_email']
        this.$validate(validationFields)
        if (!this.isOtpSend && !this.validation.hasError('model.tenant_email') && !this.validation.hasError('model.password')) {
          this.$validator.validateAll().then(async (result) => {
            // console.log('result', result)
            if (result) {
              this.submitLogin = true
              const credentials = {
                username: this.model.tenant_email,
                password: this.model.password
              }
              await this.login(credentials).then(async response => {
                // this.PreviouslyIdVerifiedDetails = await api.getPreviouslyVerifiedUserDetails(this.facilityOwnerId, response?.result?.email, response?.result?.user_token)
                // console.log('previousDetails', this.PreviouslyIdVerifiedDetails)
                // if (this.PreviouslyIdVerifiedDetails) { await this.updateFields(this.PreviouslyIdVerifiedDetails) }
                this.submitLogin = false
                // console.log('login-response', response)
                if (response.status === 'error') {
                  this.fieldErrors = response.errors
                  // console.log('fieldErrors', this.fieldErrors)
                } else {
                  this.isPopupEnabled = false
                  this.isContinueWithMobileEnabled = false
                  this.isOtpSend = false
                }
              })
            }
          })
        } else if (this.isOtpSend) {
          this.otpSubmits()
        }
      },
      updateFields: async function (response) {
        let userImages = {}
        if (response?.images) {
          userImages = response?.images
        } else {
          // Taking Cropped Driving License image url if available else take uncropped fromt image of Driving License
          const docId =
              response?.image?.find((e) => e.type === 'croppedDocument')
                ?.id ??
              response?.image?.find((e) => e.type === 'front')?.id
          const frontImageSrc = await this.getURL(docId)
          const portraitImage = this.model.selfie ? this.model.selfie : await this.getURL(
            response?.image?.find((e) => e.type === 'selfie')?.id
          )
          userImages = {
            front: frontImageSrc?.data?.data ? frontImageSrc?.data?.data : frontImageSrc,
            selfie: portraitImage?.data?.data ? portraitImage?.data?.data : portraitImage
          }
        }
        this.extractedImages = { ...userImages }
        // const confidenceInterval = overAllRate
        const extractedData = response.mitek?.data ? response.mitek?.data : response
        // const address1 = extractedData?.address?.addressLine1 ? extractedData?.address?.addressLine1 : extractedData?.address?.address1
        // const address2 = extractedData?.address?.addressLine2 ? extractedData?.address?.addressLine2 : extractedData?.address?.address2
        // comparing if address1 is same as address2
        // const addressLine2 = address1 == address2 ? '' : address2
        this.model.tenant_first_name = extractedData?.name?.first
        this.model.tenant_last_name = extractedData?.name?.last
        this.model.mailing_address1 = extractedData?.address?.address1
        this.model.tenant_phone = this.formatPhone(extractedData?.phoneNumber)
        this.model.mailing_city = extractedData?.address?.city
        this.model.mailing_state = extractedData?.driversLicense?.state
        this.model.mailing_zip = String(extractedData?.address?.postalCode).slice(0, 5)
        this.model.driving_lis_no = extractedData?.driversLicense?.number
        this.model.driving_lis_state = extractedData?.driversLicense?.state
        this.model.dl_front = this.extractedImages.front?.data
          ? this.extractedImages.front?.data
          : this.extractedImages.front
        this.model.selfie = this.extractedImages.selfie?.data
          ? this.extractedImages.selfie?.data
          : this.extractedImages.selfie
        // var expDate = new Date(extractedData?.driversLicense?.expiration.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1-$2-$3'))
        // this.driving_lis_expiry = expDate
        const expirationDate = extractedData?.driversLicense?.expiration
        const [year, day, month] = expirationDate.split('-')
        const formattedDate = `${year}-${month}-${day}`
        const expDate = new Date(formattedDate)
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
        this.driving_lis_expiry = expDate.toLocaleDateString('en-US', options)
        this.checkConfidence = extractedData.authenticated
        this.idvId = extractedData?.id
        this.isPopupEnabled = false
        this.isPopupEnabledInfoPage = false
        this.setActiveSection('info')
      },
      // getPreviousIdVerificationDetails: async function (ownerId, email, userToken) {
      //   try {
      //     if(ownerId && email && userToken) {
      //       const verifiedDetails =  await api.getPreviouslyVerifiedUserDetails(ownerId, email, userToken)
      //       return verifiedDetails
      //     }
      //   } catch (error) {
      //     console.log(error)
      //   }
      // },
      base64ToUint8Array: async function (base64String) {
        try {
          const binaryString = atob(base64String)
          const uint8Array = new Uint8Array(binaryString.length)
          for (let i = 0; i < binaryString.length; i++) {
            uint8Array[i] = binaryString.charCodeAt(i)
          }
          return new Blob([uint8Array], { type: 'image/jpeg' })
        } catch (error) {
          console.error('Error converting base64 to Uint8Array:', error)
        }
      },
      createURL: async function (obj) {
        try {
          const responseArray = await this.fileUpload(obj)
          if (obj?.selfie?.documentType == 'photo') {
            this.uploadComplete = true
          }
          return responseArray
        } catch (err) {
          console.log(err)
        }
      },
      fetchAndEncodeImageFromS3: async function (url) {
        return new Promise((resolve, reject) => {
          fetch(url)
            .then(response => {
              return response.blob()
            })
            .then(blob => {
              const reader = new FileReader()
              reader.readAsDataURL(blob)
              reader.onloadend = () => {
                const base64data = reader.result.split(',')[1]
                resolve(base64data)
              }
              reader.onerror = (error) => {
                reject(error)
              }
            })
            .catch(error => {
              reject(error)
            })
        })
      },
      fileUpload: async function (obj) {
        try {
          const promiseArr = []
          for (const value of Object.values(obj)) {
            if (value.document_type) {
              this.uploadStatus[value.document_type] = true

              const { userImg } = value
              const encodedResponse = await this.fetchAndEncodeImageFromS3(
                userImg
              )
              const date = moment(new Date()).format('YYYYMMDD_HHmmss')
              let body = {}
              if (value.key.includes('cropped')) {
                body = {
                  content_type: 'image/jpeg',
                  file_name: value.key,
                  file_type: 'mitek',
                  facility_id: this.facilityId,
                  attributes: {
                    email: 'test@yahoo.com',
                    document_type: value.document_type
                  }
                // file: encodedResponse
                }
              } else {
                body = {
                  content_type: 'image/jpeg',
                  file_name: `users_${value.document_type}`,
                  file_type: 'mitek',
                  facility_id: this.facilityId,
                  attributes: {
                    email: 'test@yahoo.com',
                    document_type: value.document_type
                  }
                // file: encodedResponse
                }
              }
              const response = await api.getFileUploadURL(
                this.facilityOwnerId,
                body
              )

              const bucketHeaders = {
                'Content-Type': 'image/jpeg',
                'Content-Encoding': 'base64',
                Authorization: '',
                Host: 's3.amazonaws.com',
                Accept: '*/*'
              }
              const formData = new FormData()
              formData.append('key', response.upload_fields.key)
              formData.append(
                'AWSAccessKeyId',
                response.upload_fields.AWSAccessKeyId
              )
              formData.append('Policy', response.upload_fields.policy)
              formData.append('Signature', response.upload_fields.signature)
              formData.append('Content-Type', 'image/jpeg')
              formData.append(
                'File',
                await this.base64ToUint8Array(encodedResponse)
              )
              const imageResponse = await api.uploadToS3(response.upload_url, formData, bucketHeaders)
              promiseArr.push(response)
            }
          }
          return promiseArr
        } catch (error) {
        // Handle any errors occurred during upload process
          this.logData(
            {
              error: error?.toString(),
              dossierId: this.$store.state?.rental?.dossierId,
              idvId: this.idvId
            },
            'IDV FILE UPLOAD FAILURE',
            'error'
          )
        }
      },
      getURL: async function (id) {
        try {
          if (id) {
            const userImg = await api.getIncodeDocumentURL(
              this.facilityOwnerId,
              id
            )
            return userImg
          }
        } catch (error) {
          console.log(error)
        }
      },
      checkUserPhone: async function () {
        if (this.validation.hasError('model.tenant_phone')) {
          // this.phoneError = true
          this.isExistingUserPhone = false
        } else {
          // this.phoneError = false
          try {
            if (
              this.formattedPhoneNumber &&
              this.formattedPhoneNumber !== '' &&
              this.formattedPhoneNumber.length >= 10
            ) {
              this.withCountryCode = '+1' + this.formattedPhoneNumber
              const response = await api.getUser(
                { phone: this.withCountryCode },
                true
              )
              this.isExistingUserPhone = true
            }
          } catch (error) {
            console.log('error', error)
            this.isExistingUserPhone = false
            this.errorMsg = '+1 ' + this.model.tenant_phone + ' has no account. Please login with your existing email or create a new account.'
          }
        }
        // this.$v.phonenumber.$touch()
      },
      sentOtp: async function () {
        await this.focusFieldonClick()
        const validationFields = ['model.tenant_phone']
        this.$validate(validationFields)
        if (!this.validation.hasError('model.tenant_phone') && this.isExistingUserPhone) {
          try {
            const response = await api.getOtpPhone(this.withCountryCode, this.facilityOwnerId)
            this.otpLoder = false
            this.resendSmsCountdownTimer()
            if (Object.keys(response)[0] !== 'errors') {
              this.otpToken = response?.otp_token
              this.positionRelative = true
              this.isOtpSend = true
              this.isContinueWithMobileEnabled = false
              this.responseError = ''
              this.otpCode = ''
              // this.$store.dispatch('otpModule/setOtpTokenAction', this.otpToken)
              // const object = {
              //   phone: this.phonenumber,
              //   enterOtp: true
              // }
              // this.$emit('showPage', object)
            }
          } catch (error) {
            this.otpLoder = false
            const message = error?.data?.data?.errors?.origin?.message
            // this.showError = true
            if (message) {
              this.errorMsg = message
            } else {
              this.errorMsg = 'Something Went Wrong'
            }
          }
        }
        this.otpLoder = false
        //  else if (!this.isExistingUserPhone && !this.validation.hasError('model.tenant_phone')) {
        //   console.log('not-!!!')
        //   this.otpLoder = false
        //   // this.$store.commit(
        //   //   'otpModule/setErrorMessage',
        //   //   `+1 ${
        //   //     this.phoneNumber.formatted
        //   //   }  has no account. Please login with your existing email or create a new account`
        //   // )
        //   // this.$store.commit('otpModule/setExistingEmailAccount', '')
        //   // EventBus.$emit('show-email-login')
        // }
      },
      otpSubmits: async function () {
        // console.log('otpCode-Validation', this.validation.hasError('otpCode'))
        // if (!this.validation.hasError('otpCode')) {}
        this.submitLogin = true
        this.otpLoder = false
        const phone = this.withCountryCode
        const otp = this.otpCode
        const token = this.otpToken
        // this.$emit('otpSubmitMethod', body)
        // this.showTimer = false
        try {
          const response = await api.loginUser({
            phone,
            otp,
            token
          }).then(async (response) => {
            if (response) {
              this.isPopupEnabled = false
              this.isContinueWithMobileEnabled = false
              this.isOtpSend = false
              localStorage.setItem('user_email', response.email)
              localStorage.setItem('user_phone', response.phone)
              await this.setLogin(response)
              await this.getLoggedInUserDetails()
              this.submitLogin = false
              this.responseError = ''
            }
            // console.log('response...', response)
          }).catch((error) => {
            this.submitLogin = false
            this.responseError = 'Invalid Code. Please try again.'
            // console.log('fieldErrors', this.responseError)
          })

          try {
            const responseUserToken = await api.getUserWithToken(
              response.token
            )
            this.setLogin(response)
            // console.log('res-getUserWithToken', responseUserToken)
            // const responseUserTokenObj = api.getApplicationData(
            //   responseUserToken,
            //   this.$env.SDK_PASSPORT_KEY
            // )

            // localStorage.setItem(
            //   'phoneverification',
            //   JSON.stringify(this.pickUserdata(responseUserToken))
            // )
          } catch (error) {
            console.log('Error')
          }
        } catch (error) {

        }
      },
      showPassword () {
        this.$refs.showPassword.type =
        this.$refs.showPassword.type === 'password' ? 'text' : 'password'
      },
      resendSmsCountdownTimer () {
        this.hideResendSMS = true
        this.showTimer = true
        var timeLeft = 60
        var timerId = setInterval(() => {
          if (timeLeft === -1) {
            clearTimeout(timerId)
            this.showTimer = false
            this.hideResendSMS = false
          } else {
            this.countDownValue = timeLeft
            timeLeft--
          }
        }, 1000)
      },
      // Optimization required
      callMethods (methodType) {
        const fieldTypes = ['phone_sendText', 'phone_1_sendText', 'phone_sendText_info', 'phone_sentOtp', 'otp_onSubmit', 'password_onSubmit']
        this.inputFieldType = methodType?.name ? methodType?.name : methodType
        if (fieldTypes.includes(this.inputFieldType)) {
          if (this.inputFieldType === 'phone_sendText' || this.inputFieldType === 'phone_1_sendText' || this.inputFieldType === 'phone_sendText_info') { this.sendText() } else if (this.inputFieldType === 'phone_sentOtp') { this.updatePopup('sentOtp') } else if (this.inputFieldType === 'otp_onSubmit' || this.inputFieldType === 'password_onSubmit') {
            this.updatePopup('onSubmit')
          }
        } else {
          this.convertReservation()
        }
      },
      stopDossierPolling: function () {
        clearInterval(this.polling)
        this.$store.dispatch('rental/updateDossierPollingStarted', false)
      },
      // pickUserdata (response, extra = {}) {
      //   const userObject = response?.user || {}

      //   return {
      //     number: userObject.phone ?? '',
      //     isVerified: userObject.phoneVerified ?? true,
      //     phoneVerificationToken: userObject.phoneVerificationToken ?? '',
      //     emailObject:
      //       pick(userObject, ['email', 'emailVerificationToken', 'emailVerified']) ??
      //       {},
      //     ...extra
      //   }
      // },
      holdUnit: async function () {
        var self = this
        var spaceSelectedBySearch = false
        if (!this.selectedSpace.unit) {
          this.selectedSpace = this.availableUnits
          // console.log('availability = ', availability, this.selectedSpace, this.selectedUnit)
        } else {
          // var availability = 0
          spaceSelectedBySearch = true
        }
        // if (this.holdCounter < availability || this.selectedSpace.unit) {
        if (spaceSelectedBySearch) {
          api.holdSpace(this.selectedSpace.available_unit_id).then(async response => {
            // console.log('hold suceess \n', response.data.result)
            this.model.hold_token = response.data.result.token
            this.model.unit_door_number = this.selectedSpace.door_number
            this.model.unit_type_id = response.data.result.space_type_id
            // Make this common
            this.setHoldCookie({ spcId: this.selectedSpace.available_unit_id, token: this.model.hold_token })
            this.setSelectedPromo()
            await this.getMoveInCost()
            await this.getDossierId()
            this.updateDossier(this.selectedSpace)
            if (this.userToken.length > 0) {
              this.setActiveSection('info')
              this.getLoggedInUserDetails()
            } else {
              if (spaceSelectedBySearch) {
                setTimeout(function () { self.setActiveSection('rent_this_space') }, 1500)
              } else {
                // this code will not execute-need clarification !!!
                this.setActiveSection('rent_this_space')
              }
            }
          }).catch(error => {
            // console.log('Error', error)
            if (spaceSelectedBySearch) {
              this.$dialog.alert('Sorry! Unit ' + this.selectedSpace.door_number + ' is not available. Please choose another unit.')
                .then(function () {
                  if (self.activeSection !== 'find_reservation') {
                    self.setActiveSection('detail_page')
                  }
                })
            }
            // else {
            //     this.holdCounter += 1
            //     this.selectedSpace = {}
            //     this.holdUnit()
            // }
          })
        } else {
          this.model.hold_token = self.selectedSpace.token
          this.model.unit_door_number = self.selectedSpace.door_number
          this.model.unit_type_id = this.selectedUnit.unit_id
          // Make this common
          this.setHoldCookie({ spcId: this.selectedSpace.available_unit_id, token: this.model.hold_token })
          this.setSelectedPromo()
          await this.getMoveInCost()
          await this.getDossierId()
          this.updateDossier(this.selectedSpace)
          if (this.userToken.length > 0) {
            this.setActiveSection('info')
            this.getLoggedInUserDetails()
          } else {
            if (spaceSelectedBySearch) {
              // this code will not execute-need clarification !!!
              setTimeout(function () { self.setActiveSection('rent_this_space') }, 1500)
            } else {
              this.setActiveSection('rent_this_space')
            }
          }
        }
      },
      processHold: function () {
        var self = this
        this.releaseSpace()
        if (this.selectedSpace.unit) {
          // Call hold unit directly if a user selects a space by search
          this.holdUnit()
        } else {
          this.holdCounter = 0
          api.getHoldSpaceTypes(this.selectedUnit.unit_id).then(response => {
            this.availableUnits = response.data.result
            this.holdUnit()
          }).catch(error => {
            // console.log('processHoldError', error)
            this.$dialog.alert('Sorry! Unit is not available. Please choose another unit.')
              .then(function () {
                if (self.activeSection !== 'find_reservation') {
                  self.setActiveSection('detail_page')
                }
              })
          })
        }
        api.getFacilityInsurance(this.selectedUnit.unit_id).then(response => {
          this.insurances = response.data.result
          this.setDefaultInsurance()
          if (this.insurances.length == 0) {
            this.removeInsuranceSection()
          }
        })
        api.getFacilityLockDetail(this.selectedUnit.unit_id).then(response => {
          this.merchandiseItem = response.data.result[0]
        })
      },
      showTokens: function () {
        this.sectionLoading = true
        this.retryPandaTokens = false
        if (this.model.no_secondary_contact) {
          this.model.secondary_contact_first_name = ''
          this.model.secondary_contact_last_name = ''
          this.model.secondary_contact_email = ''
          this.model.secondary_contact_phone = ''
          this.model.secondary_contact_address1 = ''
          this.model.secondary_contact_address2 = ''
          this.model.secondary_contact_city = ''
          this.model.secondary_contact_state = ''
          this.model.secondary_contact_zipcode = ''
        }
        if (!this.model.active_military) {
          this.model.military_ssn = ''
          this.model.military_ets_date = ''
          this.model.military_email = ''
          this.model.military_contact_name = ''
          this.model.military_commanding_officer = ''
          this.model.military_branch = ''
          this.model.military_name = ''
          this.model.military_unit_phone = ''
          this.model.military_address1 = ''
          this.model.military_address2 = ''
          this.model.military_city = ''
          this.model.military_state = ''
          this.model.military_zip = ''
        }
        this.generateTokens(this.model).then(response => {
          var self = this
          this.pandaTokens = response.result.dynamic_tokens
          this.pandaTokens.forEach(function (el) {
            self.dynamic_tokens = [...self.dynamic_tokens, ...el.fields]
          })
          if (self.dynamic_tokens.length == 0) {
            this.setActiveSection('panda_agreements')
          }
          this.sectionLoading = false
        }).catch(error => {
          this.showPandadocTokenRetry()
        })
        this.setActiveSection('panda_fields')
      },
      getPandaDocuments: function () {
        var self = this
        this.sectionLoading = true
        var radioTokens = []
        this.dynamic_tokens.forEach(function (el) {
          var fieldVal = self.model[el.name]
          if (fieldVal) {
            if (el.type == 'checkbox') {
              el.value = 'X'
            } else if (el.type == 'date') {
              el.value = fieldVal.toLocaleDateString('en-US')
            } else {
              el.value = fieldVal
            }
          } else {
            if (el.type == 'checkbox') {
              el.value = ' '
            }
            if (el.type == 'radiobox') {
              var radioVal = self.model[el.radiobox_group]
              if (radioVal && radioVal == el.name) {
                radioTokens.push({ name: el.radiobox_group, value: el.name, type: 'string' })
                el.value = 'X'
              } else {
                el.value = ' '
              }
            } else {
              el.value = ' '
            }
          }
        })
        var dynamicTokens = [...this.dynamic_tokens, ...radioTokens]
        var updatedTokens = [{ fields: dynamicTokens }]
        this.model.dynamic_tokens = JSON.stringify(updatedTokens)
        this.generatePandaAgreements()
        // this.generateAgreements(this.model).then(response => {
        //     this.panda_agreements = response
        //     this.selected_document = 0
        //     this.sectionLoading = false;
        // }).catch(error => {
        //     console.log('catch 1', error)
        //     this.generateAgreements(this.model).then(response => {
        //         this.panda_agreements = response
        //         this.selected_document = 0
        //         this.sectionLoading = false;
        //     }).catch(error => {
        //         console.log('catch 2', error)
        //         this.doRental()
        //     })
        // })
      },
      generatePandaAgreements: function () {
        this.retryPandaAgreements = false
        this.model.space_id = this.selectedSpace.available_unit_id
        this.generateAgreements(this.model).then(response => {
          this.panda_agreements = response
          if (this.panda_agreements.length) {
            this.selected_document = 0
            this.sectionLoading = false
          } else {
            this.showPandadocRetry()
          }
        }).catch(error => {
          this.showPandadocRetry()
        })
      },
      showPandadocRetry: function () {
        this.sectionLoading = false
        this.retryPandaAgreements = true
      },
      showPandadocTokenRetry: function () {
        this.sectionLoading = false
        this.retryPandaTokens = true
      },
      retryPandaTokens () {
        this.retryPandaTokens = false
        this.sectionLoading = true
        this.showTokens()
      },
      retryPandaDocuments () {
        this.retryPandaAgreements = false
        this.sectionLoading = true
        // console.log('retry.................')
        this.generatePandaAgreements()
      },
      getLoggedInUserDetails: async function () {
        this.sectionLoading = true
        this.model.tenant_email = this.$store.state.userEmail
        try {
          this.PreviouslyIdVerifiedDetails = await api.getPreviouslyVerifiedUserDetails(
            this.facilityOwnerId,
            this.$store.state.userEmail,
            this.$store.state.userToken
          )
          this.confidenceInterval = this.PreviouslyIdVerifiedDetails?.verification?.probability
        } catch (error) {
          console.error('An error occurred while fetching previously verified user details:', error)
          this.PreviouslyIdVerifiedDetails = null
        }
        try {
          const userDetailsResponse = await this.getUserDetails(this.PreviouslyIdVerifiedDetails?.id)
          if (userDetailsResponse.status === 'ok') {
            var m = this.model
            var user = userDetailsResponse.result
            m.tenant_first_name = user.first_name
            m.tenant_last_name = user.last_name
            m.tenant_phone = this.formatPhone(user.phone)
            this.tenant_phone = this.formatPhone(user.phone)
            m.mailing_address1 = user.billing_address1
            m.mailing_address2 = user.billing_address2
            m.mailing_city = user.billing_city
            m.mailing_zip = user.billing_zipcode
            m.mailing_zipcode = user.billing_zipcode
            m.mailing_state = user.billing_state
            m.driving_lis_no = user.driving_lis_no
            m.driving_lis_state = user.driving_lis_state
            var driving_lis_expiry = user.driving_lis_expiry ? new Date(user.driving_lis_expiry) : user.driving_lis_expiry
            m.driving_lis_expiry = driving_lis_expiry
            this.driving_lis_expiry = driving_lis_expiry
            m.secondary_contact_first_name = user.secondary_contact_first_name
            m.secondary_contact_last_name = user.secondary_contact_last_name
            m.secondary_contact_email = user.secondary_contact_email
            m.secondary_contact_phone = this.secondary_contact_phone = user.secondary_contact_phone
            m.secondary_contact_address1 = user.secondary_contact_address1
            m.secondary_contact_address2 = user.secondary_contact_address2
            m.secondary_contact_city = user.secondary_contact_city
            m.secondary_contact_state = user.secondary_contact_state || ''
            m.secondary_contact_zipcode = user.secondary_contact_zipcode
          } else {
            console.log('User details retrieval failed:', userDetailsResponse)
          }
        } catch (error) {
          console.error('An error occurred while fetching user details:', error)
        } finally {
          if (this.PreviouslyIdVerifiedDetails && !this.PreviouslyIdVerifiedDetails?.errors) {
            await this.updateFields(this.PreviouslyIdVerifiedDetails)
          }
          this.sectionLoading = false
        }
      },
      validatePandaRadio: function () {
        var radioInputs = document.querySelectorAll("input[type='radio']")
        var self = this
        var validPandaRadio = [];
        [].forEach.call(radioInputs, (item, i) => {
          var rInputGroups = document.querySelectorAll('input[name=' + item.name + ']')
          var validatePanda = true
          if (rInputGroups[0].required) {
            var checkedField = document.querySelectorAll("input[type='radio'][name=" + item.name + ']:checked')
            if (checkedField[0]) {
              validPandaRadio.push(true)
              validatePanda = true
            } else {
              validPandaRadio.push(false)
              validatePanda = false
            }
          } else {
            validPandaRadio.push(true)
            validatePanda = true
          }
          // [].forEach.call(rInputGroups, (rItem, j) => {
          //     if (rItem.required) {
          //         if (rItem.checked) {
          //             this.validPandaRadio = true;
          //         } else {
          //             this.validPandaRadio = false;
          //         }
          //     } else {
          //         this.validPandaRadio = true;
          //     }
          // })
          var parentDiv = self.getClosest(item, '.form_row')
          var errorDiv = parentDiv.querySelectorAll('.section_error')[0]
          if (!validatePanda) {
            errorDiv.style.display = 'block'
          } else {
            errorDiv.style.display = 'none'
          }
        })
        this.validPandaRadio = validPandaRadio
      },
      getClosest: function (elem, selector) {
        // Element.matches() polyfill
        if (!Element.prototype.matches) {
          Element.prototype.matches =
                            Element.prototype.matchesSelector ||
                            Element.prototype.mozMatchesSelector ||
                            Element.prototype.msMatchesSelector ||
                            Element.prototype.oMatchesSelector ||
                            Element.prototype.webkitMatchesSelector ||
                            function (s) {
                              var matches = (this.document || this.ownerDocument).querySelectorAll(s)
                              var i = matches.length
                              while (--i >= 0 && matches.item(i) !== this) {}
                              return i > -1
                            }
        }

        // Get closest match
        for (; elem && elem !== document; elem = elem.parentNode) {
          if (elem.matches(selector)) return elem
        }

        return null
      },
      preValidationChecks: function () {
        if (this.activeSection == 'panda_fields') {
          this.validatePandaRadio()
        }
        this.validLicenseExpiry = !!this.model.driving_lis_expiry

        if (this.model.senior) { this.validDob = !!this.model.birthday } else { this.validDob = true }
      },
      postValidationSteps: function (result, section) {
        if (result && !this.date_expired && this.validDob && this.validGateCode && this.validLicenseExpiry && !this.validPandaRadio.includes(false)) {
          // console.log('Inside postvalidate')
          if (this.isReservation && this.activeSection == 'info') { this.doReservation() } else {
            if (section) {
              // console.log('has section', section)
              // Verify secondary contact details before proceeding
              if (this.activeSection == 'secondary_contact' && !this.isValidSecondaryContactInfo) {
                return
              }
              // console.log('setting active section as', section)
              this.setActiveSection(section)
            } else {
              if (this.isReservation) { this.finishRental() } else {
                // rental
                // console.log('no section')
                if (this.processingError && !this.paymentRetry) {
                  // console.log('this.processingError && !this.paymentRetry')
                  if (this.isCCFailure) {
                    // console.log('this.isCCFailure')
                    this.model.cc_number = ''
                    this.model.cc_exp_month = ''
                    this.model.cc_exp_year = ''
                    this.model.cvv = ''
                    this.panda_agreements = []

                    this.setActiveSection('card_details')
                  } else {
                    // console.log('not this.isCCFailure')
                    this.paymentRetry = true
                    this.doRental()
                  }
                } else {
                  // console.log('no section, is rental, proceed to finishrental')
                  this.finishRental()
                }
              }
            }
          }
        }
      },
      doneAction: function () {
        const section = this.selectedFlow[this.activeSectionIndex + 1]
        if (this.simpleValidatorSections.includes(this.activeSection)) {
          // Simple Vue Validator scoped validation
          this.preValidationChecks()
          this.$validate(this.sectionWiseFields[this.activeSection]).then(result => {
            // console.log('Simple Vue Validator - Validating', this.activeSection, 'section. Check Passed= ', result)
            if (result) {
              // Validation bag accumulates all erroneous fields
              // Reset before proceeding to new section
              this.validation.reset()
              this.logData()
              this.postValidationSteps(result, section)
            }
          })
        } else {
          // Continuing with vee validate
          if (this.activeSection == 'confirm_rental' && !this.processingError) {
            this.finishRental()
          } else {
            this.$validator.validateAll().then(result => {
              this.logData()

              // console.log('error', this.$validator.errors)
              // let validationError = this.$validator.errors.items
              // for (var error of validationError) {
              //     console.log(error.field, error.msg, error.rule)
              // }

              // console.log('Vee validate validation result =', result)

              this.preValidationChecks()
              this.postValidationSteps(result, section)
            })
          }
        }
      },
      cancelAction: function () {
        this.logData('Cancel Action')
        // commented this code for enable the back button into the license swipe
        // if (this.activeSection == 'info')
        //     if (this.findReservationSection)
        //         this.setActiveSection('find_reservation')
        //     else
        //         this.isReservation = !this.isReservation
        // else
        if (this.selectedFlow[this.activeSectionIndex - 1] === 'rent_this_space') { this.stopDossierPolling() }
        this.setActiveSection(this.selectedFlow[this.activeSectionIndex - 1])
      },
      logData: function (action = 'Done Action') {
        let creditCardData = {}
        const modelData = this.maskLogData({ ...this.model })
        if (this.cardData) {
          // creditCardData = {...this.cardData}
          creditCardData = this.maskLogData({ ...this.cardData }, 'creditCardData')
          // let ccNum = creditCardData['card_number']
          // creditCardData['card_number'] = ccNum.slice(0, 4) + 'X'.repeat(ccNum.length - 8) + ccNum.slice(ccNum.length - 4)
        }
        const dlData = { ...this.dlResponse }
        // Remove Image data keys
        const imageData = ['ReformattedImage', 'ReformattedImageTwo', 'SignImage', 'FaceImage']
        for (var key of imageData) {
          delete dlData[key]
        }
        const data = {
          session: this.$session.id(),
          action: action,
          activeSection: this.activeSection,
          isReservation: this.isReservation,
          model: JSON.stringify(modelData),
          userToken: this.userToken,
          userEmail: modelData.tenant_email,
          drivingLicenseData: JSON.stringify(dlData),
          creditCardData: JSON.stringify(creditCardData)
        }
        api.logData(data)
      },
      maskLogData: function (data, type = 'model') {
        const isModel = type === 'model'
        // const ccNumKey = isModel ? 'cc_number' : 'card_number'
        const ccKeys = isModel ? ['cc_number', 'cc_exp_month', 'cc_exp_year', 'cvv'] : ['card_number', 'exp_month', 'exp_year']
        for (var key of ccKeys) {
          data[key] = 'xxx'
        }
        // data[ccNumKey] = this.maskCCNumber(data[ccNumKey])
        return data
      },
      maskCCNumber (ccNum) {
        if (ccNum && ccNum.length) { return ccNum.slice(0, 4) + 'X'.repeat(ccNum.length - 8) + ccNum.slice(ccNum.length - 4) }
      },
      // getDlData: function () {
      //   this.loadingDlData = true
      //   this.acquireImage()
      // },
      is_Popular: function (insurance) {
        if (insurance.is_popular == true) {
          return insurance.is_popular
        }
      },
      isChecked: function (insurance, model) {
        if (insurance.insurance_id == model.insurance_id) {
          return true
        }
      },
      getMoveInCost: async function (retry = false) {
        this.moveInCost = []
        var self = this
        // console.log('MOVE IN COST - request')
        var promoId = (this.selectedPromo) ? this.selectedPromo.promo_id : ''
        this.model.promo_id = promoId
        await api.getMoveInCost({
          unitId: this.selectedUnit.unit_id,
          availableUnitId: this.selectedSpace.available_unit_id,
          promoId: promoId,
          holdToken: this.model.hold_token,
          merchandiseItem: (this.model.item_id) ? this.merchandiseItem.item_id : '',
          insuranceId: this.model.insurance_id,
          reservationId: this.model.reservation_id
        }).then(response => {
          if (response && response.data.status == 'ok') {
            this.moveInCost = this.addTax(response.data.result.costs)
            const amount = this.moveInCost[this.moveInCost.length - 1].total_without_merchandise + this.moveInCost[this.moveInCost.length - 1].tax
            const amount_str = amount.toString()
            let precision = amount_str.length
            const decimalIndex = amount_str.indexOf('.')
            this.moveInCostresponse = response.data.result.gds_raw
            if (decimalIndex < 1) { precision += 2 } else {
              let offset = 2
              if (decimalIndex == 1 && amount_str[0] == 0) { offset = 1 }
              precision = decimalIndex + offset
            }
            this.model.total_amount = parseFloat(amount_str).toPrecision(precision)
          } else {
            if (response == 'cancelled') return
            if (retry) {
              this.$dialog.alert('Sorry! We are unable to calculate move in cost for this unit. Please try again')
                .then(function () {
                  self.setActiveSection('detail_page')
                })
            } else {
              this.getMoveInCost(true)
            }
          }
        })
      },
      showErrorPage: function (rentalData = null, errorResponse = null) {
        this.processingError = true
        // this.setActiveSection('confirm_rental')
        this.setActiveSection(this.selectedFlow[this.activeSectionIndex + 1])
        const response = errorResponse?.response?.data
        if (rentalData && response) {
          try {
            const INVALID_CARD_ERRORS = ['CardInvalid', 'TenantPaymentFailed', 'CardProcessingError', 'CardDeclined', 'CardDeclinedNSF']
            this.isCCFailure = INVALID_CARD_ERRORS.includes(response.errors.origin.code)
          } catch (err) {
            console.log(err)
          }
        }
      },
      removeNokeSections: function () {
        const nokeSections = ['noke_confirm', 'setup_noke']
        for (var section of nokeSections) {
          const index = this.selectedFlow.indexOf(section)
          if (index >= 0) { this.selectedFlow.splice(index, 1) }
        }
      },
      updateConfirmSection: function () {
        const confirmSectionIndex = this.selectedFlow.indexOf('confirm_rental')
        if (this.newUser) {
          if (confirmSectionIndex < 0) { this.selectedFlow.splice(this.selectedFlow.length, 0, 'confirm_rental') }
        } else {
          if (confirmSectionIndex >= 0) { this.selectedFlow.splice(confirmSectionIndex, 1) }
        }
      },
      doReservation: function () {
        this.isCCFailure = false
        this.setActiveSection('process_rental')
        const data = {
          selectedSpace: this.selectedSpace.available_unit_id,
          ...this.model
        }
        this.processReservation(data).then(response => {
          const reservation_response = response.result ? response.result.reservation_response : response.errors.reservation
          this.res_mail_req = {
            'tenant-interface': true,
            source: 'desktop',
            type: response?.result?.type,
            unit_no: data.unit_door_number,
            space_type: data.unit_type_id,
            ...reservation_response,
            space: {
              id: data.selectedSpace,
              rent: response?.result?.rental_cost,
              spaceType: data.unit_type_id,
              spaceNumber: data.unit_door_number
            }
          }
          if (response.status == 'error') {
            delete this.res_mail_req.space
            this.processingError = true
            api.reservationFailureMail(this.res_mail_req)
          } else {
            this.processingError = false
            this.newUser = response.result.new_user
            this.rrdId = response.result.rrd_id
            this.reservationCode = response.result.reservation_code
            if (!this.capabilities?.pmsNotifications?.reservation) {
              api.reservationSuccessMail(this.res_mail_req)
            }
          }
          // this.setActiveSection('confirm_rental')
          this.setActiveSection(this.selectedFlow[this.activeSectionIndex + 1])
        }).catch(error => {
          this.showErrorPage()
        })
      },
      doRental: function () {
        this.isCCFailure = false
        if (this.model.billing_same_as_mailing) {
          this.model.billing_address1 = this.model.mailing_address1
          this.model.billing_address2 = this.model.mailing_address2
          this.model.billing_city = this.model.mailing_city
          this.model.billing_state = this.model.mailing_state
          this.model.billing_zipcode = this.model.mailing_zip
        }
        var rentalDict = Object.assign({}, this.model)
        rentalDict.has_merchandise = this.model.item_id
        var agreements = []
        var autoPayDocument = ''
        this.panda_agreements.forEach(function (el) {
          if (el.document_type == 'auto_debit_enrollment_form') {
            autoPayDocument = el.document_id
          }
          agreements.push({ document_id: el.document_id, document_type: el.document_type })
        })
        rentalDict.agreements = JSON.stringify(agreements)
        rentalDict.unit_id = this.selectedUnit.unit_id
        rentalDict.available_unit_id = this.selectedSpace.available_unit_id
        rentalDict.merchandise = this.model.item_id ? this.merchandiseItem.item_id : ''
        rentalDict.userToken = this.$store.state.userToken
        rentalDict.confidence_interval = this.confidenceInterval
        if (this.checkConfidence) { rentalDict.idv_id = this.idvId }
        this.processRental(rentalDict).then(response => {
          const rent_cost = this.moveInCost.filter(x => x.name == 'rent')
          this.rental_mail_req = {
            'tenant-interface': true,
            source: 'desktop',
            space_type: this.selectedUnit.unit_id,
            move_in_cost: this.moveInCostresponse,
            rental: response.result ? response.result.rental : response.errors.rental,
            unit_no: this.model.unit_door_number,
            space: {
              id: this.selectedSpace.available_unit_id,
              rent: {
                amount: rent_cost[0].amount,
                description: '',
                costType: 'rent',
                end: 0,
                start: 0,
                tax: rent_cost[0].tax ? rent_cost[0].tax : 0,
                total: 0,
                pmsRaw: null
              },
              spaceType: this.selectedUnit.unit_id,
              spaceNumber: this.model.unit_door_number
            }
          }
          this.rental_mail_req.rental.request.paymentInstrument.card.number = this.model.cc_number
          this.rental_mail_req.rental.response.data.tenant.driversLicense.confidenceInterval = this.confidenceInterval
          if (response.status == 'error') {
            this.showErrorPage(rentalDict, response.errors.rental)
            api.rentalFailureMail(this.rental_mail_req)
          } else {
            this.account_id = response.result.account_id
            this.processingError = false
            this.newUser = response.result.new_user
            this.rrdId = response.result.rrd_id
            this.nokePin = response.result.noke_pin
            this.nokeStatus = response.result.noke_status
            if (!this.capabilities?.pmsNotifications?.rental) { api.rentalSuccessMail(this.rental_mail_req) }
            // this.nokeStatus = 'failed'
            this.processPostRentalTransactions(autoPayDocument)
          }
        }).catch(error => {
          this.showErrorPage(rentalDict)
        })
        this.setActiveSection('process_rental')
      },
      processPostRentalTransactions: function (autoPayDocument) {
        // this.setActiveSection('confirm_rental')
        this.sendGateCode(false) // Prevent send me gate code section from being hidden in confirmation  page
        if (this.isNoke) {
          this.removeNokeSections()
          this.selectedFlow.splice(this.activeSectionIndex + 1, 0, 'noke_confirm')
          if (this.nokePin) { this.selectedFlow.splice(this.activeSectionIndex + 2, 0, 'setup_noke') }
          this.updateConfirmSection()
        }

        this.setActiveSection(this.selectedFlow[this.activeSectionIndex + 1])
        // if (this.model.item_id) {
        //     var merchandiseReqObj = {
        //         'rrd_id': this.rrdId,
        //         'item_id': this.merchandiseItem.item_id,
        //         'total_amount': this.merchandiseItem.amount + this.merchandiseItem.tax,
        //         'cc_number': this.model.cc_number,
        //         'cc_exp_year': this.model.cc_exp_year,
        //         'cc_exp_month': this.model.cc_exp_month,
        //         'cc_name': this.model.name_on_card,
        //         'cvv': this.model.cvv
        //     }
        //     this.processMerchandise(merchandiseReqObj);
        // }
        if (this.model.auto_debit) {
          var autoDebitObj = {
            tenant_id: this.account_id,
            space_id: this.selectedSpace.available_unit_id,
            rrd_id: this.rrdId,
            // 'total_number': this.merchandiseItem.amount + this.merchandiseItem.tax,
            cc_number: this.model.cc_number,
            cc_exp_year: this.model.cc_exp_year,
            name_on_card: this.model.name_on_card,
            cc_exp_month: this.model.cc_exp_month,
            cvv: this.model.cvv,
            cc_billing_street: this.model.mailing_address1,
            cc_billing_city: this.model.mailing_city,
            cc_billing_state: this.model.mailing_state,
            cc_billing_postal_code: this.model.mailing_zip,
            auto_pay_document_id: autoPayDocument
          }
          this.processAutoPay(autoDebitObj)
        }
      },
      sendGateCode: function (showSuccessMessage = true) {
        const gateCodeReq = {
          resend_sms: true,
          resend_phone_number: this.model.tenant_phone
        }
        this.sendingTextMessage = true
        this.gateCodeSMSError = ''
        this.gateCodeSMSSuccess = ''
        this.$validator.validateAll().then((result) => {
          if (result && !this.date_expired) {
            if (this.isReservation) {
              Object.assign(this.res_mail_req, gateCodeReq)
              this.res_mail_req['tenant-interface'] = true
              api.reservationSuccessMail(this.res_mail_req).then(response => {
                this.sendingTextMessage = false
                if (response.status == 'error') {
                  this.gateCodeSMSError = 'Error occurred while sending message'
                } else {
                  if (showSuccessMessage) { this.gateCodeSMSSuccess = `message sent successfully to ${this.model.tenant_phone}` }
                }
              })
            } else {
              Object.assign(this.rental_mail_req, gateCodeReq)
              this.rental_mail_req['tenant-interface'] = true
              api.rentalSuccessMail(this.rental_mail_req).then(response => {
                this.sendingTextMessage = false
                if (response.status == 'error') {
                  this.gateCodeSMSError = 'Error occurred while sending message'
                } else {
                  if (showSuccessMessage) { this.gateCodeSMSSuccess = `message sent successfully to ${this.model.tenant_phone}` }
                }
              })
            }
          }
        })
      },
      addTax: function (moveInCost) {
        const total = moveInCost[moveInCost.length - 1]
        const tax = {
          name: 'Tax',
          amount: total.tax,
          description: 'Tax'
        }
        moveInCost.splice(moveInCost.length - 1, 0, tax)
        return moveInCost
      },
      isLast: function (index, list) {
        // check if its the last entry of list, based on index
        return index === list.length - 1
      },
      onCreateacc: function () {
        this.showStrongMsg = false
        if (this.model.newpassword == this.model.newpassword1) {
          var passwordObj = { rrd_id: this.rrdId, new_password: this.model.newpassword }
          this.$validator.validateAll().then((result) => {
            if (result && !this.date_expired) {
              var passCheck = zxcvbn(this.model.newpassword)
              if (passCheck.score > 0) {
                this.submitPassword = true
                this.isUpdatePasswordError = false
                this.updatePassword(passwordObj).then(response => {
                  this.submitPassword = false
                  if (response.status == 'error') {
                    this.isUpdatePasswordError = true
                    this.fieldErrors = response.errors
                  } else {
                    this.$store.state.activeSection = 'home'
                  }
                })
                if (this.model.text_me_gate_code) {
                  this.sendGateCode()
                }
              } else {
                this.showStrongMsg = true
                this.strongMsg = 'Password is too weak. '
                if (passCheck.feedback && passCheck.feedback.suggestions[0]) {
                  this.strongMsg = 'Password is too weak. ' + passCheck.feedback.suggestions[0]
                }
              }
            }
          })
        }
      },
      finishRental: function () {
        this.logData('Finish Rental')
        this.$session.destroy()
        this.logout()
        this.$store.state.activeSection = 'home'
      },
      setVideo: function (size) {
        var self = this
        const result = this.sizeGuideVideos.filter(video => video.size == size)
        if (result.length) {
          var video = Wistia.api(result[0].video_id)
          video.time(0)
          try {
            if (window.kioskConfig.mute_audio) {
              video.volume(0)
            }
          } catch (err) {
            video.volume(0)
          }
          video.play()
          this.video = result[0].video_id
        } else {
          this.video = ''
        }
      },
      setSelectedSpace: function (space) {
        this.selectedSpace = space
        this.setSelectedUnit(space.unit)
        this.setActiveSection('hold_unit')
      },
      setDefaultInsurance: function () {
        if (this.defaultInsurance) { this.model.insurance_id = this.defaultInsurance.insurance_id } else { this.model.insurance_id = '' }
      },
      videoClass: function (video) {
        return 'wistia_async_' + video
      },
      requestCardSwipe: function () {
        cardAPi.getCardSwipe().then(response => {
          if (this.activeSection == 'swipe_scan') { this.getDecryptedCardData(response.data) }
        })
      },
      getDecryptedCardData: function (request) {
        api.postCardSwipe(JSON.stringify(request)).then(response => {
          if (response.data.status == 'ok') {
            this.cardData = response.data.result.DecryptedCardSwipe
            this.setActiveSection('card_details')
          }
        })
      },
      validateCreditCardMonthYear: function (m, y) {
        var cMonth = new Date().getMonth() + 1
        var cYear = new Date().getFullYear()
        if (y < cYear) {
          // console.log('invalid date');
          this.date_expired = true
        } else {
          if ((y == cYear) && (m < cMonth)) {
            // console.log('invalid date');
            this.date_expired = true
          }
        }
      },
      setSelectedPromo (promo_type = 'default') {
        // TODO: set default promo initially
        if (this.selectedUnit.promos.length) {
          const filteredPromo = this.selectedUnit.promos.filter(promo => promo.promo_type == promo_type)[0]
          if (filteredPromo) { this.selectedPromo = filteredPromo } else {
            if (promo_type != 'default') { this.setSelectedPromo() } else { this.selectedPromo = '' }
          }
        }
      },
      checkAsterisk (field) {
        return field.required ? field.label + '*' : field.label
      },
      hasPromo (value) {
        return this.unitPromoTypes.includes(value)
      },
      dynamicTitle (cancelTitle = false) {
        // commented this code for enable the back button into the license swipe
        // return cancelTitle ? this.getCancelTitle() : this.getDoneTitle()
        return this.getDoneTitle()
      },
      insuranceHead () {
        return this.facility.has_insurance_plan ? 'Contents Insurance' : 'Contents Protection'
      },
      getCancelTitle () {
        return this.isReservation ? FOOTER_TITLES.rent.cancel : (this.findReservationSection ? 'back' : FOOTER_TITLES.reserve.cancel)
      },
      getDoneTitle () {
        if (this.activeSection == 'noke_confirm') { return this.nokePin ? 'Setup Access Control' : this.getConfirmPageTitle() }
        if (this.activeSection == 'setup_noke') { return this.getConfirmPageTitle() }
        return this.isReservation ? FOOTER_TITLES.reserve.done : FOOTER_TITLES.rent.done
      },
      getConfirmPageTitle () {
        return this.newUser ? 'Create Your Online Account' : 'Finish'
      },
      getReservationDate () {
        const date = new Date()
        date.setDate(date.getDate() + 1)
        return date
      },
      getUnitDetails () {
        const details = [this.facility.disable_unit_category ? '' : this.selectedUnit.unit_type, this.model.unit_door_number].filter(x => {
          return x ? x.length : false
        })
        if (details.length == 2) { return details[0] + ' | ' + details[1].toUpperCase() }
        return details[0]
      },
      getErrorPageTitle () {
        if (this.isReservation || !this.processingError) { return 'Finish' } else {
          return this.paymentRetry ? 'Finish' : 'Retry Payment'
        }
      },
      removeVehicleStorageSection () {
        if (this.vehicleStrorageSectionIndex > 0) { this.selectedFlow.splice(this.vehicleStrorageSectionIndex, 1) }
      },
      removeInsuranceSection () {
        if (this.insuranceSectionIndex > 0) { this.selectedFlow.splice(this.insuranceSectionIndex, 1) }
      },
      // Validator methods
      requiredField: function (value) {
        return Validator.value(value).required()
      },
      requiredEmailField: function (value) {
        return Validator.value(value).required().email()
      }
    }
  ),
  computed: Object.assign(
    {},
    mapState({
      facility: state => state.facilityInfo,
      amenities: state => state.facilityAmenities,
      facilityMedia: state => state.facilityMedia,
      facilityUnits: state => state.facilityUnits,
      allFacilityUnits: state => state.allFacilityUnits,
      facilitySpaces: state => state.facilitySpaces,
      activeSection: state => state.rental.section,
      selectedUnit: state => state.rental.selectedUnit,
      userToken: state => state.userToken,
      openedUnitGroup: state => state.rental.openedUnitGroup,
      windowMargin: state => state.windowMargin,
      capabilities: state => state.capabilities
    }),
    {
      defaultInsurance: function () {
        return this.insurances.filter(insurance => insurance.default)[0]
      },
      getFilteredMoveInCost: function () {
        return this.moveInCost.filter((item, index) => !(item.name.toLowerCase() == 'rent' && index == 0 && this.selectedSpace.rental_rate <= item.amount))
      },
      facilityNotes: function () {
        let notes = '<li>' + this.facility.facility_notes + '</li>'
        notes = notes.replace(/\r\n\r\n/g, '</li><li>')
        notes = notes.replace(/\r\n$/g, '')
        notes = notes.replace(/\r\n/g, '</li><li>')
        return notes
      },
      creditCardFormatter: function () {
        if (this.model.cc_number.startsWith('37') || this.model.cc_number.startsWith('34')) {
          this.cvvLength = 4
          return '#### ###### #####'
        } else {
          this.cvvLength = 3
          return '#### #### #### ####'
        }
      },
      vehicleStrorageSectionIndex: function () {
        return this.selectedFlow.indexOf('vehicle_storage')
      },
      insuranceSectionIndex: function () {
        return this.selectedFlow.indexOf('content_insurance')
      },
      frontendConf: function () {
        return this.facility.frontend_conf
      },
      secondaryContactTitle: function () {
        return (this.frontendConf.secondary_contact_required && this.frontendConf.secondary_contact_title && this.frontendConf.secondary_contact_title.length) ? this.frontendConf.secondary_contact_title : 'Secondary Contact'
      },
      secondaryContactSubTitle: function () {
        let subTitle = 'Would you like to provide a secondary contact?'
        if (this.frontendConf.secondary_contact_required) {
          subTitle = ''
          if (this.frontendConf.secondary_contact_help_text && this.frontendConf.secondary_contact_help_text.length) { subTitle = this.frontendConf.secondary_contact_help_text }
        }
        return subTitle
      },
      completedDocument: function () {
        return window.completed_panda_doc_id
      },
      getHeaderStyle () {
        return {
          top: this.windowMargin
        }
      },
      getBigScreenStyle () {
        if (this.keyBoardVisible) {
          const width = document.documentElement.clientWidth
          if (width > 1920) {
            const keyBoardDelta = (width - 1920) / 2 + 576 + 87
            return {
              left: keyBoardDelta + 'px !important'
            }
          }
        }
      },
      unitPromoTypes: function () {
        if (Object.keys(this.selectedUnit).length) { return this.selectedUnit.promos.map(promo => promo.promo_type) } else { return [] }
      },
      nonDefaultPromos: function () {
        if (this.unitPromoTypes.length) { return this.unitPromoTypes.filter(promo => !['default', 'rental', 'military'].includes(promo)) } else { return [] }
      },
      getRange () {
        /*
                    return format: {
                      "from": Date(),
                      "to": Date(),
                      "daysOfMonth": Array[
                        days
                      ]
                    }
                    */
        const today = new Date()
        const currentYear = today.getFullYear()
        const currentDate = today.getDate()
        const disabledFrom = new Date(today.setFullYear(currentYear - this.facility.senior_citizen_age))
        const disabledTo = new Date(today.setFullYear(currentYear - this.facility.senior_citizen_age - 60))
        // disabledFrom.setDate(currentDate + 1)
        const result = {
          from: disabledFrom,
          to: disabledTo
        }
        return result
      },
      getReservationDisabledDates () {
        const to = new Date()
        to.setDate(to.getDate() - 1)
        const from = new Date()
        from.setDate(from.getDate() + this.facility.advanced_dates)
        return {
          to: to,
          from: from
        }
      },
      getLicenseDisabledDates () {
        const to = new Date()
        to.setDate(to.getDate() - 1)
        return {
          to: to
        }
      },
      openDate () {
        return this.getRange.from
      },
      userAction () {
        const rent = this.activeSection == 'process_rental' ? 'lease' : 'rental'
        return this.isReservation ? 'reservation' : rent
      },
      isInsurance () {
        return this.facility.has_insurance_plan ? 'Insurance' : 'Protection'
      },
      codeType () {
        return this.isReservation ? 'Reservation' : 'Gate'
      },
      isNoke () {
        return !!this.facility.noke
      },
      facilityAddress2 () {
        return this.facility.address2 ? this.facility.address2 + ', ' : ''
      },
      highlight () {
        return {
          email: !this.model.tenant_email.length,
          phone: this.model.tenant_phone ? !this.model.tenant_phone.toString().length : true
        }
      },
      showConfirmInfo () {
        return !Object.values(this.highlight).includes(true)
      },
      nokeStatusCheck () {
        return this.nokeStatus == 'success'
      },
      isValidSecondaryContactInfo: function () {
        if (this.activeSectionIndex == 2) { return true }
        // Check if primary and secondary contact address are different
        const primaryContactInfo = this.model.mailing_address1.trim() + this.model.mailing_city.trim() + this.model.mailing_state.trim() + this.model.mailing_zip.trim()
        const secondaryContactInfo = this.model.secondary_contact_address1.trim() + this.model.secondary_contact_city.trim() + this.model.secondary_contact_state.trim() + this.model.secondary_contact_zipcode.trim()
        // console.log('primaryContactInfo', slugify(primaryContactInfo), 'secondaryContactInfo', slugify(secondaryContactInfo))
        return !(slugify(primaryContactInfo) === slugify(secondaryContactInfo))
      },
      secondaryEmailRequired: function () {
        return Boolean(this.facility.secondary_email_required)
      },
      secondaryEmailPlaceholder: function () {
        return this.secondaryEmailRequired ? 'Email*' : 'Email'
      },
      showStoringVehicleToggle: function () {
        return this.selectedUnit.parking ? false : this.facility.enable_storing_vehicle
      },
      showParkingTypeFields: function () {
        let showFields = this.facility.enable_parking_type_fields
        if (!this.selectedUnit.parking && !this.model.store_vehicle) { showFields = false }
        return showFields
      },
      // Validator Props - START
      secondaryContactFields: function () {
        const toggleFields = ['model.no_secondary_contact', 'model.active_military']
        const infoFields = [
          'model.secondary_contact_first_name',
          'model.secondary_contact_last_name',
          'model.secondary_contact_email',
          'model.secondary_contact_phone',
          'model.secondary_contact_address1',
          'model.secondary_contact_city',
          'model.secondary_contact_state',
          'model.secondary_contact_zipcode'
        ]
        if (!this.facility.secondary_email_required) {
          infoFields.splice(infoFields.indexOf('model.secondary_contact_email'), 1)
        }
        if (!this.model.no_secondary_contact) {
          return [...toggleFields, ...infoFields]
        }
        return toggleFields
      },
      militaryInfoFields: function () {
        const toggleFields = ['model.active_military']
        const infoFields = [
          'model.military_ssn',
          'military_ets_date',
          'model.military_email',
          'model.military_contact_name',
          'model.military_commanding_officer',
          'model.military_branch',
          'model.military_name',
          'model.military_unit_phone',
          'model.military_address1',
          'model.military_city',
          'model.military_state',
          'model.military_zip'
        ]
        if (this.model.active_military) {
          return [...toggleFields, ...infoFields]
        }
        return toggleFields
      },
      vehicleStorageFields: function () {
        const fields = []
        if (this.showStoringVehicleToggle) {
          fields.push('model.store_vehicle')
        }
        if (this.showParkingTypeFields) {
          fields.push('model.storing_vehicle_type')
          if (this.model.storing_vehicle_type == 'other') {
            fields.push('model.other_vehicle_describe')
          }
        }
        return fields
      },
      discountFields: function () {
        const fields = []
        if (this.discounts == 'senior') {
          fields.push('birthday')
        }
        if (this.discounts == 'student') {
          fields.push('model.school_name', 'model.student_id')
        }
        return fields
      },
      aboutConvenienceFields: function () {
        const fields = []
        if (this.facility.autopay_always_required) {
          fields.push('model.auto_debit')
        }
        if (this.facility.is_gatecode_selectable && this.facility.show_gatecode) {
          fields.push('model.gatecode')
        }
        return fields
      },
      confirmRentalFields: function () {
        const fields = []
        if (!this.processingError) {
          if (this.newUser) {
            fields.push('model.newpassword')
            if (this.showTextCode() && !this.isNoke) {
              fields.push('model.tenant_phone')
            }
          } else {
            if (this.showTextCode()) {
              fields.push('model.tenant_phone')
            }
          }
        }
        return fields
      },
      // Validator Props - END
      sectionWiseFields: function () {
        return {
          info: [
            'model.tenant_first_name',
            'model.tenant_last_name',
            'model.tenant_email',
            'model.tenant_phone',
            'model.mailing_address1',
            'model.mailing_city',
            'model.mailing_state',
            'model.mailing_zip',
            'model.driving_lis_no',
            'model.driving_lis_state',
            'driving_lis_expiry'
          ],
          secondary_contact: this.secondaryContactFields,
          military_info: this.militaryInfoFields,
          vehicle_storage: this.vehicleStorageFields,
          discounts: this.discountFields,
          content_insurance: ['model.insurance_id'],
          about_convenience: this.aboutConvenienceFields
          // card_details: [
          //     'model.name_on_card',
          //     'model.cc_number',
          //     'model.cc_exp_month',
          //     'model.cc_exp_year',
          //     'model.cvv',
          // ],
          // confirm_rental: this.confirmRentalFields,
        }
      },
      simpleValidatorSections: function () {
        return Object.keys(this.sectionWiseFields)
      },
      isReservationEnabled: function () {
        if (this.facility.wl_reservation_enabled) {
          return true
        }
        if (this.selectedUnit.parking && this.facility.disable_parking_reservations) {
          return true
        }
        return false
      },
      unitDescriptions: function () {
        return this.selectedUnit.parking ? 'Space' : 'Unit'
      },
      showManualVerificationOnScannedDocumentSection: function () {
        return (
          !this.PreviouslyIdVerifiedDetails &&
            !this.verificationCompleted &&
            !this.facility.driver_license_required
        )
      },
      captureTypeMap () {
        return {
          1: {
            callback: this.captureFrontCallback,
            captureResult: 'FrontCaptureResult',
            captureType: 'DL_FRONT',
            documentType: 'drivers_license_front',
            imageSrc: 'frontImageSrc',
            key: 'front'
          },
          2: {
            callback: this.captureBackCallback,
            captureResult: 'BackCaptureResult',
            captureType: 'PDF417_BARCODE',
            documentType: 'drivers_license_back',
            imageSrc: 'backImageSrc',
            key: 'back'
          },
          3: {
            callback: this.captureSelfieCallback,
            captureResult: 'SelfieCaptureResult',
            captureType: 'SELFIE',
            documentType: 'photo',
            imageSrc: 'selfieImageSrc',
            key: 'selfie'
          }
        }
      },
      captureCroppedTypeMap () {
        return {
          1: {
            captureResult: 'FrontCaptureResult',
            captureType: 'DL_FRONT',
            documentType: 'drivers_license_front',
            imageSrc: 'frontCroppedImageSrc',
            key: 'croppedDocument'
          },
          2: {
            captureResult: 'CroppedSignature',
            captureType: 'DL_SIGN',
            documentType: 'drivers_license_front',
            imageSrc: 'signatureSrc',
            key: 'croppedSignature'
          },
          3: {
            captureResult: 'SelfieCaptureResult',
            captureType: 'SELFIE',
            documentType: 'photo',
            imageSrc: 'selfieCroppedImageSrc',
            key: 'croppedPortrait'
          }
        }
      }
    }
  ),
  watch: {
    'model.newpassword': function () {
      this.showStrongMsg = false
    },
    video: function (newVid, old) {
      if (old.length > 0) {
        var video = Wistia.api(old)
        video.time(0)
        video.volume(0)
        video.pause()
      }
    },
    findReservationSection: function (value) {
      if (value) { this.isReservation = false }
    },
    reservation_date: function (value) {
      this.model.reservation_date = value.toLocaleDateString('en-US')
    },
    isReservation: function (reservation) {
      if (reservation) { this.selectedFlow = [...RESERVATION_FLOW] } else { this.selectedFlow = [...RENTAL_FLOW] }
    },
    activeSection: function () {
      var self = this
      this.sizeCount = 0
      if (this.activeSection == 'find_reservation') {
        this.setVideo('')
        this.unsetSticky()
      }
      this.activeSectionIndex = this.selectedFlow.indexOf(this.activeSection)
      if (this.activeSection == 'detail_page' || this.activeSection == 'find_reservation') {
        this.moveInCost = []
        this.setSelectedUnit({})
        this.selectedSpace = {}
        this.selectedPromo = ''
        this.isReservation = false
        this.spaceSearchActive = false
        this.dlScanError = ''
        this.model = { ...RENTAL_MODEL }
        this.validation.reset()
      }

      if (this.activeSection == 'secondary_contact') {
        // If unit is parking and enable_parking_type_fields is false
        // Remove vehicle section is vehicle storage is disabled for other units
        if (this.frontendConf.secondary_contact_required) {
          this.model.no_secondary_contact = false
        }
        if (this.selectedUnit.parking) {
          if (!this.facility.enable_parking_type_fields) { this.removeVehicleStorageSection() }
        } else {
          if (!this.facility.enable_storing_vehicle) { this.removeVehicleStorageSection() }
        }
      }

      // if (this.activeSection == 'military_info') {
      //     window.setTimeout(() => {
      //         document.getElementById('military_info_no').click()
      //     }, 100)
      //     window.setTimeout(() => {
      //         document.getElementById('military_info_yes').click()
      //     }, 200)
      // }

      if (this.activeSection == 'hold_unit') {
        this.selectedFlow = [...RENTAL_FLOW]
        this.processHold()
        this.$store.commit('updateSkipAndVerifyStatus', false)
      }
      if (this.activeSection == 'panda_fields') {
        this.showTokens()
      }

      if (this.activeSection == 'panda_agreements') {
        this.getPandaDocuments()
      }

      if (this.activeSection == 'swipe_scan') {
        this.clearCCDetails()
        cardAPi.getDeviceHealth().then(response => {
          if (response.statusText == 'OK') {
            this.requestCardSwipe()
          }
        })
      }
      if (this.activeSection == 'card_details') {
        if (!this.swipeCard) { this.model.name_on_card = this.model.tenant_first_name + ' ' + this.model.tenant_last_name }
        this.swipeCard = false
      }
      if (this.activeSection == 'about_convenience') {
        this.model.auto_debit = Boolean(this.facility.autopay_required) || Boolean(this.facility.autopay_always_required)
      }
      if (this.activeSection === 'noke_confirm') {
        this.$store.commit('updateSkipAndVerifyStatus', false)
      }
    },
    cardData: function () {
      this.model.name_on_card = this.cardData.name
      this.model.cc_exp_month = parseInt(this.cardData.exp_month)
      this.model.cc_exp_year = '20' + this.cardData.exp_year
      this.model.cc_number = this.cardData.card_number
      this.swipeCard = true
      this.logData('Swipe Credit Card')
    },
    userToken: function () {
      if (this.userToken.length > 0) {
        this.setActiveSection('info')
        this.getLoggedInUserDetails()
      }
    },
    'model.auto_debit': function (value) {
      if (this.activeSection == 'about_convenience') { this.showAutoDebit = !value }
    },
    'model.active_military': function () {
      // console.log('active military changed')

      // Respect user's preference from military info page - leave rental flow unchanged
      const updateRentalFlow = this.activeSection != 'military_info'
      const discountIndex = this.selectedFlow.indexOf('discounts')
      const milInfoIndex = this.selectedFlow.indexOf('military_info')
      if (this.model.active_military) {
        this.discounts = ''
        this.setSelectedPromo('military')
        this.getMoveInCost()
        // Remove discounts if added to rental flow
        // Replace discount with military info
        if (discountIndex > 0) { this.selectedFlow.splice(discountIndex, 1) }
        if (updateRentalFlow && milInfoIndex < 0) { this.selectedFlow.splice(this.activeSectionIndex + 1, 0, 'military_info') }
      } else {
        // this.setSelectedPromo('default')
        this.discounts = 'default'
        // this.getMoveInCost()
        // Replace military info with discount
        if (updateRentalFlow && milInfoIndex > 0) { this.selectedFlow.splice(milInfoIndex, 1) }
        if (discountIndex < 0 && this.nonDefaultPromos.length) { this.selectedFlow.splice(this.activeSectionIndex + 1, 0, 'discounts') }
      }
    },
    'model.item_id': function () {
      // console.log('merchandise changed')
      this.getMoveInCost()
    },
    'model.insurance_id': function () {
      this.getMoveInCost()
    },
    completed_document: function () {
      this.panda_agreements[this.selected_document].completed = true
      // console.log(this.panda_agreements.length, this.selected_document);
      if (this.panda_agreements.length - 1 > this.selected_document) {
        this.selected_document++
      } else {
        this.doRental()
      }
    },
    'model.mailing_zip': function () {
      this.model.mailing_zipcode = this.model.mailing_zip
      if (this.model.mailing_zipcode && this.model.mailing_zipcode.length > 4) {
        api.getStatename(this.options, this.model.mailing_zipcode)
          .then((response) => {
            if (response.data.status == 'ok') {
              this.model.mailing_state = response.data.result.state_code
            }
          })
          .catch((error) => {
            this.model.mailing_state = ''
          })
      }
    },
    'model.secondary_contact_zipcode': function () {
      if (this.model.secondary_contact_zipcode && this.model.secondary_contact_zipcode.length > 4) {
        api.getStatename(this.options, this.model.secondary_contact_zipcode)
          .then((response) => {
            if (response.data.status == 'ok') {
              this.model.secondary_contact_state = response.data.result.state_code
            }
          })
          .catch((error) => {
            this.model.secondary_contact_state = ''
          })
      }
    },
    'model.billing_zipcode': function () {
      if (this.model.billing_zipcode && this.model.billing_zipcode.length > 4) {
        api.getStatename(this.options, this.model.billing_zipcode)
          .then((response) => {
            if (response.data.status == 'ok') {
              this.model.billing_state = response.data.result.state_code
            }
          })
          .catch((error) => {
            this.model.billing_state = ''
          })
      }
    },
    'model.tenant_first_name': function (value) {
      this.model.name_on_card = value + ' ' + this.model.tenant_last_name
    },
    'model.tenant_last_name': function (value) {
      this.model.name_on_card = this.model.tenant_first_name + ' ' + value
    },
    dlResponse: function () {
      this.model.tenant_first_name = this.dlResponse.NameFirst1 || this.dlResponse.NameFirst2 || this.dlResponse.NameFirst3
      this.model.tenant_last_name = this.dlResponse.NameLast1 || this.dlResponse.NameLast2 || this.dlResponse.NameLast3
      this.model.mailing_address1 = this.dlResponse.Address
      this.model.mailing_address2 = this.dlResponse.Address == this.dlResponse.Address2 ? '' : this.dlResponse.Address2
      this.model.mailing_city = this.dlResponse.City
      this.model.mailing_state = this.dlResponse.State
      this.model.mailing_zip = String(this.dlResponse.Zip).slice(0, 5)
      this.model.driving_lis_no = this.dlResponse.license
      this.model.driving_lis_state = this.dlResponse.State
      var expDate = new Date(this.dlResponse.ExpirationDate4.replace(/^(\d{2})(\d{2})(\d{4})$/, '$1-$2-$3'))
      this.driving_lis_expiry = expDate
      this.logData('Scan Driving License')
      this.setActiveSection('info')
    },
    military_ets_date: function (value) {
      this.model.military_ets_date = value.toLocaleDateString('en-US')
    },
    driving_lis_expiry: function (value) {
      if (value) { this.model.driving_lis_expiry = value }
      if (this.model.driving_lis_expiry) {
        var today = new Date()
        value = new Date(value.toDateString())
        today = new Date(today.toDateString())
        this.validLicenseExpiry = value >= today
      }
    },
    'model.cc_exp_month': function () {
      this.date_expired = false
      if (this.model.cc_exp_month && this.model.cc_exp_year) {
        this.validateCreditCardMonthYear(this.model.cc_exp_month, this.model.cc_exp_year)
      }
    },
    'model.cc_exp_year': function () {
      this.date_expired = false
      if (this.model.cc_exp_month && this.model.cc_exp_year) {
        this.validateCreditCardMonthYear(this.model.cc_exp_month, this.model.cc_exp_year)
      }
    },
    discounts: function (value) {
      // console.log('Discount changed to', value)
      if (value == 'senior') {
        this.model.senior = true
        this.model.student = false
      } else if (value == 'student') {
        this.model.student = true
        this.model.senior = false
      } else {
        this.model.senior = false
        this.model.student = false
      }
      if (value) {
        // console.log('Valid discount. Update selected promo & get move in cost')
        this.setSelectedPromo(value)
        this.getMoveInCost()
      }
    },
    birthday: function (value) {
      this.validDob = true
      this.model.birthday = value.toLocaleDateString('en-US')
    },
    'model.gatecode': function (value) {
      // console.log('gatecode changed', value)
      if (value.length == this.facility.gatecode_length) {
        this.validatingGateCode = true
        this.validGateCode = false
        this.validateGateCode(value).then(res => {
          // console.log('valid gate code', res)
          // hide validationg gc msg
          this.validatingGateCode = false
          this.validGateCode = res.data.result.gatecode
        }).catch(error => {
          this.validatingGateCode = false
          this.validGateCode = false
        })
      }
    }
  },
  // created: function() {
  //     this.$validator.attach('gatecode', 'required|validateGateCode', {
  //         getter: () => this.model.gatecode
  //     })
  // },
  destroyed: function () {
    this.releaseSpace()
    this.setActiveSection('detail_page')
  }
}
</script>

<style lang="less" scoped>
    @import '../../assets/less/colors';
    @import '../../assets/less/mixins';
    @import '../../assets/less/flexbox';
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .error {
      font-size: 16px !important;
    }
    // .mobile_input_field {
    //   position: relative;
    //   left: 134px;
    // }
    // .error_alignment {
    //   left: 135px;
    // }
    // .otp_section{
    // font-weight: 700;
    // font-size: 22px;
    // line-height: 21px;
    // color: #000000;
    // margin: 12px 0px 30px;
    // position: relative;
    // left: 246px !important;
    // }
    .box(@padding: 0px) {
        padding: @padding;
        border-radius: 5px;
        box-sizing: border-box;
        border: 1px solid #DCDCDC;
        background-color: #FFFFFF;
    }
    body {
        .ds-dialog-wrap {
            display: none !important;
        }
        &.big_screen {
            .rental {
                #find_reservation {
                    height: 1080px;
                    .wrapper {
                        top: 302px;
                    }
                }
                #rent_this_space {
                    .container {
                        height: 885px;
                        .img-wrapper {
                            height: 524px;
                        }
                        .btn-wrapper {
                            height: 352px;
                        }
                    }
                }
                .protection_plan_section {
                    height: 885px;
                    background-size: auto;
                    .no_insurance.no_insurance_text {
                        .each_insurance {
                            min-height: 80px;
                            label {
                                line-height: 77px !important;
                            }
                        }
                    }
                }
                #swipe_scan .container {
                    height: 885px;
                    .action-link {
                        bottom: 140px;
                        left: 172px;
                    }
                }
                #panda_fields .container_info {
                    height: 640px;
                }
                .container_info {
                    min-height: 405px;
                }
                #secondary_contact {
                    .container_info {
                        height: 435px;
                    }
                    .container_info_btm {
                        height: 240px;
                    }
                }
            }
        }
    }
    .rental {
        position: relative;
        #noke-confirmation {
            .noke-confirmation-wrapper {
                background: url('../../assets/graphics/noke-bg.jpg') no-repeat;
                height: 476px;
            }
            span {
                color: @call_to_action_color;
            }
            img {
                position: absolute;
                top: 10%;
                z-index: 2;
                right: 18%;
            }
            .ic_confirmation {
                // font-size: 150px;
                position: relative;
                top: 80px;
                width: calc(~'100% - 798px');
                // height: calc(~'100% - 50px');
                display: block;
                text-align: center;
                &.error {
                    border: 10px solid @call_to_action_color;
                    height: 132px;
                    width: 132px;
                    border-radius: 50%;
                    top: 70px;
                    background: #fff;
                    left: calc(~'(100% - 700px) / 2');
                }
                span {
                    position: absolute;
                    bottom: 16px;
                    left: 201px;
                    right: 0;
                    margin:auto;
                    display: block;
                    &:before {
                        content: '\E866';
                        background-color: #fff;
                        width: 165px;
                        height: 152px;
                        border-radius: 50%;
                        font-size: 167px;
                        display: block;
                        line-height: 142px;
                    }
                    &.error {
                        &:before {
                            content: '\E81B';
                            // font-size: 60px;
                        }
                    }
                }
            }
            .heading {
                width: 433px;
                padding: 98px 0 0 87px;
                h3 {
                    font-weight: 500;
                    font-size: 35px;
                    color: @call_to_action_color;
                    letter-spacing: 4px;
                }
                p {
                    &.highlight {
                        font-weight: bold;
                        font-size: 26px;
                        color: @call_to_action_color;
                        line-height: 20px;
                        padding-top: 10px;
                        padding: 10px 0 70px;
                    }
                    &.colored {
                        color: @call_to_action_color;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 26px;
                        padding-bottom: 30px;
                    }
                }
            }
            .text-section {
                padding-left: 87px;
                width: 46%;
                p{
                    font-size: 20px;
                    color: #4A4A4A;
                    line-height: 26px;
                }
                &.noke-failed {
                    background-color: @call_to_action_color;
                    width: 85%;
                    height: 130px;
                    position: relative;
                    left: 53px;
                    p {
                        width: 50%;
                        color: #FFFFFF;
                        line-height: 30px;
                        position: absolute;
                        left: 33px;
                        padding-top: 36px;
                        padding-bottom: 36px;
                        font-weight: bold;
                    }
                }
            }
            footer {
                z-index: 3;
            }
        }
        #setup-smart-access {
            .head-wrapper {
                padding: 64px 100px 59px;
                background: #fff;
                // position: fixed;
                width: 100%;
                top: 0;
                height: 448px;
                box-sizing: border-box;
                .text-section {
                    width: 695px;
                    h2 {
                        font-weight: 500;
                        font-size: 35px;
                        color: @call_to_action_color;
                        letter-spacing: 7.16px;
                        line-height: 1;
                        padding-bottom: 40px;
                    }
                    p {
                        font-weight: 300;
                        font-size: 22px;
                        color: @call_to_action_color;
                        width: 658px;
                        padding: 10px 0;
                        line-height: 1.1;
                    }
                    form {
                        width: 64%;
                        padding-top: 50px;
                        .flex-display(flex);
                        .align-items(center);
                        .justify-content(space-between);
                        position: relative;
                        input {
                            width: 184px;
                            margin: 0;
                        }
                        button {
                            width: 235px;
                            margin: 0;
                            height: 54px;
                            box-shadow: none;
                        }
                        p {
                            position: absolute;
                            bottom: -54px;
                            font-size: 16px;
                            &.success {
                                color: @primary_color;
                            }
                            &.error {
                                color: red;
                            }
                        }
                    }
                }
            }
            img {
                position: absolute;
                right: 132px;
                top: 339px;
                z-index: 2;
            }
            .detail-wrapper {
                position: fixed;
                top: 42%;
                width: 61%;
                height: 60%;
                /* box-shadow: 0 0 20px 1px; */
                padding: 76px 140px 59px;
                ol {
                    list-style-type: decimal;
                    li {
                        list-style-type: decimal;
                        width: 506px;
                        font-weight: 500;
                        font-size: 22px;
                        color: @call_to_action_color;
                        letter-spacing: 4.5px;
                        line-height: 1.3;
                        &:first-child {
                            padding-bottom: 66px;
                        }
                        &:last-child {
                            padding-bottom: 27px;
                        }
                    }
                }
                .noke-pin {
                    background: #FFFFFF;
                    width: 339px;
                    height: 100px;
                    font-weight: 500;
                    font-size: 50px;
                    color: #4A4A4A;
                    letter-spacing: 2.5px;
                    text-align: center;
                    box-sizing: border-box;
                    padding-top: 14px;
                    position: absolute;
                    left: 7%;
                }
                p {
                    font-weight: 400;
                    font-size: 20px;
                    color: #4A4A4A;
                    position: absolute;
                    top: 48%;
                    left: 7%;
                }
            }
        }
        #find_reservation {
            height: 100vh;
            width: 100%;
            background: url('../../assets/graphics/lady_traced.png') no-repeat left bottom;
            background-color: rgba(0,0,0,0.05);
            .wrapper {
                width: 50%;
                position: absolute;
                top: 28vh;
                right: 14%;
                .heading {
                    font-size: 60px;
                    font-weight: 300;
                    line-height: 65px;
                    color: @call_to_action_color;
                    text-align: center;
                    margin-bottom: 20px;
                }

                .form_row {
                    .error {
                        padding-left: 16px;
                    }
                }
                .reservation-code {
                    font-size: 18px;
                    .flex-display(flex);
                    .align-items(center);
                    .justify-content(space-around);
                    &.in_focus {
                        button, a {
                            display: none;
                        }
                    }
                    input[type='text'] {
                        width: 46%;
                        height: 65px;
                        margin-bottom: 0px;
                        text-transform: none;
                    }
                    button, a {
                        width: 46%;
                        height: 63px;
                        font-size: 18px;
                        margin-top: 0px;
                        text-align: center;
                        span {
                            line-height: 63px;
                            display: block;
                        }
                        .loader {
                            height: 40px;
                            width: 40px;
                        }
                    }
                }
            }
        }
        #detail_page {
            position: relative;
            top: 0px;
            .transition(top 0.5s ease);
            &.sticky {
                top: -373px;
                .availability {
                    position: relative;
                    &:after {
                        opacity: 1;
                        .rotate(180deg);
                    }
                }
                .container {
                    height: calc(~'100vh - 94px');
                }
            }
            .availability {
                display: block;
                min-height: 50px;
                padding: 24px 52px 20px 64px;
                font-size: 34px;
                color: @common_text_color_dark;
                background-color: white;
                &:after {
                    content: '\e834';
                    font-family: 'fontello';
                    position: absolute;
                    left: 0px;
                    right: 0px;
                    margin: auto;
                    top: 0px;
                    z-index: 100;
                    width: 38px;
                    font-size: 14px;
                    height: 90px;
                    line-height: 90px;
                    .transition(all .5s ease);
                    opacity: 0;
                }
                span {
                    font-weight: bold;
                }
            }
            .container {
                width: 100%;
                padding: 40px 52px 30px 64px;
                box-sizing: border-box;
                display: flex;
                overflow: hidden;
                height: calc(~'100vh - 94px - 374px');
                position: relative;
                .panel {
                    width: calc(50%);
                    overflow: auto;
                    -webkit-overflow-scrolling: touch;
                    -ms-overflow-style: none;
                    box-sizing: border-box;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                    &.left {
                        padding-right: 20px;
                    }
                    &.right {
                        padding-left: 20px;
                    }
                    .review-section {
                        section {
                            .transition(all .5s ease-out);
                            max-height: 0px;
                            overflow: hidden;
                        }
                        .review-tab.active {
                            + section {
                                max-height: 10000px;
                            }
                        }
                    }
                    .head-box {
                        .box(@padding: 0px);
                        font-size: 22px;
                        padding-left: 20px;
                        border-radius: 5px;
                        margin-bottom: 16px;
                        letter-spacing: 4.5px;
                        color: @common_text_color_dark;
                        transition:all .5s ease-in-out;
                        span {
                            line-height: 58px;
                            &:before {
                                top: 0px;
                                font-size: 10px;
                                margin-right: 20px;
                                position: relative;
                                display: inline-block;
                                transition:all .5s ease-in-out;
                                transform: rotateZ(90deg);
                                -webkit-transform: rotateZ(90deg);
                            }
                        }
                        &.active {
                            transition:all .5s ease-in-out;
                            padding-left: 26px;
                            span:before {
                                top: -3px;
                                margin-right: 30px;
                                transition:all .5s ease-in-out;
                                transform: rotateZ(180deg);
                                -webkit-transform: rotateZ(180deg);
                            }
                        }
                    }
                    .facility-details {
                        .box(26px 30px 10px 30px);
                        margin-bottom: 20px;
                        .address {
                            color: @common_text_color_dark;
                            .tittle {
                                font-size: 20px;
                                font-weight: 500;
                            }
                            .street {
                                font-size: 14px;
                            }
                        }
                        .access-hours-wrapper {
                            width: 100%;
                            margin-top: 18px;
                            background-color: white;
                            .flex-display(flex);
                            .flex-wrap(wrap);
                            .access-time {
                                width: 42%;
                                display: inline-block;
                                padding-right: 5%;
                                box-sizing: border-box;
                                margin-left: 6%;
                                margin-bottom: 20px;
                                &:nth-child(odd) {
                                    margin-left: 0;
                                }
                                &.facility-notes {
                                    color: black;
                                }
                                li {
                                    .flex-display(flex);
                                    .align-items(center);
                                    .justify-content(space-between);
                                    font-size: 16px;
                                    .status {
                                        color: black;
                                        font-size: 14px;
                                        font-weight: 500;
                                    }
                                    &:first-child {
                                        margin-bottom: 10px;
                                        span {
                                            text-transform: capitalize;
                                        }
                                    }
                                    &.active, &.daily {
                                        padding: 4px 0px;
                                        color: @common_text_color_dark;
                                    }
                                }
                            }
                        }
                    }
                    .amenities-section {
                        section {
                            .transition(all .5s ease-out);
                            max-height: 0px;
                            overflow: hidden;
                            padding: 0 0 0 2%;
                            ul {
                                width: 100%;
                                li {
                                    float: left;
                                    color: #000;
                                    width: 31.3%;
                                    height: 128px;
                                    font-weight: 100;
                                    font-size: 14px;
                                    margin: 0 2% 2% 0;
                                    position: relative;
                                    line-height: 30px;
                                    background-color: #ccc;
                                    -webkit-background-size: cover;
                                    background-size: cover;
                                    span {
                                        left: 15px;
                                        bottom: 12px;
                                        color: #fff;
                                        line-height: 15px;
                                        position: absolute;
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                        .amenities.active {
                            + section {
                                max-height: 5000px;
                                .box(2% 0 0 2%);
                            }
                        }
                    }
                    .payment-notice {
                        color: @call_to_action_color;
                    }
                    .unit_search {
                        .header {
                            z-index: 10;
                            color: #fff;
                            height: 71px;
                            width: 594px;
                            font-size: 18px;
                            font-weight: 300;
                            padding: 0px 30px;
                            border-radius: 2.5px;
                            .flex-display(flex);
                            .align-items(center);
                            position: absolute;
                            box-sizing: border-box;
                            .justify-content(space-between);
                            background-color: @primary_color;
                            .unit-name {
                                font-size: 22px;
                                font-weight: bold;
                            }
                        }
                        ul {
                            padding-top: 70px;
                        }
                    }
                }
            }
        }
        #rent_this_space {
            .container {
                height: calc(~'100vh - 195px');
                .img-wrapper {
                    // height: 524px;
                    height: 48.5vh;
                    position: relative;
                    background-color: #EBEBEB;
                    img {
                        top: 72px;
                        left: -2px;
                        position: absolute;
                    }
                    .arrow {
                        position: absolute;
                        top: 452px;
                        left: 531px;
                        width: 188px;
                        height: 159px;
                        #WhitelabelKioskArrow {
                            fill: @call_to_action_color;
                        }
                        svg {
                            position: absolute;
                        }
                    }
                    &.active {
                        .flex-display(flex);
                        .align-items(center);
                        .justify-content(center);
                        .error_div {
                            background-color: #ffff;
                            width: 525px;
                            height: 174px;
                            margin-right: 25%;
                            border: 1px solid red;
                            border-radius: 10px;
                            font-size: 24px;
                            text-align: center;
                            .flex-display(flex);
                            .align-items(center);
                            .justify-content(center);
                        }
                    }
                }
                .btn-wrapper {
                  position: relative;
                  // z-index: 2;
                    // height: 408px;
                    height: calc(~'51.5vh - 195px');
                    margin-right: 4%;
                    .flex-display(flex);
                    .align-items(center);
                    .justify-content(center);
                    background-color: #F3F3F3;
                    .warn_btn_text, .scan_button {
                        display: block !important;
                    }
                    .rescan_button {
                        display: none !important;
                    }
                    &.active {
                        .warn_btn_text, .scan_button {
                            display: none !important;
                        }
                        .rescan_button {
                            display: block !important;
                        }
                    }
                    .btn-container {
                         margin: auto;
                        //margin-right: 25%;
                        text-align: left;
                        .discription {
                          display: flex;
                          flex-direction: row;
                          justify-content: center;
                          .existing-customer, .manual-verificatoin {
                            padding: 0px 22px;
                            font-size: 22px;
                            margin-top: 28px;
                            color: @call_to_action_color;
                          }
                        }
                        .warn_btn_text {
                            font-size: 22px;
                            margin-top: -4px;
                            margin-bottom: 48px;
                            font-weight: 700;
                            color: @call_to_action_color;
                            text-align: center;
                        }
                        .form-container {
                          display: flex;
                          gap: 8px;
                          justify-content: center;
                        }
                        .form-container  {
                          .form-fields {
                            width: 476px;
                            border-radius: 3px;
                          }
                          button {
                            display: block;
                            // padding: 24px;
                            width: 144px;
                            height: 69px;
                            border-radius: 3px;
                            margin-top: 0px;
                            font-weight: 500;
                            letter-spacing: normal;
                            text-transform: none;
                            &.btn-disabled {
                              opacity: 0.6 !important;
                              cursor: auto !important;
                            }
                          }
                          input {
                            width: 476px;
                            height: 69px;
                          }
                          .error {
                            // top: -16px;
                            text-align: left;
                          }
                        }
                    }
                    &.active {
                        margin-right: 25%;
                    }
                }
                .absolute-position {
                  position: absolute;
                }
                .overlay {
                  z-index: 2;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  background: rgba(0, 0, 0, 0.7);
                  transition: opacity 500ms;
                  .popup-wrapper {
                      width: 778px;
                      position: relative;
                      margin: 0 auto;
                      top: 30%;
                      background: #C6E2F5;
                      align-items: center;
                      justify-content: center;
                      text-align: center;
                      transition: all 5s ease-in-out;
                      &.popupbackground {
                        background: #ffffff;
                      }
                      &.top15 {
                        top: 15%;
                      }
                    .popup-container {
                      .popup-header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        gap: 157px;
                        padding: 34px 48px;
                        background: #C6E2F5;
                        h2 {
                          font-weight: 700;
                          font-size: 36px;
                          color: #000000;
                          text-align: left;
                        }
                        .close {
                          font-size: 40px;
                          text-decoration: none;
                          color: #000000;
                        }
                      }
                      .popup-body {
                          padding: 38px 48px 48px;
                          h2 {
                            font-size: 22px;
                            font-weight: 700;
                            text-align: center;
                            color: #000000;
                            &.pb-h1 {
                              margin-top: 2px;
                            }
                          }
                          .just-in-case {
                            font-size: 22px;
                            font-weight: 400;
                            padding: 15px 0px 20px 0px;
                            text-align: left;
                            color: #000000;
                            margin-bottom: 2px;
                          }
                          .form-container {
                                display: flex;
                                gap: 8px;
                                justify-content: left;
                                text-align: left;
                              }
                          .form-container  {
                            .form-fields {
                                width: 483px;
                              }
                            button {
                              display: block;
                              // padding: 24px;
                              margin-left: 8px;
                              width: 184px;
                              height: 69px;
                              font-weight: 700;
                              font-size: 22px;
                              // background: #FFFFFF;
                              border-radius: 3px;
                              margin-top: 0px;
                              letter-spacing: normal;
                              text-transform: none;
                                &.btn-disabled {
                                  opacity: 0.6 !important;
                                  cursor: auto !important;
                                }
                              }
                              input {
                                width: 483px;
                                height: 69px;
                                &.pb-input1 {
                                  font-size: 22px;
                                }
                              }
                              .error {
                                // top: -16px;
                                text-align: left;
                              }
                            }
                          .break-line {
                            margin: 0px 0px 5px;
                            .hr {
                              vertical-align: middle;
                              width: 300px;
                              display: inline-block;
                              border: 1px solid #A5B4BF;
                            }
                            .hr-msg {
                              padding: 27px;
                              width: 24px;
                              font-size: 22px;
                              font-weight: 400;
                              color: #101318;
                            }
                            &.breakLinePadding{
                              margin: 38px 0px 29px;
                              }
                            }
                          .btn-full-width {
                            width: 682px !important;
                            height: 69px;
                            // padding: 24px;
                            // margin-top: 20px;
                            font-weight: 700;
                            font-size: 22px;
                            letter-spacing: normal;
                            text-transform: none;
                            &.cwe-button {
                                margin-top: 10px;
                              }
                            }
                            .cwm-button {
                              width: 682px !important;
                              font-weight: 700;
                              font-size: 22px;
                              margin-top: 10px;
                              letter-spacing: normal;
                              text-transform: none;
                            }
                            .sms-icon img {
                              width: 32.69px;
                              height: 30px;
                            }
                            .cwm-wrapper {
                              display: flex;
                              flex-direction: row;
                              gap: 0px;
                              padding-top: 10px;
                              justify-content: center;
                            }
                          .continue-mobile {
                              p {
                                font-weight: 400;
                                font-size: 22px;
                                line-height: 21px;
                                color: #000000;
                                text-align: left;
                                &.cm-p1 {
                                  margin-top: 10px;
                                  margin-bottom: 22px;
                                }
                                &.cm-p2 {
                                  margin-bottom: 16px;
                                }
                                &.info {
                                  font-size: 17px;
                                  line-height: 16px;
                                  color: #637381;
                                  margin: 8px 0px 12px;
                                }
                                &.error {
                                  color: red;
                                }
                              }
                              .form_field_align {
                                width: 483px;
                              }
                              input {
                                width: 682px;
                                height: 69px;
                                border: 1px solid #101318;
                                border-radius: 3px;
                                margin-top: 8px;
                              }
                              .error {
                                top: -14px;
                                width: 675px;
                                text-align: left;
                              }
                              label {
                                text-align: left;
                              }
                            }
                          .body-footer {
                            p {
                                text-align: left;
                                font-weight: 400;
                                font-size: 22px;
                                color: #000000;
                                margin-bottom: 13px;
                                &.bf-p1 {
                                  margin-top: 4px;
                                }
                            }
                            input {
                                width: 682px;
                                height: 69px;
                                border: 1px solid #101318;
                                border-radius: 3px;
                                text-transform: none;
                              }
                              // .popup-checkbox > input + label > .input-box {
                              //   width: 21px !important;
                              //   height: 21px !important;
                              //   min-width: 21px !important;
                              //   margin-right: 13px !important;
                              // }
                            .error {
                              // top: -16px;
                              text-align: left;
                              }
                            label {
                              text-align: left;
                            }
                            .popup-checkbox {
                              input + label > .input-box {
                                width: 21px !important;
                                height: 21px !important;
                                min-width: 21px !important;
                                margin-right: 13px !important;
                              }
                              .checkbox-label {
                                font-weight: 400;
                                font-size: 22px;
                                color: #101318;
                              }
                            }
                            .otp-section {
                              width: 100%;
                              .otp-section-wrapper {
                                margin-top: 26px;
                                p {
                                  text-align: center;
                                  line-height: 21px;
                                  margin: 30px 0px 22px;
                                  &.count-down {
                                    margin: 2px 0px 30px;
                                    font-weight: 700;
                                    font-size: 22px;
                                  }
                                  &.error {
                                    // font-size: 16px;
                                    color: red;
                                    text-align: center;
                                    margin-bottom: 26px;
                                    margin-top: -12px;
                                }
                                }
                                h5 {
                                  font-weight: 700;
                                  font-size: 22px;
                                  line-height: 21px;
                                  color: #000000;
                                  margin: 12px 0px 22px;
                                  // position: relative;
                                  // left: 180px;
                                }
                                input {
                                  width: 410px;
                                }
                                .otp-notification {
                                  padding: 14px 16px;
                                  gap: 8px;
                                  width: 410px;
                                  // height: 69px;
                                  background: #FFFFFF;
                                  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
                                  border-radius: 30px;
                                  position: relative;
                                  left: 118px;
                                  top: -5px;
                                  p {
                                    font-weight: 500;
                                    font-size: 14px;
                                    line-height: 22px;
                                    color: #101318;
                                    margin: 4px 0px 0px;
                                  }
                                  .otp-warning {
                                    color: #FB4C4C;
                                    margin-bottom: 4px;
                                  }
                                  img {
                                    margin-bottom: -7px;
                                  }
                                }
                                .resent-sms {
                                  margin: 2px 0px 30px;
                                  a {
                                    color: #212530;
                                    font-weight: 700;
                                    text-decoration: underline;
                                  }
                                }
                                .relative-position {
                                  position: relative;
                                  left: 134px;
                                }
                              }
                            }
                            .btn-full-width {
                              margin-top: 21px;
                            }
                          }
                      }
                    }
                  }
                }
                .gif-image {
                  position: absolute;
                  top: 304px;
                  left: 16px;
                  z-index: 0;
                  img {
                    width: 1000px;
                    height: 415px;
                  }
                }
            }
        }
        #side_bar {
            top: -1px;
            width: 398px;
            right: 60px;
            position: absolute;
            background-color: #FFFFFF;
            border: 1px solid #DDDDDD;
            box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.16);
            z-index: 1;
            .head {
                color: #FFFFFF;
                .flex-display(flex);
                .align-items(center);
                .justify-content(space-between);
                padding: 12px 20px;
                font-size: 18px;
                font-weight: 500;
                line-height: 20px;
                border-radius: 0px 0px 3px 3px;
                width: 399px;
                box-sizing: border-box;
                background-color: @primary_color;
                .button {
                    font-size: 13px;
                    border: 1px solid #ffffff;
                    line-height: 12px;
                    padding: 10px 25px;
                    border-radius: 3px;
                }
            }
            .move-in-cost {
                color: @common_book_text_color;
                padding-top: 22px;
                box-sizing: border-box;
                font-size: 14px;
                p {
                    line-height: 18px;
                    padding: 0px 35px;
                }
                .unit-name {
                    font-size: 18px;
                    font-weight: bold;
                    margin-bottom: 4px;
                    color: @common_text_color_dark;
                }
                .unit-type {
                    color: @black_text;
                }
                .unit-description {
                    margin-top: 10px;
                }
                ul {
                    li {
                        padding: 0px 35px;
                        .flex-display(flex);
                        .align-items(flex-start);
                        .justify-content(space-between);
                        &.cost-list.first, &.move-in {
                            font-weight: bold;
                        }
                        &.in-store {
                            font-style: italic;
                            padding-bottom: 10px;
                        }
                        &.move-in {
                            margin-top: 21px;
                            margin-bottom: 25px;
                        }
                        &.cost-list.last {
                            height: 55px;
                            font-size: 18px;
                            color: #FFFFFF;
                            font-weight: 500;
                            margin-top: 20px;
                            background-color: @primary_color;
                            .align-items(center);
                            .price {
                                font-size: 20px;
                            }
                            span:first-child {
                                max-width: 100%;
                            }
                        }
                        &.cost-list {
                            span:first-child {
                                max-width: 70%;
                            }
                        }
                        &.cont-list.first {
                            margin-top: 5px;
                            margin-bottom: 9px;
                        }
                    }
                }
                .card-images {
                    padding: 26px;
                    padding-bottom: 0px;
                    .flex-display(flex);
                    .align-items(center);
                    .justify-content(space-between);
                    .all-cards {
                        width: 202px;
                        margin-left: 15px;
                    }
                }
                .zigzag-img {
                    left: 0px;
                    top: 33px;
                    position: relative;
                }
            }
            .notice {
                font-size: 18px;
                margin-top: 15px;
                font-weight: bold;
                text-align: center;
                color: @call_to_action_color;
            }
        }
        #info {
            .container_info {
                height: auto;
                background: none;
                background-color: #D8D8D8;
                // padding-bottom: 20px;
                // padding-left: 63px;
                padding: 45px 0 20px 63px;
                h3 {
                  padding-top: 0px
                }
                .form-fields {
                    margin-bottom: 15px;
                    width: 750px;
                    // position: relative;
                    // left: 131px;
                    .highlight {
                        border: 1px solid red;
                    }
                    & > .form_row {
                        padding-left: 27px;
                        margin-bottom: 31px;
                    }
                    .info_wrapper {
                        padding-left: 27px;
                        // padding-top: 29px;
                        // padding-bottom: 10px;
                        position: relative;
                        &.confirm_info {
                            margin: -1px;
                            border: 1px solid @call_to_action_color;
                            border-radius: 5px;
                            &:before {
                                position: absolute;
                                content: "Confirm your information";
                                font-size: 22px;
                                color: @call_to_action_color;
                                left: 0;
                                top: -37px;
                            }
                        }
                    }
                    .id-container {
                      display: grid;
                      grid-template-columns: auto auto;
                      gap: 24px;
                    }
                }
            }
            .container_info_btm {
                height: 408px;
                background: none;
                background-color: #C7C7C7;
                // padding-top: 64px;
                padding-top: 15px;
                p {
                    margin: 0 auto;
                    width: 200px;
                    margin-top: 100px;
                }
            }
            .container-scanned-document {
                // position: absolute;
                width: 325px;
                height: 274px;
                margin-left: 27px;
                // left: 885px;
                // top: 604px;
                background: #FFFFFF;
              .scanned-document {
                padding: 16px;
                .verified-top-banner {
                  display: flex;
                  align-items: center;
                  margin-bottom: 10px;
                  .iconBorder {
                    border: 3px solid #35858e;
                    color: #35858e;
                    border-radius: 100%;
                    width: 23px;
                    height: 23px;
                    display: block;
                    text-align: center;
                    padding: 0;
                    margin-right: 10px;
                    .ss-icon {
                      font-size: 14px;
                    }
                  }
                  .danger {
                    border: 3px solid #ee5338;
                    color: #ee5338;
                  }
                  .verified-title {
                    font-weight: 700;
                    font-size: 22px;
                    color: #000000;
                  }
                  p {
                    font-weight: 400;
                    font-size: 16px;
                    color: #000000;
                    line-height: 15px;
                  }
                }
                // .form-container {
                //   display: flex;
                //   gap: 8px;
                //   justify-content: center;
                // }
                .form-container  {
                  // .form-fields {
                  //   width: 207px;
                  //   border-radius: 3px;
                  // }
                  // .form_field_align {
                  //   width: 207px;
                  //   border-radius: 3px;
                  // }
                  .form_row {
                    padding-left: 0px;
                    margin-bottom: 0px;
                    .form_field_align {
                      margin-top: 5px;
                    }
                  }
                  button {
                    display: block;
                    // padding: 24px;
                    width: 292px;
                    height: 69px;
                    border-radius: 3px;
                    margin-top: 0px;
                    font-weight: 500;
                    letter-spacing: normal;
                    text-transform: none;
                    margin: -20px 0px 10px;
                    &.btn-disabled {
                      opacity: 0.6 !important;
                      cursor: auto !important;
                    }
                  }
                  input {
                    width: 292px;
                    height: 54px;
                    margin: 10px 0px 20px;
                  }
                  .error {
                    text-align: left;
                    top: -10px;
                  }
                }
                .image-wrapper {
                  display: flex;
                  gap: 15;
                  .front-img {
                    img {
                      width: 294px;
                      height: 169px;
                    }
                  }
                  .selfie-img{
                    img {
                      width: 115px;
                      height: 138px;
                      object-fit: contain;
                      image-orientation: none;
                    }
                  }
                }
                .skip-and-verify {
                  font-weight: 500;
                  color: @call_to_action_color;
                }
                .rental-process {
                  font-weight: 400;
                  font-size: 16px;
                  color: #000000;
                  margin-top: 10px;
                }
                .margin-0 {
                  margin: 0px;
                }
              }
            }
            .absolute-position {
                  position: absolute;
            }
          .overlay {
            z-index: 4;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 1080px;
            background: rgba(0, 0, 0, 0.7);
            transition: opacity 500ms;
            .popup-wrapper {
                width: 778px;
                position: relative;
                margin: 0 auto;
                top: 30%;
                background: #C6E2F5;
                align-items: center;
                justify-content: center;
                text-align: center;
                transition: all 5s ease-in-out;
                &.popupbackground {
                  background: #ffffff;
                }
                &.top15 {
                  top: 15%;
                }
              .popup-container {
                .popup-header {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;
                  align-items: center;
                  gap: 157px;
                  padding: 38px 48px;
                  background: #C6E2F5;
                  h2 {
                    font-weight: 700;
                    font-size: 36px;
                    color: #000000;
                    text-align: left;
                  }
                  .close {
                    font-size: 36px;
                    text-decoration: none;
                    color: #000000;
                  }
                }
                  .popup-body {
                    padding: 38px 48px 48px;
                    h2 {
                      font-size: 22px;
                      font-weight: 700;
                      text-align: center;
                      color: #000000;
                    }
                    .just-in-case {
                      font-size: 22px;
                      font-weight: 400;
                      padding: 15px 0px 20px 0px;
                      text-align: left;
                      color: #000000;
                    }
                    .form-container {
                          display: flex;
                          gap: 8px;
                          justify-content: left;
                          text-align: left;
                        }
                    .form-container  {
                      .form-fields {
                          width: 483px;
                        }
                      button {
                        display: block;
                        // padding: 24px;
                        margin-left: 8px;
                        width: 184px;
                        height: 64px;
                        font-weight: 700;
                        font-size: 22px;
                        // background: #FFFFFF;
                        border-radius: 3px;
                        margin-top: 0px;
                        letter-spacing: normal;
                        text-transform: none;
                          &.btn-disabled {
                            opacity: 0.6 !important;
                            cursor: auto !important;
                          }
                        }
                        input {
                          width: 483px;
                          height: 64px;
                          &.pb-input1 {
                            font-size: 22px;
                          }
                        }
                        .error {
                          text-align: left;
                        }
                      }
                    .btn-full-width {
                      width: 682px !important;
                      height: 69px;
                      // padding: 24px;
                      // margin-top: 20px;
                      font-weight: 700;
                      font-size: 22px;
                      letter-spacing: normal;
                      text-transform: none;
                  }
                  .break-line {
                    margin: 0px 0px 5px;
                    .hr {
                      vertical-align: middle;
                      width: 300px;
                      display: inline-block;
                      border: 1px solid #A5B4BF;
                    }
                    .hr-msg {
                      padding: 27px;
                      width: 24px;
                      font-size: 22px;
                      font-weight: 400;
                      color: #101318;
                    }
                    &.breakLinePadding{
                      margin: 38px 0px;
                    }
                  }
                }
              }
            }
          }
        }
        #secondary_contact {
            color: @common_book_text_color;
            .secondary_verify {
                display: block;
                margin-top: 20px;
            }
            .secondary-contact {
                margin-top: 35px;
            }
            .container_info {
                height: 42.8vh;
                padding-top: 44px;
            }
            .container_info_btm {
                height: 21vh;
                padding-top: 62px;
                position: relative;
                h2 {
                    font-size: 22px;
                    font-weight: 500;
                    letter-spacing: 4.5px;
                    color: @call_to_action_color;
                    text-transform: uppercase;
                    margin-bottom: 28px;
                }
                .radio-wrapper {
                    li {
                        width: 100px;
                        display: inline-block;
                    }
                }
                img {
                    position: absolute;
                    right: 30px;
                    bottom: 28px;
                }
            }
        }
        #military_info {
            .container_info {
                padding-top: 44px;
                position: relative;
                height: calc(~'100vh - 195px');
                img {
                    position: absolute;
                    right: 30px;
                    bottom: 161px;
                }
                h3 {
                    font-size: 22px;
                    font-weight: 500;
                    color: @call_to_action_color;
                }
            }
            .military-info {
                margin-top: 28px;
            }
            .radio-wrapper {
                li {
                    width: 100px;
                    display: inline-block;
                    line-height: 50px;
                }
            }
        }
        #vehicle_storage {
            h3 {
                color: var(--call-to-action-color);
                font-size: 22px;
                font-weight: 500;
            }
            .radio-wrapper {
                li {
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            .vehicle-info {
                ul {
                    columns: 2;
                    -webkit-columns: 2;
                    -moz-columns: 2;
                    height: 380px;
                    li {
                        margin-top: 10px;
                        margin-right: 90px;
                    }
                }
            }
        }
        #about_convenience {
            .container {
                height: calc(~'100vh - 195px');
                background-color: #FFFFFF;
            }
            .lock-section, .auto-pay-section, .gate-code-section {
                padding-left: 90px;
                padding-top: 36px;
                box-sizing: border-box;
                color: @common_book_text_color;
                .view-port {
                    width: 45vw;
                    font-size: 18px;
                    line-height: 22px;
                    h3 {
                        font-size: 22px;
                        letter-spacing: 4.5px;
                        color: @call_to_action_color;
                        margin-bottom: 20px;
                    }
                    p.text {
                        margin-bottom: 28px;
                    }
                    label {
                        color: @call_to_action_color;
                    }
                }
            }
            .lock-section {
                height: 24.5vh;
                background-color: #D8D8D8;
                p {
                    width: 400px;
                }
                .checkbox-component label {
                    color: @call_to_action_color !important;
                    display: block;
                    line-height: 25px;
                    .price {
                        font-size: 24px;
                        display: block;
                        font-weight: 500;
                        color: @common_book_text_color;
                        &:before {
                            content:"$";
                        }
                    }
                }
                .view-port {
                    width: 21vw;
                    display: inline-block;
                }
                .img-wrapper {
                    display: inline-block;
                    position: relative;
                    img {
                        position: relative;
                        left: 60px;
                        top: 15px;
                    }
                }
            }
            .auto-pay-section {
                height: 24.7vh;
                padding-top: 46px;
                background-color: #ECECEC;
                margin-bottom: 4px;
                label {
                    width: 530px !important;
                }
            }
            .gate-code-section {
                height: 22vh;
                background-color: @call_to_action_shading;
                .gate-code {
                    .error {
                        width: 360px;
                        top: -19px;
                    }
                    input {
                        width: 190px;
                    }
                    .error + input, .gc_error {
                        border: 1px solid red;
                    }
                    &.in_focus {
                        input {
                            width: 100%;
                            &::-webkit-input-placeholder {
                                 color: #000;
                            }
                        }
                        .error {
                            top: 25px !important;
                        }
                    }
                }
                p.text {
                    margin-bottom: 21px
                }
            }
        }
        #swipe_scan {
            .container {
                height: calc(~'100vh - 195px');
                position:relative;
                .swipe-img {
                    position: relative;
                    left: 173px;
                    top: 110px;
                }
                .card_separator {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 525px;
                    position: relative;
                    top: 150px;
                    left: 155px;
                    font-family: Gotham;
                    font-size: 15px;
                    font-weight: 400;
                    color: #9B9B9B;
                    letter-spacing: 3.07px;
                    text-align: center;
                    .card-border {
                        border: 1px solid #9B9B9B;
                        width: 300px;
                        &:first-child {
                            margin-right: 10px;
                        }
                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                }
                .to-do-girl {
                    position: absolute;
                    bottom: -1px;
                    right: 5vw;
                }
                .action-link {
                    position: absolute;
                    bottom: 150px;
                    left: 9vw;
                    font-size: 22px;
                    letter-spacing: 4.5px;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: @primary_color;
                }
            }
        }
        #card_details {
            .error {
                top: -19px;
            }
            .container_info {
                height: calc(~'100vh - 195px');
                position: relative;
                .billing_section, .mailing_check {
                    margin-bottom: 20px;
                }
                img {
                    height: 76%;
                    position: absolute;
                    bottom: -3vh;
                    left: 29vw;
                }
            }
            .card-name {
                width: 680px;
            }
            .auto-pay-section {
                .view-port {
                    width: 23vw;
                    h3 {
                        padding-top: 22px;
                        margin-bottom: 5px;
                    }
                    p.text {
                        margin-bottom: 23px;
                    }
                }
            }
        }
        #panda_fields {
            p.section_description {
                margin-bottom: 10px;
                margin-top: -20px;
            }
            .container_info {
                height: calc(~'100vh - 400px');
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                .form-fields {
                    width: 730px;
                    // overflow-y: auto;
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
            .form_row {
                h3 {
                    font-size: 22px;
                    letter-spacing: 4.5px;
                    color: @call_to_action_color;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                .date_picker {
                    margin-bottom: 20px;
                    border-radius: 3px;
                    input[type='text'] {
                        margin-bottom: 0px !important;
                    }
                }
                .checkbox {
                    margin-bottom: 20px;
                }
                .radio-wrapper {
                    width: auto;
                    margin-right: 50px;
                    label {
                        top: 6px;
                    }
                }
            }
        }
        .container_info {
            min-height: 37.5vh;
            padding: 60px 0 20px 90px;
            background-color: #F3F3F3 !important;
            h3 {
                font-size: 18px;
                color: #9B9B9B;
                padding-top: 12px;
                padding-bottom: 17px;
                font-weight: 300;
            }
            .radio-wrapper {
                li {
                    display: block;
                    line-height: 46px;
                }
            }
        }
        .container_info_btm {
            height: 408px;
            padding-left: 90px;
            background-color: #EBEBEB !important;
        }
        .auto-pay-section {
            color: @common_book_text_color;
            .view-port {
                width: 45vw;
                font-size: 18px;
                line-height: 22px;
                h3 {
                    font-size: 22px;
                    letter-spacing: 4.5px;
                    color: @call_to_action_color;
                    margin-bottom: 20px;
                    font-weight: 500;
                }
                p.text {
                    margin-bottom: 28px;
                }
                label {
                    color: @call_to_action_color;
                }
            }
        }
    }
/* confirm pay */

    #confirm_rental, #confirm_rentalpay {
        .container {
            position: relative;
            .loader_wrapper {
                background-image: url('../../assets/graphics/confirm_bg.png');
                background-size: auto;
                z-index:0;
                &.error {
                    background-image: none;
                }
                p {
                    color: @small_instructions;
                }
                p {
                    width: 60%;
                }
                span {
                    color: @call_to_action_color;
                }
                .ic_confirmation {
                    position: absolute;
                    bottom: -62px;
                    border-radius: 50%;
                    background-color: #fff;
                    color: @call_to_action_color;
                    left: calc(~'(100% - 630px) / 2');
                    &.success {
                        font-size: 165px;
                        line-height: 140px;
                    }
                    &.error {
                        font-size: 65px;
                        height: 145px;
                        width: 145px;
                        text-align: center;
                        border: 9px solid @call_to_action_color;
                        &:before {
                            content: '\E81B';
                            line-height: 150px;
                        }
                    }
                }
            }
            .pay_history {
                width: 787px;
                margin: 125px 90px 0px 90px;
                background-color: @primary_shading_color;
                ul {
                    padding: 40px 40px;
                    li {
                        .flex-display(flex);
                        .justify-content(space-between);
                        .align-items(center);
                        font-size: 14px;
                        padding: 10px 0px;
                        color: @common_book_text_color;
                        div {
                            span {
                                display: block;
                                color: #9b9b9b;
                            }
                        }
                    }
                }
            }
            .total_payment {
                width: 715px;
                margin: 8px 88px;
                padding: 20px 37px;
                .flex-display(flex);
                .justify-content(space-between);
                .align-items(center);
                background-color: @primary_color;
                color: #fff;
                span:last-child {
                    font-size: 18px;
                }
            }
            .fail-next-to-do {
                width: 66%;
                padding-top: 125px;
                h3, h4 {
                    font-size: 32px;
                    font-weight: 300;
                    text-align: center;
                    line-height: 42px;
                    color: @call_to_action_color;
                }
                h3 {
                    font-weight: bold;
                }
            }
        }
        &.retryPandaAgreements {
            .container {
                .loader_wrapper {
                    padding-left: 0px;
                    h3 {
                        width: 100%;
                    }
                    .ic_confirmation {
                        left: calc(~'(100% - 185px) / 2');
                        &:before {
                            line-height: 145px;
                        }
                    }
                }
                .fail-next-to-do {
                    width: 100%;
                }
            }
        }
    }
/* ends */

/* Confirm rental */

#confirm_rentalpay {
    .loader_wrapper {
        p.success {
            font-size: 22px;
            width: 48%;
        }
    }
    .create_accnt {
        padding: 90px 80px;
        width: 57%;
        &.pt60 {
            padding-top: 60px;
        }
        .password_note {
            font-size: 14px;
            font-weight: 300;
            font-family: "Gotham";
        }
        .resCodeSms {
            width: 450px;
        }
        .form-caption_txt {
            padding: 30px 0px 20px;
        }
        .checkbox.form_field_align {
            margin-top: 6px;
            .input-box {
                margin-right: 20px;
            }
        }
        .show_strong_msg {
            width: 200%;
        }
        .textSms {
            font-size: 17px;
        }
        .reservation_code_section {
            width: 679px;
            // TODO: replace with new kiosk color varialbe
            // background: #fff;
            background-color: @call_to_action_shading;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 30px 0 35px 0;
            p {
                font-weight: 500;
                font-size: 32px;
                letter-spacing: 6.5px;
                text-transform: none;
                &:first-child {
                    font-size: 22px;
                    letter-spacing: 4.5px;
                    color: @call_to_action_color;
                }
            }
        }
        h3 {
            font-size: 22px;
            color: @call_to_action_color;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 4.5px;
        }
        ul {
            padding-bottom: 30px;
            li {
                font-size: 16px;
                color: @common_text_color_dark;
                font-weight: 500;
            }
        }
        p {
            font-size: 16px;
            color: @common_text_color_dark;
            text-transform: capitalize;
            &.success {
                color: @primary_color;
            }
            &.error {
                color: red;
            }
        }
        button {
            box-shadow: none;
            font-size: 18px;
            margin-top: 0px;
            width: 333px;
            height: 70px;
            line-height: 70px;
            span {
                line-height: 70px;
            }
            &.auto_width {
                width: auto;
            }
        }
        .form-fields .form_field_align .error {
            top:-20px;
        }
    }
}
#discounts {
    height: 100vh;
    .form-fields {
        width: 82%;
        height: calc(~'100vh - 310px');
        padding: 70px 94px 65px;
        background-image: url('../../assets/graphics/promo_bg.png');
        background-repeat: no-repeat;
        background-position: bottom 135px right;
        h3 {
            color: @call_to_action_color;
            font-size: 22px;
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: 400;
        }
        ul {
            padding: 25px 0px;
            li {
                padding: 10px 0px;
            }
        }
        p {
            color: @common_text_color_dark;
            span  {
                font-weight: 500;
            }
        }
    }
}

/* ends */

</style>
